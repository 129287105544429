import moment from 'moment-timezone';
import { DATE_FORMAT } from '../constants';

export const formattedDayOfTheWeekDayMonthShipmentDate = value =>
  value ? moment(value).format(DATE_FORMAT.DAY_OF_WEEK_DAY_MONTH_FORMAT) : '';

export const getUKCurrentMomentTime = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getNextWorkingDay = (date = getUKCurrentMomentTime()) => {
  let nextDay = moment(date).add(1, 'days');

  // Keep adding days if the next day is a weekend (Saturday or Sunday)
  while (nextDay.isoWeekday() > 5) {
    nextDay.add(1, 'days');
  }

  return nextDay;
};

export const getNextSaturday = (date = moment()) => {
  let nextDay = moment(date).add(1, 'days');

  // Keep adding days until you get Saturday
  while (nextDay.isoWeekday() !== 6) {
    nextDay.add(1, 'days');
  }

  return nextDay;
};

export const getNextSunday = (date = moment()) => {
  let nextDay = moment(date).add(1, 'days');

  // Keep adding days until you get Sunday
  while (nextDay.isoWeekday() !== 7) {
    nextDay.add(1, 'days');
  }

  return nextDay;
};

export const isFriOrSat = (date = moment()) => {
  let today = moment(date);
  return today.isoWeekday() === 5 || today.isoWeekday() === 6;
};

export const addBusinessDays = (date, days) => {
  if (!date || !days) {
    return null;
  }
  let result = moment(date);
  while (days > 0) {
    result.add(1, 'days');
    if (result.day() !== 0 && result.day() !== 6) {
      days--;
    }
  }
  return result;
};

export const getDayAndMonth = date =>
  moment(date).isValid()
    ? `${moment(date).format(DATE_FORMAT.DISPLAY_DATE_TIME_FORMAT)}`
    : '';

export const isPresentOrFutureDate = date => {
  if (!date) {
    return false;
  }

  const inputDate = moment(date, DATE_FORMAT.YYYY_MM_DD_FORMAT, true);

  if (!inputDate.isValid()) {
    return false;
  }

  // get the current date (ignoring time)
  const currentDate = moment().startOf('day');

  return inputDate.isSameOrAfter(currentDate, 'day');
};

export const convertToInternalDate = localDate => {
  if (!localDate) {
    return null;
  }
  const dateInternal = new Date('1967-12-31');

  const targetDate = new Date(localDate);

  if (isNaN(targetDate)) {
    return null;
  }

  const msInDay = 24 * 60 * 60 * 1000;
  const daysDifference = Math.floor((targetDate - dateInternal) / msInDay);

  return daysDifference;
};
