import PropTypes from 'prop-types';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../features/ErrorBoundary';
import { useAuth } from '../features/Auth';

const ProtectedRoute = ({ element }) => {
  const { currentSession } = useAuth();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();

  const unauthorized = !currentSession?.uid;
  if (unauthorized) {
    return (
      <ErrorBoundary
        config={getErrorBoundaryConfig[ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS]()}
      />
    );
  }

  return element;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default ProtectedRoute;
