import { HttpStatusCode } from 'axios';
import { get } from 'lodash';

import { STRINGS } from '../constants';
import { StringUtil } from '../utils';

export const isNotFoundError = error =>
  get(error, 'statusCode') === HttpStatusCode.NotFound;

export const isBadRequestError = error =>
  get(error, 'statusCode') === HttpStatusCode.BadRequest;

export const isConflictError = error =>
  get(error, 'statusCode') === HttpStatusCode.Conflict;

export const formatParcelError = error => ({
  ...error.response?.data?.error,
  message: `${STRINGS.PARCEL_NUMBER_IS_NOT_VALID} ${StringUtil.formatMessage(
    STRINGS.PLEASE_ENTER_VALID_$,
    'value'
  )}.`,
});

export const getProductErrorMessage = (countErrors, countProducts) => {
  if (countErrors === countProducts) {
    return countProducts === 1
      ? STRINGS.PRODUCT_IS_INVALID
      : STRINGS.PRODUCTS_ARE_INVALID;
  }

  if (countErrors > 1) {
    return STRINGS.SOME_PRODUCTS_ARE_INVALID;
  }

  return STRINGS.SOME_PRODUCT_IS_INVALID;
};
