import { Box, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

export const StepsList = ({ config, containerStyles }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        ...containerStyles,
      }}
    >
      {config.map(step => (
        <Box
          key={step.id}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          <step.icon style={{ width: '35px', height: '35px' }} />
          <Typography variant='body2' sx={{ display: 'inline-flex' }}>
            <span style={{ marginRight: theme.spacing(1) }}>{step.id}.</span>
            <span>{step.text}</span>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

PropTypes.StepsList = {
  config: PropTypes.array.isRequired,
  containerStyles: PropTypes.object,
};
