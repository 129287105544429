import PropTypes from 'prop-types';
import { generateMapboxStaticImageUrl } from '../../helpers/map';

const StaticMapPreview = ({
  address,
  longitude,
  latitude,
  width = 300,
  height = 150,
  zoom = 17,
  markerColor = '1f88e5',
  style,
  onClick,
  customMarker,
}) => {
  const mapImageUrl = generateMapboxStaticImageUrl({
    longitude,
    latitude,
    width,
    height,
    zoom,
    markerColor,
    customMarker,
  });

  return (
    <img
      src={mapImageUrl}
      alt={address}
      style={{ width: '100%', height: 'auto', ...style }}
      onClick={onClick}
    />
  );
};

StaticMapPreview.propTypes = {
  address: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  zoom: PropTypes.number,
  markerColor: PropTypes.string,
  style: PropTypes.style,
  onClick: PropTypes.func,
};

export default StaticMapPreview;
