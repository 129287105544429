import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
} from '@mui/material';
import {
  countriesActions,
  countriesSelectors,
} from '../../../../redux/countriesSlice';

import { ADDRESSBOOK, FORM, PAGINATIONS, STRINGS } from '../../../../constants';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';
import AddressDetailsForm from '../../../../components/AddressDetailsForm';
import {
  AddressBookHelpers,
  AddressHelpers,
  ErrorHelpers,
  PostcodeHelpers,
  Validators,
} from '../../../../helpers';
import { useOverlay } from '../../../../features/Overlay';

const StyledCheckbox = styled(Checkbox)(() => ({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const AddressBookForm = ({
  isEditMode,
  onSidebarClose,
  currentPage,
  setPaginationParameters,
  setTotalPages,
  searchPageSize = PAGINATIONS.DEFAULT_SEARCH_PAGE_SIZE,
  resetSearch = () => {},
  selectedAddressBook,
  setSelectedAddressBook,
}) => {
  const countries = useSelector(countriesSelectors.getCountries);
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const { showSuccess, showError } = useCustomSnackbar();

  const onSubmit = useCallback(
    async formData => {
      overlay.show();
      const addressBookData = AddressBookHelpers.normalizeForPayload({
        data: formData,
      });

      try {
        if (isEditMode) {
          await dispatch(
            addressBooksSlice.actions.updateAddressBook({
              addressBookId: selectedAddressBook.addressBookId,
              addressBookData,
            })
          ).unwrap();
        } else {
          await dispatch(
            addressBooksSlice.actions.createAddressBook(addressBookData)
          ).unwrap();
        }

        const data = await dispatch(
          addressBooksSlice.actions.fetchAddressBooks({
            searchPage: currentPage,
            searchPageSize: searchPageSize,
          })
        ).unwrap();

        setPaginationParameters &&
          setPaginationParameters({
            currentPage: data.totalPages,
          });
        setTotalPages && setTotalPages(data.totalPages);
        setSelectedAddressBook(ADDRESSBOOK.ADDRESS_BOOK_INITIAL_FORM_VALUES);
        onSidebarClose();
        showSuccess({
          message: isEditMode
            ? STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.UPDATE
            : STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.SUCCESS.CREATE,
          variant: 'default',
        });
      } catch (error) {
        if (ErrorHelpers.isConflictError(error?.error)) {
          showError({
            message: STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CONFLICT,
          });
        } else {
          showError({
            message: isEditMode
              ? STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.UPDATE
              : STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CREATE,
          });
        }
      } finally {
        resetSearch();
        overlay.hide();
      }
    },
    [
      isEditMode,
      dispatch,
      currentPage,
      searchPageSize,
      setPaginationParameters,
      setTotalPages,
      onSidebarClose,
      showSuccess,
      selectedAddressBook.addressBookId,
      showError,
      resetSearch,
    ]
  );

  useEffect(() => {
    if (!countries?.length) {
      dispatch(countriesActions.fetchCountries());
    }
  }, [countries?.length, dispatch]);

  return (
    <Box
      sx={{
        padding: '32px 16px 24px',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h3'>
        {isEditMode
          ? STRINGS.ADDRESSES_SECTION.SIDEBAR_FORM.EDIT_ADDRESS
          : STRINGS.ADDRESSES_SECTION.SIDEBAR_FORM.ADD_NEW_ADDRESS}
      </Typography>
      <Box
        sx={{
          mt: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={selectedAddressBook}
          validate={values => {
            const selectedCountry = AddressHelpers.getCountryValue({
              countries,
              countryCode: values.address.countryCode,
            });

            const isPostcodeRequired = PostcodeHelpers.isPostcodeRequired(
              values.address.countryCode,
              countries
            );

            let requiredFields = [];

            if (isPostcodeRequired) {
              requiredFields = ['address.postcode'];
            }

            return Validators.validateWithJoi(
              AddressBookHelpers.normalizeFormValuesForValidation(values),
              Validators.requireKeysSchema(
                Validators.addressBookSchema(selectedCountry),
                requiredFields
              )
            );
          }}
          render={({ handleSubmit, pristine, invalid, submitting, values }) => (
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    maxHeight: 'calc(100vh - 148px)',
                    overflow: 'auto',
                    px: '16px',
                    mx: '-16px',
                    pt: 1,
                  }}
                >
                  <form id='addressBookSidebarForm' onSubmit={handleSubmit}>
                    <AddressDetailsForm
                      values={values}
                      validatePostcodeOnCountryChange
                      config={{
                        fields: {
                          addressId: {
                            name: FORM.ADDRESS_BOOK_FIELDS.ADDRESS_KEY.KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          coordinates: {
                            name: FORM.ADDRESS_BOOK_FIELDS.ADDRESS_COORDINATES
                              .KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          country: {
                            name: FORM.ADDRESS_BOOK_FIELDS.COUNTRY.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.COUNTRY.LABEL,
                            required: true,
                          },
                          postcode: {
                            name: FORM.ADDRESS_BOOK_FIELDS.POSTCODE.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.POSTCODE.LABEL,
                            canResetOption: true,
                            required: PostcodeHelpers.isPostcodeRequired(
                              values.address.countryCode,
                              countries
                            ),
                            maxLength: 8,
                          },
                          nickname: {
                            name: FORM.ADDRESS_BOOK_FIELDS.NICKNAME.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.NICKNAME.LABEL,
                            maxLength: 35,
                            required: true,
                          },
                          organisation: {
                            name: FORM.ADDRESS_BOOK_FIELDS.ORGANISATION.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.ORGANISATION.LABEL,
                            maxLength: 35,
                            canResetOption: true,
                          },
                          street: {
                            name: FORM.ADDRESS_BOOK_FIELDS.ADDRESS.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.ADDRESS.LABEL,
                            maxLength: 35,
                            canResetOption: true,
                            required: true,
                          },
                          locality: {
                            name: FORM.ADDRESS_BOOK_FIELDS.LOCALITY.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.LOCALITY.LABEL,
                            maxLength: 35,
                            canResetOption: true,
                          },
                          town: {
                            name: FORM.ADDRESS_BOOK_FIELDS.CITY.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.CITY.LABEL,
                            maxLength: 35,
                            canResetOption: true,
                            required: true,
                          },
                          county: {
                            name: FORM.ADDRESS_BOOK_FIELDS.COUNTY.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.COUNTY.LABEL,
                            maxLength: 35,
                            canResetOption: true,
                          },
                          contactName: {
                            name: FORM.ADDRESS_BOOK_FIELDS.CONTACT_NAME.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.CONTACT_NAME.LABEL,
                            maxLength: 35,
                            required: true,
                          },
                          email: {
                            name: FORM.ADDRESS_BOOK_FIELDS.EMAIL.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.EMAIL.LABEL,
                            maxLength: 100,
                            required: true,
                          },
                          phoneNumber: {
                            name: FORM.ADDRESS_BOOK_FIELDS.PHONE_NUMBER.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.PHONE_NUMBER.LABEL,
                            maxLength: 15,
                            required: true,
                          },
                          vatNumber: {
                            name: FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.LABEL,
                            maxLength: 45,
                          },
                          eoriNumber: {
                            name: FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.LABEL,
                            maxLength: 45,
                          },
                          pidNumber: {
                            name: FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.LABEL,
                            maxLength: 45,
                          },
                          ukimsNumber: {
                            name: FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
                            label: FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.LABEL,
                            maxLength: 32,
                          },
                        },
                        inputSize: 'small',
                        hasActionButtons: false,
                        idPrefix: 'addressBookSidebarForm',
                      }}
                    />
                    <Box sx={{ mt: 2, px: 2 }}>
                      <FormGroup>
                        <Field
                          type='checkbox'
                          name={FORM.ADDRESS_BOOK_FIELDS.IS_DEFAULT.KEY}
                        >
                          {({ input }) => (
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  {...input}
                                  // disable isDefault option for non GB countries
                                  disabled={
                                    !AddressHelpers.isCountryGB(
                                      values.address.countryCode
                                    ) || selectedAddressBook.isDefault
                                  }
                                />
                              }
                              label={FORM.ADDRESS_BOOK_FIELDS.IS_DEFAULT.LABEL}
                            />
                          )}
                        </Field>
                        <Field
                          type='checkbox'
                          name={FORM.ADDRESS_BOOK_FIELDS.IS_BILLING.KEY}
                        >
                          {({ input }) => (
                            <FormControlLabel
                              sx={{ mt: 1 }}
                              control={
                                <StyledCheckbox
                                  {...input}
                                  // disable isBilling option for non GB countries
                                  disabled={
                                    !AddressHelpers.isCountryGB(
                                      values.address.countryCode
                                    ) || selectedAddressBook.isBilling
                                  }
                                />
                              }
                              label={FORM.ADDRESS_BOOK_FIELDS.IS_BILLING.LABEL}
                            />
                          )}
                        </Field>
                      </FormGroup>
                    </Box>
                  </form>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                }}
              >
                <Button
                  sx={{ flex: 1 }}
                  variant='outlined'
                  onClick={onSidebarClose}
                >
                  {STRINGS.ADDRESSES_SECTION.SIDEBAR_FORM.CANCEL}
                </Button>
                <Button
                  sx={{ ml: 2, flex: 1 }}
                  variant='contained'
                  form='addressBookSidebarForm'
                  type='submit'
                  disabled={pristine || submitting || invalid}
                >
                  {STRINGS.ADDRESSES_SECTION.SIDEBAR_FORM.SAVE}
                </Button>
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

AddressBookForm.propTypes = {
  isEditMode: PropTypes.bool,
  onSidebarClose: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  setPaginationParameters: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  resetSearch: PropTypes.func,
  selectedAddressBook: PropTypes.object.isRequired,
  setSelectedAddressBook: PropTypes.func.isRequired,
};

export default AddressBookForm;
