import { Field } from 'react-final-form';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useCallback } from 'react';

export const FormInput = ({
  name,
  label,
  disabled,
  required,
  key,
  inputProps,
  fieldProps,
  helperText,
  showErrorUntouched = false,
  onBlur,
  maxLength,
  onChange,
  ...props
}) => {
  const { parse, ...restFieldProps } = fieldProps || {};
  const handleParse = useCallback(
    value => {
      if (!value) {
        return '';
      }

      return parse ? parse(value) : value;
    },
    [parse]
  );

  return (
    <Field name={name} parse={handleParse} {...restFieldProps} key={key}>
      {({ input, meta }) => {
        const isError = meta.error && (meta.touched || showErrorUntouched);

        return (
          <TextField
            {...input}
            label={label}
            disabled={disabled}
            required={required}
            inputProps={{ maxLength, ...inputProps }}
            error={isError}
            helperText={isError ? meta.error : helperText}
            InputLabelProps={{ shrink: !!input.value || !!meta.active }}
            onChange={e => {
              input.onChange(e);
              onChange && onChange(e);
            }}
            onBlur={e => {
              if (isNil(input.value)) {
                input.onChange({ ...e, target: { ...e.target, value: '' } });
              }
              input.onBlur(e);

              if (onBlur) {
                onBlur(e);
              }
            }}
            {...props}
          />
        );
      }}
    </Field>
  );
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  key: PropTypes.string,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  helperText: PropTypes.string,
  showErrorUntouched: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
};
