import { SvgIcon } from '@mui/material';

const Plane = ({ styles }) => (
  <SvgIcon sx={{ ...styles }} viewBox='0 0 36 35'>
    <mask
      id='mask0_15922_294763'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='24'
      height='25'
    >
      <rect y='0.5' width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_15922_294763)'>
      <path
        d='M9.92656 21.625L7.45156 17.025L2.85156 14.55L4.62656 12.8L8.25156 13.425L10.8016 10.875L2.87656 7.5L4.97656 5.35L14.6016 7.05L17.7016 3.95C18.0849 3.56667 18.5599 3.375 19.1266 3.375C19.6932 3.375 20.1682 3.56667 20.5516 3.95C20.9349 4.33333 21.1266 4.80417 21.1266 5.3625C21.1266 5.92083 20.9349 6.39167 20.5516 6.775L17.4266 9.9L19.1266 19.5L17.0016 21.625L13.6016 13.7L11.0516 16.25L11.7016 19.85L9.92656 21.625Z'
        fill='black'
        fillOpacity='0.56'
      />
    </g>
  </SvgIcon>
);

export default Plane;
