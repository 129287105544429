import { filter, get } from 'lodash';
import moment from 'moment-timezone';
import { REFERENCE_NAME } from '../constants';
import { DATE_FORMAT, FORM, SHIPMENT, STRINGS } from '../../../constants';
import { DateUtil, ObjectUtil } from '../../../utils';
import {
  requireKeysSchema,
  validateWithJoi,
} from '../../../helpers/validators';
import { shipmentSchema } from '../validators/shipment';
import {
  AddressBookHelpers,
  AddressHelpers,
  FormatterHelpers,
  NetworkHelpers,
  ShipmentHelpers,
} from '../../../helpers';

export const filterCollectionDatesInPast = dates =>
  filter(dates, date =>
    DateUtil.getUKCurrentMomentTime().isBefore(
      moment.tz(
        `${date.date} ${date.collectionCutOff}`,
        DATE_FORMAT.DATE_TIME_FORMAT,
        DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
      )
    )
  );

export const formatShipmentDate = ({
  date,
  collectionCutOff,
  firstInArea,
  lastInArea,
}) => ({
  date: moment
    .tz(
      `${date} ${collectionCutOff}`,
      DATE_FORMAT.DATE_TIME_FORMAT,
      DATE_FORMAT.EUROPE_LONDON_TIME_ZONE
    )
    .format(DATE_FORMAT.DATE_TIME_FORMAT),
  firstInArea: moment(firstInArea, DATE_FORMAT.TIME).isValid()
    ? firstInArea
    : SHIPMENT.DEFAULT_FIRST_IN_AREA,
  lastInArea: moment(lastInArea, DATE_FORMAT.TIME).isValid()
    ? lastInArea
    : SHIPMENT.DEFAULT_LAST_IN_AREA,
});

export const getDefaultShipmentDate = defaultCutOff => {
  const nextWorkingDay = DateUtil.getNextWorkingDay();

  return formatShipmentDate({
    date: nextWorkingDay.format(DATE_FORMAT.YYYY_MM_DD_FORMAT),
    collectionCutOff: defaultCutOff,
    firstInArea: SHIPMENT.DEFAULT_FIRST_IN_AREA,
    lastInArea: SHIPMENT.DEFAULT_LAST_IN_AREA,
  });
};

export const getValidationContext = (values, references, countries) => {
  const deliveryCountryValue = AddressHelpers.getCountryValue({
    countries,
    countryCode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY),
  });
  const collectionCountryValue = AddressHelpers.getCountryValue({
    countries,
    countryCode: get(values, FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
  });

  return {
    ...NetworkHelpers.getMaxWeightAndParcelNumber(references.networks),
    deliveryCountryValue,
    collectionCountryValue,
  };
};

export const validateFullShipmentSync = (values, references, countries) => {
  const context = getValidationContext(values, references, countries);
  const requiredFields = ShipmentHelpers.getFullShipmentRequiredFields(
    values,
    references,
    countries
  );
  const schema = requireKeysSchema(
    shipmentSchema({
      ...context,
      withContactDetails: true,
      productRequiredKeys: ObjectUtil.convertObjectKeysToArray(
        ShipmentHelpers.getProductRequiredFields(values)
      ),
    }),
    requiredFields
  );
  const errors = validateWithJoi(values, schema, {
    allowUnknown: true,
    context: {
      isProductRequired: ShipmentHelpers.isProductAvailable(values),
      groupErrors: true,
    },
  });

  return ObjectUtil.getLeafValues(errors);
};

export const getAddressBookFieldsPath = type => {
  const data = {
    delivery: {
      referencePath: REFERENCE_NAME.DELIVERY_ADDRESS_BOOK,
      fieldPath: FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
    },
    collection: {
      referencePath: REFERENCE_NAME.COLLECTION_ADDRESS_BOOK,
      fieldPath: FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
    },
  };

  return data[type];
};

export const getDisplayedPrice = price =>
  price ? FormatterHelpers.formatPrice(price) : '-.-';

export const getDisplayedExtraPrice = price =>
  price ? `+${FormatterHelpers.formatPrice(price)}` : STRINGS.FREE;

export const mapAddressDetailsToAddressBook = details =>
  AddressBookHelpers.normalizeForPayload({
    data: {
      address: details.address,
      email: details.contactDetails.email || details.notificationDetails?.email,
      nickname: details.contactDetails.contactName,
      contactName: details.contactDetails.contactName,
      phoneNumber:
        details.notificationDetails?.mobile || details.contactDetails.telephone,
      eoriNumber: details.eoriNumber,
      vatNumber: details.vatNumber,
      pidNumber: details.pidNumber,
      ukimsNumber: details.ukimsNumber,
      // todo remove if in the future we will not use it
      iossNumber: details.iossNumber,
    },
  });
