import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import FacePensiveIcon from '../../../components/icons/FacePensiveIcon';
import {
  BASKET,
  HOME,
  SIGN_IN_WITH_EMAIL,
  TRACK,
} from '../../../constants/routes';
import {
  BACK_TO_BASKET_CART,
  BACK_TO_HELP,
  BACK_TO_HOME,
  BACK_TO_LOGIN,
  BACK_TO_ORDERS,
  CANNOT_PROCESS_CLAIM,
  CLAIM_WINDOW_EXPIRED,
  IF_YOU_RECEIVED_THIS_LINK,
  IT_LOOKS_LIKE_WE_ENCOUNTERED,
  IT_LOOKS_LIKE_YOUR_TRYING,
  LOGIN,
  OOPS_YOU_HAVE_REACHED_MAX_NUMBER_OF_BASKET_ITEMS,
  ORDER_WILL_EXPIRE,
  REVIEW_YOUR_BASKET_BEFORE_ADDING_MORE,
  SORRY_YOU_DONT_HAVE_ACCESS,
  TRACK_PARCEL,
  WE_HAVE_A_LITTLE_PROBLEM,
  YOUR_BASKET_IS_FULL,
} from '../constants';
import { MAX_BASKET_ITEMS } from '../../../constants/env';

export const ERROR_BOUNDARY_TYPES = {
  DEFAULT: 'default',
  WE_HAVE_A_LITTLE_PROBLEM: 'weHaveALittleProblem',
  LOADING_CRASH_WITH_SIGN_IN: 'loadingCrashWithSingIn',
  LOADING_CRASH: 'loadingCrash',
  DONT_HAVE_ACCESS: 'dontHaveAccess',
  REACHED_MAX_BASKET_ITEMS: 'reachedMaxBasketItems',
  DONT_HAVE_ACCESS_LOGGED: 'dontHaveAccessLogged',
  ORDER_ERROR: 'orderError',
  CANNOT_PROCESS_CLAIM: 'canNotProcessClaim',
};

const getErrorBoundaryConfig = (type, navigate) => {
  switch (type) {
    case ERROR_BOUNDARY_TYPES.WE_HAVE_A_LITTLE_PROBLEM:
      return (error = '') => ({
        title: WE_HAVE_A_LITTLE_PROBLEM,
        descriptionArray: [error],
        mainIcon: <FacePensiveIcon />,
      });
    case ERROR_BOUNDARY_TYPES.LOADING_CRASH_WITH_SIGN_IN:
      return () => ({
        title: WE_HAVE_A_LITTLE_PROBLEM,
        descriptionArray: [IT_LOOKS_LIKE_WE_ENCOUNTERED],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button
            variant={'contained'}
            onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
          >
            {BACK_TO_LOGIN}
          </Button>
        ),
      });
    case ERROR_BOUNDARY_TYPES.LOADING_CRASH:
      return (error = '') => ({
        title: WE_HAVE_A_LITTLE_PROBLEM,
        descriptionArray: [error],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button variant={'contained'} onClick={() => navigate(HOME)}>
            {BACK_TO_HOME}
          </Button>
        ),
      });
    case ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS_LOGGED:
      return () => ({
        title: SORRY_YOU_DONT_HAVE_ACCESS,
        descriptionArray: [SORRY_YOU_DONT_HAVE_ACCESS],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button variant={'contained'} onClick={() => navigate(HOME)}>
            {BACK_TO_HOME}
          </Button>
        ),
      });
    case ERROR_BOUNDARY_TYPES.REACHED_MAX_BASKET_ITEMS:
      return () => ({
        title: YOUR_BASKET_IS_FULL,
        descriptionArray: [
          OOPS_YOU_HAVE_REACHED_MAX_NUMBER_OF_BASKET_ITEMS(MAX_BASKET_ITEMS),
          REVIEW_YOUR_BASKET_BEFORE_ADDING_MORE,
          ORDER_WILL_EXPIRE,
        ],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button variant={'contained'} onClick={() => navigate(BASKET)}>
            {BACK_TO_BASKET_CART}
          </Button>
        ),
      });
    case ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS:
      return () => ({
        title: SORRY_YOU_DONT_HAVE_ACCESS,
        descriptionArray: [
          IT_LOOKS_LIKE_YOUR_TRYING,
          IF_YOU_RECEIVED_THIS_LINK,
        ],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Box sx={{ display: 'flex' }}>
            <Button
              variant='contained'
              sx={{ marginRight: '12px' }}
              onClick={() => navigate(SIGN_IN_WITH_EMAIL)}
            >
              {LOGIN}
            </Button>
            <Button variant='outlined' onClick={() => navigate(TRACK)}>
              {TRACK_PARCEL}
            </Button>
          </Box>
        ),
      });
    case ERROR_BOUNDARY_TYPES.ORDER_ERROR:
      return (error = '') => ({
        title: WE_HAVE_A_LITTLE_PROBLEM,
        descriptionArray: [error],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button
            variant='contained'
            onClick={() => navigate('/profile/orders')}
          >
            {BACK_TO_ORDERS}
          </Button>
        ),
      });
    case ERROR_BOUNDARY_TYPES.CANNOT_PROCESS_CLAIM:
      return date => ({
        title: CANNOT_PROCESS_CLAIM,
        descriptionArray: [CLAIM_WINDOW_EXPIRED(date)],
        mainIcon: <FacePensiveIcon />,
        actionContent: () => (
          <Button variant='contained' onClick={() => navigate(0)}>
            {BACK_TO_HELP}
          </Button>
        ),
      });
    default:
      return () => {};
  }
};

export const useErrorBoundaryConfigurations = () => {
  const navigate = useNavigate();
  return Object.keys(ERROR_BOUNDARY_TYPES).reduce((configs, type) => {
    configs[ERROR_BOUNDARY_TYPES[type]] = getErrorBoundaryConfig(
      ERROR_BOUNDARY_TYPES[type],
      navigate
    );
    return configs;
  }, {});
};
