import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

const DeleteIconButton = ({ item, onClick, disabled }) => (
  <IconButton
    size='large'
    aria-label='delete'
    color='inherit'
    sx={{
      display: 'inline-flex',
      p: 1,
    }}
    disabled={disabled}
    onClick={event => {
      onClick(item);
      event.stopPropagation();
    }}
  >
    <DeleteIcon />
  </IconButton>
);

DeleteIconButton.propTypes = {
  item: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DeleteIconButton;
