import { Box, IconButton, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { ShipmentPrice } from '@dpdgroupuk/consumer-shipping-helper';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import {
  BasketCardHeader,
  DefaultBasketCard,
} from '../../../components/BasketCard';
import ConfigSlice from '../../../redux/configSlice';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {
  INCORRECT_PICKUP_POINT_MSG,
  PICKUP_POINT_ISSUE,
} from '../../../constants/strings';
import BasketCardFooter from './BasketCardFooter';
import IncorrectOrderAlert from './IncorrectOrderAlert';

const getCardPrice = (
  price,
  isSmallScreen,
  isContentOpened,
  setIsContentOpened
) =>
  price.totalIncVatAmount && (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Typography variant='h3' color='primary.main'>
        {`£${price.totalIncVatAmount.toFixed(2)}`}
      </Typography>
      {isSmallScreen && (
        <IconButton
          onClick={() => setIsContentOpened(!isContentOpened)}
          sx={{ paddingRight: 0 }}
        >
          {isContentOpened ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      )}
    </Box>
  );

const BasketCard = ({
  basketItem,
  references,
  onRemove,
  isInCheckoutList,
  onAddToCheckout,
  onRemoveFromCheckout,
  errors = {},
  isOnlyItem,
  isOnlyItemInCheckout,
}) => {
  const { basketErrors, pickupLocationError } =
    errors[basketItem.basketItemId] || {};
  const isSmallScreen = useBreakpoint('sm');
  const [isContentOpened, setIsContentOpened] = useState(!isSmallScreen);
  const { shipment } = basketItem;
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const price = ShipmentPrice.calculateShipmentPrice(
    shipment,
    references.networkPrice,
    priceConfig
  ).toViewJSON(2);

  const cardPrice = getCardPrice(
    price,
    isSmallScreen,
    isContentOpened,
    setIsContentOpened
  );

  return (
    <DefaultBasketCard
      outboundConsignment={shipment?.outboundConsignment}
      containerStyles={{ mt: 2 }}
      contentContainerStyles={{ p: 2, py: 3 }}
      isContentOpened={isContentOpened || !isSmallScreen}
      references={references}
      header={
        <BasketCardHeader
          shipment={shipment}
          rightCardContent={cardPrice}
          error={!isEmpty(basketErrors) && isSmallScreen}
        />
      }
      contentHeader={
        <>
          {!isEmpty(basketErrors) && (
            <IncorrectOrderAlert errors={basketErrors} />
          )}
          {pickupLocationError && (
            <IncorrectOrderAlert
              errors={[INCORRECT_PICKUP_POINT_MSG]}
              title={PICKUP_POINT_ISSUE}
            />
          )}
        </>
      }
      footer={
        <BasketCardFooter
          basketItem={basketItem}
          onAddToCheckout={onAddToCheckout}
          onRemoveFromCheckout={onRemoveFromCheckout}
          onRemove={onRemove}
          isInCheckoutList={isInCheckoutList}
          withCheckoutControll={
            isEmpty(basketErrors) && !pickupLocationError && !isOnlyItem
          }
          isCheckoutControllDisabled={isOnlyItemInCheckout}
        />
      }
    />
  );
};

BasketCard.propTypes = {
  references: PropTypes.shape({
    collectionPickup: PropTypes.object,
    deliveryPickup: PropTypes.object,
    networkPrice: PropTypes.object,
  }),
  basketItem: PropTypes.shape({
    basketItemId: PropTypes.string,
    shipment: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  isInCheckoutList: PropTypes.bool,
  onAddToCheckout: PropTypes.func,
  onRemoveFromCheckout: PropTypes.func,
  errors: PropTypes.object,
  isOnlyItem: PropTypes.bool,
  isOnlyItemInCheckout: PropTypes.bool,
};

export default BasketCard;
