import apis from './apis';

export const getShipmentById = async shipmentId => {
  const response = await apis.get(`shipments/${shipmentId}/`);

  return response.data;
};

export const getShipmentByParcelNumber = async parcelNumber => {
  const response = await apis.get('shipments/', {
    params: {
      parcelNumber,
    },
  });

  return response.data;
};
