import { SvgIcon } from '@mui/material';

const Road = ({ styles }) => (
  <SvgIcon sx={{ ...styles }} viewBox='0 0 36 35'>
    <mask
      id='mask0_15922_294734'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='24'
      height='25'
    >
      <rect y='0.5' width='24' height='24' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_15922_294734)'>
      <path
        d='M4 20.5V4.5H6V20.5H4ZM11 20.5V16.5H13V20.5H11ZM18 20.5V4.5H20V20.5H18ZM11 14.5V10.5H13V14.5H11ZM11 8.5V4.5H13V8.5H11Z'
        fill='black'
        fillOpacity='0.56'
      />
    </g>
  </SvgIcon>
);

export default Road;
