import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePaginationParameters = ({ defaultPageSize }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  const currentPage = Number(searchParams.get('currentPage') || 1);
  const searchPageSize = Number(
    searchParams.get('searchPageSize') || defaultPageSize
  );

  const setPaginationParameters = useCallback(
    (props, replace = false) => {
      setSearchParams(
        {
          searchPageSize,
          currentPage,
          ...props,
        },
        { replace }
      );
    },
    [setSearchParams, currentPage, searchPageSize]
  );

  useEffect(() => {
    setPaginationParameters({ currentPage, searchPageSize }, true);
  }, []);
  return {
    currentPage,
    searchPageSize,
    setPaginationParameters,
    totalPages,
    setTotalPages,
  };
};
