import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import Joi from 'joi';
import { OrderSliceSelectors } from '../../slice';
import { DateUtil } from '../../../../utils';
import { FORM, POINT_TYPE, SHIPMENT } from '../../../../constants';
import { parcelSchema } from '../../validators/parcel';
import {
  NetworkHelpers,
  ShipmentHelpers,
  Validators,
} from '../../../../helpers';
import { countriesSelectors } from '../../../../redux/countriesSlice';

export const getOutboundNetworkParameters = createSelector(
  OrderSliceSelectors.getValues,
  NetworkHelpers.getNetworkQuery
);

export const getNetworksByParcelParams = createSelector(
  [OrderSliceSelectors.getReferences, OrderSliceSelectors.getValues],
  ({ networks }, values) =>
    NetworkHelpers.getNetworksByParcelSizeAndWeight(networks, values)
);

export const getFilteredNetworks = createSelector(
  [getNetworksByParcelParams, OrderSliceSelectors.getValues],
  (networks, values) => {
    let filteredNetworks = networks;
    const collectFrom = get(values, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY);
    const deliveryTo = get(values, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY);

    const shippingDate = get(values, FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
    // Only show Sat or Sun options if the selected collection date is Fri or Sat
    if (!DateUtil.isFriOrSat(new Date(shippingDate))) {
      filteredNetworks = networks.filter(
        ({ networkKey }) => !SHIPMENT.WEEKEND_NETWORKS.includes(networkKey)
      );
    }

    if (deliveryTo === POINT_TYPE.SHOP) {
      filteredNetworks = filteredNetworks.filter(({ networkDetails }) =>
        ShipmentHelpers.isFlagOptional(networkDetails, 'shipToPickup')
      );
    } else {
      filteredNetworks = filteredNetworks.filter(({ networkDetails }) =>
        collectFrom === POINT_TYPE.SHOP
          ? !networkDetails?.premium &&
            !ShipmentHelpers.isFlagOptional(networkDetails, 'shipToPickup')
          : !ShipmentHelpers.isFlagOptional(networkDetails, 'shipToPickup')
      );
    }

    return NetworkHelpers.sortNetworksByPrice(filteredNetworks);
  }
);

export const getMaxWeightAndNumberOfParcels = createSelector(
  [OrderSliceSelectors.getReferences],
  references => NetworkHelpers.getMaxWeightAndParcelNumber(references.networks)
);

export const validateParcelsValues = createSelector(
  [OrderSliceSelectors.getValues, getMaxWeightAndNumberOfParcels],
  (values, context) => {
    const parcels = get(values, FORM.SHIPMENT_FIELDS.PARCELS.KEY, []);
    const parcelsWithoutProducts = parcels.map(({ products, ...rest }) => rest);

    const { error } = Joi.array()
      .items(
        Validators.requireKeysSchema(parcelSchema(context), [
          '_weight',
          '_quantity',
          '_value',
          '_width',
          '_height',
          '_length',
        ])
      )
      .min(1)
      .validate(parcelsWithoutProducts);

    return error;
  }
);

export const getParcelDetailsRequiredFields = createSelector(
  [
    OrderSliceSelectors.getValues,
    OrderSliceSelectors.getReferences,
    countriesSelectors.getCountries,
  ],
  ShipmentHelpers.getParcelDetailsRequiredFields
);

export const getParcelDetailsRequiredFieldsMap = createSelector(
  [getParcelDetailsRequiredFields],
  requiredFields =>
    requiredFields.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {})
);

export const getShipToShopAvailable = createSelector(
  [getNetworksByParcelParams],
  networks =>
    networks.some(
      ({ networkDetails }) =>
        networkDetails.shipToPickup === SHIPMENT.REQUIRED_TYPE.OPTIONAL
    )
);
