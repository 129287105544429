import { Box, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import get from 'lodash/get';
import { ChatBotApp } from '@dpdgroupuk/mydpd-chat';
import ReduxifiedWizard from '../../features/Wizard/ReduxifiedWizard';
import Loader from '../../features/Loader/Loader';
import { BasketSelectors } from '../../redux/basket';
import { ROUTE_PATH, STRINGS } from '../../constants';
import Error from '../Auth/SignIn/Error';
import { useAuth } from '../../features/Auth';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import Navigation from './components/Navigation';
import ParcelDetails from './pages/ParcelDetails';
import { OrderSlice } from './slice';
import AddressDetails from './pages/AddressDetails';
import Payment from './pages/Payment';
import { REFERENCE_NAME } from './constants';
import CustomsDetails from './pages/CustomsDetails';

const Order = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { currentSession } = useAuth();
  const dispatch = useDispatch();
  const snackbar = useCustomSnackbar();
  const [searchParams] = useSearchParams();
  const basketItemId = searchParams.get('basketItemId');
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );
  const basketItemFromHistoryState = get(location, 'state.shipment');

  useEffect(() => {
    // NOTE; Forcing to go through quotes page to populate default data
    if (
      !currentSession.basketId ||
      (!basketItem?.shipment && !basketItemFromHistoryState)
    ) {
      navigate(ROUTE_PATH.QUOTES, { replace: true });
      snackbar.showWarning({
        message: STRINGS.YOUR_SESSION_HAS_EXPIRED,
      });
    }
  }, []);

  // TODO: Potentially we will need to add redirect to home if no basketItem?.shipment or basketItemFromHistoryState present
  const onLoad = useCallback(async () => {
    dispatch(
      OrderSlice.actions.initialize({
        values: basketItem?.shipment || basketItemFromHistoryState,
        references: {
          networks: [],
          [REFERENCE_NAME.COLLECTION_PICKUP]: null,
          [REFERENCE_NAME.DELIVERY_PICKUP]: null,
        },
      })
    );
  }, []);

  return (
    <Loader
      promiseFn={onLoad}
      errorComponent={error => <Error values={{ error }} />}
    >
      <Box
        sx={{
          py: { xs: 2 },
          p: { md: 4 },
          background: theme.palette.primary.pageBackground,
          flexGrow: 1,
        }}
      >
        <Grid container spacing={0} alignItems='center' justifyContent='center'>
          <Grid item xs={12} md={10} lg={8} xl={6}>
            <ReduxifiedWizard
              isLazyMount={true}
              isStepValues={false}
              slice={OrderSlice}
              nav={<Navigation />}
            >
              <ParcelDetails stepName='parcelDetails' />
              <AddressDetails stepName='addressDetails' />
              <CustomsDetails stepName='customsDetails' />
              <Payment stepName='payment' />
            </ReduxifiedWizard>
          </Grid>
        </Grid>
      </Box>
    </Loader>
  );
};

export default Order;
