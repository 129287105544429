import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import { ORDER_DETAILS } from '../../../constants/strings';
import { FormatterHelpers } from '../../../helpers';

const AddressDetails = ({ label, text }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: theme.palette.grey[200],
      }}
    >
      <Typography
        variant='body2'
        color='textSecondary'
        sx={{
          fontFamily: 'Roboto',
          marginY: 1,
        }}
      >
        {label}
      </Typography>
      <Typography
        variant='body1'
        color='textSecondary'
        sx={{
          fontFamily: 'Roboto',
          marginY: 1,
          fontWeight: 400,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const AddressSection = ({ deliveryDetails, collectionDetails }) => (
  <>
    {deliveryDetails?.address && (
      <AddressDetails
        label={ORDER_DETAILS.RECIPIENT_ADDRESS}
        text={FormatterHelpers.formatAddressLine({
          contactName: deliveryDetails.notificationDetails.contactName,
          ...deliveryDetails.address,
        })}
      />
    )}
    {collectionDetails?.address && (
      <AddressDetails
        label={ORDER_DETAILS.PICKUP_ADDRESS}
        text={FormatterHelpers.formatAddressLine(collectionDetails.address)}
      />
    )}
  </>
);
export default AddressSection;
