/* eslint-disable max-lines */
import { StringUtil } from '../utils';
import { HELP_LABELS } from './strings';

export const COMPANY_NAME = 'Company name';
export const ADDRESS_LINE_1 = 'Address Line 1';
export const ADDRESS_LINE_2 = 'Address Line 2';
export const CITY = 'City';
export const COUNTY = 'County';
export const POSTAL_ZIP_CODE = 'Postal/Zip code';
export const COUNTRY = 'Country';
export const CURRENCY = 'Currency';
export const CUSTOMS_VALUE = 'Total Value';
export const CUSTOMS_VALUE_$ = `${CUSTOMS_VALUE} ({0})`;
export const REASON_FOR_EXPORT = 'Reason for export';
export const QUANTITY = 'Quantity';
export const VALUE = 'Value (£)';
export const WEIGHT = 'Weight (kg)';
export const HEIGHT = 'Height (cm)';
export const WIDTH = 'Width (cm)';
export const LENGTH = 'Length (cm)';
export const CONTACT_NAME = 'Contact Name';
export const NICKNAME = 'Nickname';
export const EMAIL = 'Email';
export const PHONE_NUMBER = 'Phone Number';
export const VAT_NUMBER = 'VAT Number';
export const EORI_NUMBER = 'EORI Number';
export const PID_NUMBER = 'PID Number';
export const UKIMS_NUMBER = 'UKIMS Number';
export const YOU_ARE = 'You are a';
export const RECIPIENT_IS = 'Recipient is a';
export const YOUR = 'Your';
export const RECEIVERS = "Receiver's";
export const PID = 'PID';
export const EORI = 'EORI';
export const VAT = 'VAT';
export const IOSS = 'IOSS';
export const FDA_REGISTRATION = 'FDA Registration';
export const UKIMS = 'UKIMS';
export const $_$_NUMBER = '{0} {1} Number';
export const $_NUMBER = '{0} Number';
export const MOBILE_NUMBER = 'Mobile number';
export const DEST_TAX_ID_REG_NUMBER = 'Destination Tax ID/Reg Number';
export const GST_VAT_PAID = 'GST/VAT Paid';
export const BILLING_TERMS = 'International Billing Terms';
export const INVOICE_TYPE = 'Type Of Shipment';
export const GENERATE_CUSTOMS_DATA = 'Generate Customs Data';
export const TOTAL_WEIGHT = 'Total Weight';
export const RECEIVER_NOTIFICATION_DETAILS = 'Receiver notification details';
export const RECEIVER_CONTACT_DETAILS = 'Receiver contact details';
export const YOUR_EMAIL = 'Your email';
export const YOUR_CONTACT_NAME = 'Your contact name';
export const YOUR_PHONE_NUMBER = 'Your phone number';
export const DELIVERY_INFORMATION = 'Delivery information';

export const FIELDS = {
  DESTINATION_COUNTRY: {
    KEY: 'destinationCountry',
    LABEL: 'Destination Country',
  },
  ADDRESS_KEY: {
    KEY: 'addressId',
    LABEL: 'Destination address',
  },
  POSTCODE: {
    KEY: 'postcode',
    LABEL: 'Start typing a postcode',
    PLACEHOLDER: 'Start typing a postcode',
  },
  POSTCODE_OR_ORDER_ID_SEARCH: {
    KEY: 'postcodeOrOrderId',
    LABEL: 'Postcode or order Id',
    PLACEHOLDER: 'Postcode or order Id',
  },
  COUNTRY: {
    KEY: 'country',
    LABEL: COUNTRY,
  },
  COMMODITY: {
    KEY: 'commodity',
    LABEL: 'Search term',
    PLACEHOLDER: 'Search term',
  },
  ADDRESS: {
    KEY: 'address',
    LABEL: 'Start typing your address',
  },
  ADDRESS_COORDINATES: {
    KEY: 'addressCoordinates',
    LABEL: 'Coordinates',
  },
  EMAIL: {
    KEY: 'email',
    LABEL: 'Email Address',
    PLACEHOLDER: 'Enter your email address',
  },
  PARCEL_NUMBER: {
    KEY: 'parcelNumber',
    LABEL: 'Enter parcel number',
    PLACEHOLDER: 'Enter parcel number',
  },
  PHONE_NUMBER: {
    KEY: 'phoneNumber',
    LABEL: MOBILE_NUMBER,
  },
  PIN: {
    KEY: 'pin',
  },
};

export const COLLECTION_DETAILS_KEY = 'outboundConsignment.collectionDetails';
export const COLLECTION_ADDRESS_KEY = `${COLLECTION_DETAILS_KEY}.address`;
export const DELIVERY_DETAILS_KEY = 'outboundConsignment.deliveryDetails';
export const DESTINATION_ADDRESS_KEY = `${DELIVERY_DETAILS_KEY}.address`;
export const CONSIGNMENT_NETWORK = 'outboundConsignment._network';
export const COLLECTION_CONTACT_DETAILS_KEY = `${COLLECTION_DETAILS_KEY}.contactDetails`;
export const DELIVERY_CONTACT_DETAILS_KEY = `${DELIVERY_DETAILS_KEY}.contactDetails`;
export const DELIVERY_NOTIFICATION_DETAILS_KEY = `${DELIVERY_DETAILS_KEY}.notificationDetails`;

export const SHIPMENT_FIELDS = {
  DESTINATION_ADDRESS: {
    KEY: DESTINATION_ADDRESS_KEY,
  },
  DESTINATION_COUNTRY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.countryCode`,
    LABEL: 'Destination Country',
  },
  DESTINATION_ADDRESS_KEY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
  },
  DESTINATION_ADDRESS_COORDINATES: {
    KEY: `${DESTINATION_ADDRESS_KEY}._coordinates`,
  },
  DESTINATION_POSTCODE: {
    KEY: `${DESTINATION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  DESTINATION_COUNTY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  DESTINATION_CITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  DESTINATION_ORGANISATION: {
    KEY: `${DESTINATION_ADDRESS_KEY}.organisation`,
    LABEL: COMPANY_NAME,
  },
  DESTINATION_STREET: {
    KEY: `${DESTINATION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  DESTINATION_LOCALITY: {
    KEY: `${DESTINATION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  DELIVERY_INSTRUCTIONS: {
    KEY: 'outboundConsignment.deliveryInstructions',
    LABEL: DELIVERY_INFORMATION,
  },
  COLLECTION_DETAILS: {
    KEY: COLLECTION_DETAILS_KEY,
  },
  COLLECTION_ADDRESS: {
    KEY: COLLECTION_ADDRESS_KEY,
  },
  COLLECTION_CONTACT_DETAILS: {
    KEY: COLLECTION_CONTACT_DETAILS_KEY,
  },
  COLLECTION_COUNTRY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.countryCode`,
    LABEL: COUNTRY,
  },
  COLLECTION_ADDRESS_KEY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.${FIELDS.ADDRESS_KEY.KEY}`,
  },
  COLLECTION_ADDRESS_COORDINATES: {
    KEY: `${COLLECTION_ADDRESS_KEY}._coordinates`,
  },
  COLLECTION_POSTCODE: {
    KEY: `${COLLECTION_ADDRESS_KEY}.postcode`,
    LABEL: POSTAL_ZIP_CODE,
  },
  COLLECTION_CITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.town`,
    LABEL: CITY,
  },
  COLLECTION_COUNTY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.county`,
    LABEL: COUNTY,
  },
  COLLECTION_ORGANISATION: {
    KEY: `${COLLECTION_ADDRESS_KEY}.organisation`,
    LABEL: COMPANY_NAME,
  },
  COLLECTION_STREET: {
    KEY: `${COLLECTION_ADDRESS_KEY}.street`,
    LABEL: ADDRESS_LINE_1,
  },
  COLLECTION_LOCALITY: {
    KEY: `${COLLECTION_ADDRESS_KEY}.locality`,
    LABEL: ADDRESS_LINE_2,
  },
  TOTAL_WEIGHT: {
    KEY: 'outboundConsignment.totalWeight',
  },
  NUMBER_OF_PARCELS: {
    KEY: 'outboundConsignment.numberOfParcels',
  },
  PICKUP_DETAILS: {
    KEY: 'outboundConsignment.pickupDetails',
  },
  PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment.pickupDetails.pickupLocationCode',
  },
  DROP_AT_SHOP: {
    KEY: 'outboundConsignment.collectionDetails.dropAtShop',
  },
  GENERATE_CUSTOMS_DATA: {
    KEY: 'generateCustomsData',
    LABEL: GENERATE_CUSTOMS_DATA,
  },
  CURRENCY: {
    KEY: 'outboundConsignment.currency',
    LABEL: CURRENCY,
  },
  CUSTOMS_VALUE: {
    KEY: 'outboundConsignment.customsValue',
    LABEL: currency => StringUtil.formatMessage(CUSTOMS_VALUE_$, currency),
  },
  IOSS_NUMBER: {
    KEY: 'outboundConsignment.destinationTaxId',
    LABEL: StringUtil.formatMessage($_NUMBER, IOSS),
  },
  GST_VAT_PAID: {
    KEY: 'outboundConsignment.gstVatPaid',
    LABEL: GST_VAT_PAID,
  },
  LIABILITY: {
    KEY: 'outboundConsignment.liability',
  },
  LIABILITY_VALUE: {
    KEY: 'outboundConsignment.liabilityValue',
  },
  DELIVERY_DESCRIPTION: {
    KEY: 'outboundConsignment.deliveryDescription',
    LABEL: 'Delivery Description',
  },
  NETWORK_CODE: {
    KEY: 'outboundConsignment.networkCode',
  },
  NETWORK: {
    KEY: CONSIGNMENT_NETWORK,
  },
  NETWORK_DESC: {
    KEY: `${CONSIGNMENT_NETWORK}.networkDesc`,
  },
  NETWORK_BUS_ZONE: {
    KEY: `${CONSIGNMENT_NETWORK}.busZone`,
  },
  SHIPMENT_DATE: {
    KEY: 'shipmentDate',
    LABEL: 'Select a collection date',
  },
  PARCELS: {
    KEY: 'outboundConsignment.parcels',
  },
  PARCEL_HEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._height`,
    LABEL: HEIGHT,
  },
  PARCEL_WIDTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._width`,
    LABEL: WIDTH,
  },
  PARCEL_LENGTH: {
    KEY: index => `outboundConsignment.parcels[${index}]._length`,
    LABEL: LENGTH,
  },
  PARCEL_WEIGHT: {
    KEY: index => `outboundConsignment.parcels[${index}]._weight`,
    LABEL: WEIGHT,
  },
  PARCEL_QUANTITY: {
    KEY: index => `outboundConsignment.parcels[${index}]._quantity`,
    LABEL: QUANTITY,
  },
  PARCEL_VALUE: {
    KEY: index => `outboundConsignment.parcels[${index}]._value`,
    LABEL: VALUE,
  },
  DROP_OFF_DETAILS: {
    KEY: 'outboundConsignment._dropOffDetails',
  },
  DROP_OFF_PICKUP_LOCATION_CODE: {
    KEY: 'outboundConsignment._dropOffDetails.pickupLocationCode',
  },
  COLLECT_FROM: {
    KEY: 'outboundConsignment.collectionDetails._collectFrom',
  },
  DELIVER_TO: {
    KEY: 'outboundConsignment.deliveryDetails._deliverTo',
  },
  COLLECTION_FIRST_IN_AREA: {
    KEY: 'outboundConsignment.collectionDetails._firstInArea',
  },
  COLLECTION_LAST_IN_AREA: {
    KEY: 'outboundConsignment.collectionDetails._lastInArea',
  },

  // address details fields
  COLLECTION_ADDRESS_BOOK_ID: {
    KEY: `${COLLECTION_CONTACT_DETAILS_KEY}._addressBookId`,
  },
  COLLECTION_CONTACT_NAME: {
    KEY: `${COLLECTION_CONTACT_DETAILS_KEY}.contactName`,
  },
  COLLECTION_CONTACT_EMAIL: {
    KEY: `${COLLECTION_CONTACT_DETAILS_KEY}.email`,
  },
  COLLECTION_CONTACT_TELEPHONE: {
    KEY: `${COLLECTION_CONTACT_DETAILS_KEY}.telephone`,
  },
  DELIVERY_CONTACT_TELEPHONE: {
    KEY: `${DELIVERY_CONTACT_DETAILS_KEY}.telephone`,
  },
  DELIVERY_DETAILS: {
    KEY: 'outboundConsignment.deliveryDetails',
  },
  DELIVERY_ADDRESS_BOOK_ID: {
    KEY: `${DELIVERY_CONTACT_DETAILS_KEY}._addressBookId`,
  },
  DELIVERY_CONTACT_NAME: {
    KEY: `${DELIVERY_CONTACT_DETAILS_KEY}.contactName`,
  },
  DELIVERY_CONTACT_EMAIL: {
    KEY: `${DELIVERY_NOTIFICATION_DETAILS_KEY}.email`,
  },
  DELIVERY_CONTACT_MOBILE: {
    KEY: `${DELIVERY_NOTIFICATION_DETAILS_KEY}.mobile`,
  },
  INVOICE_TYPE: {
    KEY: 'invoice.invoiceType',
    LABEL: INVOICE_TYPE,
  },
  INVOICE_INTERNATIONAL_BILLING_TERMS: {
    KEY: 'invoice.termsOfDelivery',
    LABEL: BILLING_TERMS,
  },
  INVOICE_EXPORT_REASON: {
    KEY: 'invoice.exportReason',
    LABEL: REASON_FOR_EXPORT,
  },
  INVOICE_EXPORTER_EORI_NUMBER: {
    KEY: 'invoice.exporterDetails.eoriNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, EORI),
  },
  INVOICE_EXPORTER_VAT_NUMBER: {
    KEY: 'invoice.exporterDetails.vatNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, VAT),
  },
  INVOICE_EXPORTER_UKIMS_NUMBER: {
    KEY: 'invoice.exporterDetails.ukimsNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, UKIMS),
  },
  INVOICE_EXPORTER_FDA_NUMBER: {
    KEY: 'invoice.exporterDetails.fdaRegistrationNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, FDA_REGISTRATION),
  },
  INVOICE_EXPORTER_TELEPHONE: {
    KEY: 'invoice.exporterDetails.contactDetails.telephone',
  },
  INVOICE_EXPORTER_CONTACT_NAME: {
    KEY: 'invoice.exporterDetails.contactDetails.contactName',
  },
  INVOICE_EXPORTER_IS_BUSINESS: {
    KEY: 'invoice.exporterDetails.isBusiness',
    LABEL: YOU_ARE,
  },
  INVOICE_IMPORTER_EORI_NUMBER: {
    KEY: 'invoice.importerDetails.eoriNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, EORI),
  },
  INVOICE_IMPORTER_VAT_NUMBER: {
    KEY: 'invoice.importerDetails.vatNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, VAT),
  },
  INVOICE_IMPORTER_UKIMS_NUMBER: {
    KEY: 'invoice.importerDetails.ukimsNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, UKIMS),
  },
  INVOICE_IMPORTER_PID_NUMBER: {
    KEY: 'invoice.importerDetails.pidNumber',
    LABEL: StringUtil.formatMessage($_NUMBER, PID),
  },
  INVOICE_IMPORTER_IS_BUSINESS: {
    KEY: 'invoice.importerDetails.isBusiness',
    LABEL: RECIPIENT_IS,
  },
  INVOICE_IMPORTER_AT_RISK: {
    KEY: 'invoice.importerDetails.atRisk',
  },
  INVOICE_IMPORTER_CONTACT_NAME: {
    KEY: 'invoice.importerDetails.contactDetails.contactName',
  },
  INVOICE_IMPORTER_TELEPHONE: {
    KEY: 'invoice.importerDetails.contactDetails.telephone',
  },
  INVOICE_IMPORTER_EMAIL: {
    KEY: 'invoice.importerDetails.contactDetails.email',
  },
  CONSIGNMENT_PRODUCT_DESCRIPTION: {
    KEY: (parcelIndex, productIndex) =>
      `outboundConsignment.parcels[${parcelIndex}].products[${productIndex}].productDescription`,
  },
  PRODUCT_DESCRIPTION: {
    KEY: 'productDescription',
    LABEL: 'Detailed product description',
  },
  PRODUCT_QUANTITY: {
    KEY: 'productQty',
    LABEL: 'Product quantity',
  },
  PRODUCT_UNIT_VALUE: {
    KEY: 'unitValue',
    LABEL: 'Product unit value',
  },
  PRODUCT_UNIT_WEIGHT: {
    KEY: 'unitWeight',
    LABEL: 'Product unit weight',
  },
  PRODUCT_COUNTRY_OF_ORIGIN: {
    KEY: 'countryOfOrigin',
    LABEL: 'Country of origin',
  },
  PRODUCT_COMMODITY_CODE: {
    KEY: 'commodityCode',
    LABEL: 'Commodity code',
  },
  PRODUCT_COMMODITY_DESCRIPTION: {
    KEY: '_commodityDescription',
  },
};

export const ADDRESS_BOOK_FIELDS = {
  ADDRESS_KEY: {
    KEY: 'address.addressId',
  },
  ADDRESS_COORDINATES: {
    KEY: 'address.coordinates',
  },
  COUNTRY: {
    KEY: 'address.countryCode',
    LABEL: COUNTRY,
  },
  ADDRESS: {
    KEY: 'address.street',
    LABEL: ADDRESS_LINE_1,
  },
  LOCALITY: {
    KEY: 'address.locality',
    LABEL: ADDRESS_LINE_2,
  },
  CITY: {
    KEY: 'address.town',
    LABEL: CITY,
  },
  COUNTY: {
    KEY: 'address.county',
    LABEL: COUNTY,
  },
  POSTCODE: {
    KEY: 'address.postcode',
    LABEL: 'Postcode',
  },
  CONTACT_NAME: {
    KEY: 'contactName',
    LABEL: CONTACT_NAME,
  },
  NICKNAME: {
    KEY: 'nickname',
    LABEL: NICKNAME,
  },
  ORGANISATION: {
    KEY: 'address.organisation',
    LABEL: COMPANY_NAME,
  },
  EMAIL: {
    KEY: 'email',
    LABEL: EMAIL,
  },
  PHONE_NUMBER: {
    KEY: 'phoneNumber',
    LABEL: PHONE_NUMBER,
  },
  VAT_NUMBER: {
    KEY: 'vatNumber',
    LABEL: VAT_NUMBER,
  },
  EORI_NUMBER: {
    KEY: 'eoriNumber',
    LABEL: EORI_NUMBER,
  },
  PID_NUMBER: {
    KEY: 'pidNumber',
    LABEL: PID_NUMBER,
  },
  UKIMS_NUMBER: {
    KEY: 'ukimsNumber',
    LABEL: UKIMS_NUMBER,
  },
  IS_DEFAULT: {
    KEY: 'isDefault',
    LABEL: 'This is my default address',
  },
  IS_BILLING: {
    KEY: 'isBilling',
    LABEL: 'This is my billing address',
  },
};

export const ACCOUNT_DETAILS_FIELDS = {
  COMMUNICATION_PREFERENCES: {
    SMS: {
      NAME: 'notifyBySms',
      LABEL: 'SMS',
    },
    EMAIL: {
      NAME: 'notifyByEmail',
      LABEL: 'Email',
    },
  },
  ACCOUNT_DETAILS: {
    FIRST_NAME: {
      NAME: 'firstName',
      LABEL: 'First Name',
    },
    LAST_NAME: {
      NAME: 'lastName',
      LABEL: 'Last Name',
    },
    EMAIL_ADDRESS: {
      NAME: 'email',
      LABEL: 'Email Address',
    },
    PHONE_NUMBER: {
      NAME: 'phone',
      LABEL: 'Phone Number',
    },
  },
};

export const HELP_KEYS = {
  HELP_TYPE: 'helpType',
  CONTACT_US_TYPE: 'contactUsType',
  PARCEL_NUMBER: 'parcelNumber',
  POSTCODE_OR_ORDER_ID: 'postcodeOrOrderId',
  PARCEL_CONTENTS: 'parcelContents',
  GOODS_VALUE: 'goodsValue',
  PACKAGE_DESCRIPTION: 'packageDescription',
  ADDITIONAL_INFORMATION: 'additionalInformation',
  ATTACHED_IMAGES: 'attachedImages',
  DAMAGED_ITEM: 'damagedItem',
  DAMAGE_DESCRIPTION: 'damageDescription',
  MISSING_DESCRIPTION: 'missingDescription',
  CONTACT_DETAILS: 'contactDetails',
};

export const ISSUE_TYPES = {
  DAMAGED: 'Damaged',
  NOT_ARRIVED: 'Not Arrived',
  MISSING_CONTENTS: 'Missing Contents',
};

export const HELP_FIELDS = {
  HELP_TYPE: { KEY: HELP_KEYS.HELP_TYPE },
  CONTACT_US_TYPE: { KEY: HELP_KEYS.CONTACT_US_TYPE },
  PARCEL_NUMBER: {
    KEY: HELP_KEYS.PARCEL_NUMBER,
    LABEL: HELP_LABELS.PARCEL_REFERENCE_NUMBER,
  },
  POSTCODE_OR_ORDER_ID: {
    KEY: HELP_KEYS.POSTCODE_OR_ORDER_ID,
    LABEL: HELP_LABELS.POSTCODE_OR_ORDER_ID,
  },
  ISSUE_TYPE: { KEY: 'reasonForContact' },
  CONTACT_NAME: {
    KEY: `${HELP_KEYS.CONTACT_DETAILS}.name`,
    LABEL: HELP_LABELS.CONTACT_NAME,
  },
  EMAIL: {
    KEY: `${HELP_KEYS.CONTACT_DETAILS}.email`,
    LABEL: HELP_LABELS.EMAIL_ADDRESS,
  },
  PHONE: {
    KEY: `${HELP_KEYS.CONTACT_DETAILS}.phone`,
    LABEL: HELP_LABELS.PHONE_NUMBER,
  },
  [ISSUE_TYPES.NOT_ARRIVED]: {
    PARCEL_CONTENTS: {
      KEY: HELP_KEYS.PARCEL_CONTENTS,
      LABEL: HELP_LABELS.PARCEL_CONTENTS,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    PARCEL_VALUE: {
      KEY: HELP_KEYS.GOODS_VALUE,
      LABEL: HELP_LABELS.PARCEL_VALUE,
      REQUIRED: true,
      MAX_LENGTH: 8,
    },
    PACKAGE_DESCRIPTION: {
      KEY: HELP_KEYS.PACKAGE_DESCRIPTION,
      LABEL: HELP_LABELS.PACKAGE_DESCRIPTION,
      REQUIRED: true,
      MAX_LENGTH: 1000,
    },
    ADDITIONAL_INFORMATION: {
      KEY: HELP_KEYS.ADDITIONAL_INFORMATION,
      LABEL: HELP_LABELS.ADDITIONAL_INFORMATION,
      REQUIRED: true,
      MAX_LENGTH: 1000,
    },
  },
  [ISSUE_TYPES.DAMAGED]: {
    PARCEL_CONTENTS: {
      KEY: HELP_KEYS.PARCEL_CONTENTS,
      LABEL: HELP_LABELS.PARCEL_CONTENTS,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    DAMAGED_ITEM: {
      KEY: HELP_KEYS.DAMAGED_ITEM,
      LABEL: HELP_LABELS.WHAT_DAMAGED,
      PLACEHOLDER: HELP_LABELS.DETAILED_DESCRIPTION,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    DAMAGE_DESCRIPTION: {
      KEY: HELP_KEYS.DAMAGE_DESCRIPTION,
      LABEL: HELP_LABELS.DAMAGE_DETAIL,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    PARCEL_VALUE: {
      KEY: HELP_KEYS.GOODS_VALUE,
      LABEL: HELP_LABELS.DAMAGED_ITEM_VALUE,
      REQUIRED: true,
      MAX_LENGTH: 8,
    },
    PACKAGE_DESCRIPTION: {
      KEY: HELP_KEYS.PACKAGE_DESCRIPTION,
      LABEL: HELP_LABELS.PACKAGE_DESCRIPTION,
      MAX_LENGTH: 1000,
    },
    ADDITIONAL_INFORMATION: {
      KEY: HELP_KEYS.ADDITIONAL_INFORMATION,
      LABEL: HELP_LABELS.ADDITIONAL_INFORMATION,
      MAX_LENGTH: 1000,
    },
  },
  [ISSUE_TYPES.MISSING_CONTENTS]: {
    PARCEL_CONTENTS: {
      KEY: HELP_KEYS.PARCEL_CONTENTS,
      LABEL: HELP_LABELS.PARCEL_CONTENTS,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    MISSING_DESCRIPTION: {
      KEY: HELP_KEYS.MISSING_DESCRIPTION,
      LABEL: HELP_LABELS.WHAT_MISSING,
      PLACEHOLDER: HELP_LABELS.DETAILED_DESCRIPTION,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    PACKAGE_DESCRIPTION: {
      KEY: HELP_KEYS.PACKAGE_DESCRIPTION,
      LABEL: HELP_LABELS.PACKAGE_DESCRIPTION,
      MAX_LENGTH: 1000,
    },
    DAMAGE_DESCRIPTION: {
      KEY: HELP_KEYS.DAMAGE_DESCRIPTION,
      LABEL: HELP_LABELS.PACKAGE_DAMAGE,
      REQUIRED: true,
      MAX_LENGTH: 255,
    },
    PARCEL_VALUE: {
      KEY: HELP_KEYS.GOODS_VALUE,
      LABEL: HELP_LABELS.MISSING_ITEM_VALUE,
      MAX_LENGTH: 8,
    },
    ADDITIONAL_INFORMATION: {
      KEY: HELP_KEYS.ADDITIONAL_INFORMATION,
      LABEL: HELP_LABELS.ADDITIONAL_INFORMATION,
      MAX_LENGTH: 1000,
    },
  },
};

export const NETWORK_FIELDS = {
  EXPORT_IMPORT_REQUIRED: 'exportImportRequired',
  EORI_REQUIRED: 'eoriRequired',
  VAT_NUMBER: 'vatRequired',
  PID_NUMBER: 'pidRequired',
  UKIMS_NUMBER: 'ukimsRequired',
  EXPORT_REASON_REQUIRED: 'exportReasonRequired',
  DT1_AVAILABLE: 'dt1Available',
  DDP_AVAILABLE: 'ddpAvailable',
  FDA_REQUIRED: 'fdaRequired',
  COST_REQUIRED: 'costsRequired',
  PROFORMA: 'proforma',
  CONTENTS_REQUIRED: 'contentsRequired',
  COUNTRY_OF_ORIGIN: 'countryOfOrigin',
  PROD_REQUIRED: 'prodRequired',
  TAX_REQUIRED: 'taxRequired',
  COMMODITY_REQUIRED: 'commodityRequired',
};

export const PRODUCTS_PATH = `${SHIPMENT_FIELDS.PARCELS.KEY}[0].products`;
