import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HttpStatusCode } from 'axios';
import loginImg3 from '../../../assets/images/loginImg3.png';
import {
  BACK_TO_HOME,
  ERROR_MESSAGES_MAP,
  TRY_AGAIN,
} from '../../../constants/strings';
import { getAuthRetryAfter } from '../../../features/Auth/selectors';
import {
  AUTH_TOO_MANY_REQUESTS,
  REACH_MAX_LOGIN_ATTEMPTS_CODE,
} from '../../../constants/error';
import { FormatterHelpers } from '../../../helpers';
import { VerifyEmailSlice } from './slice';

const Error = ({ values }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGES_MAP.default);
  const retryAfter = useSelector(getAuthRetryAfter);

  useEffect(() => {
    if (!values.error) {
      dispatch(VerifyEmailSlice.actions.clearAll());
      navigate('/', { replace: true });
    }
  }, []);

  const { error = {} } = values;

  useEffect(() => {
    const mappedError = ERROR_MESSAGES_MAP[error.code];

    // Retry after case handling
    if (
      mappedError &&
      [REACH_MAX_LOGIN_ATTEMPTS_CODE, AUTH_TOO_MANY_REQUESTS].includes(
        error.code
      )
    ) {
      const minutes = retryAfter
        ? FormatterHelpers.formatTime(retryAfter)
        : null;
      const title = mappedError.title;
      const description = mappedError.description(minutes);
      setErrorMessage({ title, description });
    } else if (mappedError) {
      setErrorMessage(mappedError);
    } else {
      setErrorMessage(({ title }) => ({
        title,
        description: `${error.code}: ${error.message}`,
      }));
    }
  }, [error.code]);

  const handleClick = useCallback(() => {
    if (error.code === HttpStatusCode.TooManyRequests) {
      return window.location.reload();
    } else {
      dispatch(VerifyEmailSlice.actions.clearAll());
      navigate('/', { replace: true });
    }
  }, [dispatch, navigate, error.code]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 3, md: 2 },
        display: 'flex',
        justifyContent: { xs: 'center', md: 'start' },
      }}
    >
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <img src={loginImg3} style={{ width: '100%' }} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          ml: { sx: 0, md: '78px' },
          justifyContent: 'center',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant='h2' sx={{ mt: 2 }}>
          {errorMessage.title}
        </Typography>

        <Typography sx={{ mt: 2 }}>{errorMessage.description}</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-end',
            gap: 2,
            mt: { xs: 5, md: 0 },
            alignItems: { xs: 'center', md: 'flex-end' },
          }}
        >
          <Button
            sx={{
              mt: { md: 4 },
              alignSelf: { md: 'flex-end' },
            }}
            onClick={handleClick}
            variant='contained'
          >
            {error.code === HttpStatusCode.TooManyRequests
              ? TRY_AGAIN
              : BACK_TO_HOME}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Error.propTypes = {
  values: PropTypes.shape({
    error: PropTypes.object,
  }),
};

export default Error;
