import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import { styled } from '@mui/system';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import { PICKUP_POINT_AMENITIES } from '../../../constants/pickupPoint';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.gray,
}));

const StyledAccessibleForwardIcon = styled(AccessibleForwardIcon)(
  ({ theme }) => ({
    color: theme.palette.primary.gray,
  })
);

const StyledBedtimeIcon = styled(BedtimeOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.gray,
}));

const StyledQrCodeScannerIconIcon = styled(QrCodeScannerIcon)(({ theme }) => ({
  color: theme.palette.primary.gray,
}));

const StyledDirectionsCarIcon = styled(DirectionsCarIcon)(({ theme }) => ({
  color: theme.palette.primary.gray,
}));

const StyledSaturdayIcon = styled(EventAvailableIcon)(({ theme }) => ({
  color: theme.palette.primary.gray,
}));

const StyledSundayIcon = styled(EventAvailableIcon)(({ theme }) => ({
  color: theme.palette.primary.gray,
}));

const PickupAmenities = ({
  disabledAccess,
  parkingAvailable,
  openLate,
  openSaturday,
  openSunday,
  dropOffDpd,
}) => {
  const items = [
    {
      icon: StyledAccessibleForwardIcon,
      showIcon: disabledAccess,
      description: disabledAccess
        ? PICKUP_POINT_AMENITIES.WHEELCHAIR_ACCESS
        : null,
    },
    {
      icon: StyledQrCodeScannerIconIcon,
      showIcon: dropOffDpd,
      description: dropOffDpd ? PICKUP_POINT_AMENITIES.LABEL_PRINTING : null,
    },
    {
      icon: StyledBedtimeIcon,
      showIcon: openLate,
      description: openLate ? PICKUP_POINT_AMENITIES.OPEN_LATE : null,
    },
    {
      icon: StyledDirectionsCarIcon,
      showIcon: parkingAvailable,
      description: parkingAvailable ? PICKUP_POINT_AMENITIES.CAR_PARKING : null,
    },
    {
      icon: StyledSaturdayIcon,
      showIcon: openSaturday,
      description: openSaturday ? PICKUP_POINT_AMENITIES.OPEN_SATURDAYS : null,
    },
    {
      icon: StyledSundayIcon,
      showIcon: openSunday,
      description: openSunday ? PICKUP_POINT_AMENITIES.OPEN_SUNDAYS : null,
    },
  ];

  return (
    <Grid id='windowAmenites' container spacing={1} flexDirection='column'>
      {items
        .filter(({ showIcon }) => showIcon)
        .map(({ icon: Icon, description }, index) => (
          <Grid item key={index} xs='auto'>
            <Box
              id={`windowAmenites-${index}`}
              display='flex'
              alignItems='center'
            >
              <Icon />
              {description && (
                <StyledTypography variant='body2'>
                  {description}
                </StyledTypography>
              )}
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

PickupAmenities.propTypes = {
  disabledAccess: PropTypes.bool,
  parkingAvailable: PropTypes.bool,
  openLate: PropTypes.bool,
  openSaturday: PropTypes.bool,
  openSunday: PropTypes.bool,
};

export default PickupAmenities;
