import { useLocation, useNavigate, useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { InfoOutlined, NavigateBefore } from '@mui/icons-material';
import { HttpStatusCode } from 'axios';
import Loader from '../../features/Loader';
import { shipmentsApis } from '../../apis';
import Error from '../Auth/SignIn/Error';
import { ANALYTICS, FORM, ROUTE_PATH, STRINGS } from '../../constants';
import PickupModal from '../../components/PickupModal';
import { getBarcode } from '../../apis/barcodes';
import ShareModal from '../../components/ShareModal/ShareModal';
import useErrorHandler from '../../hooks/useErrorHandler';
import { InvoiceHelpers, LabelsHelpers } from '../../helpers';
import { getOrderInvoiceDownloadUrl } from '../UserPreferences/OrdersSection/utils/downloadUrl';
import { DateUtil, ObjectUtil } from '../../utils';
import Collection from './Collection';
import DropOff from './DropOff';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const ActionMenuBtn = ({ actionMenuBtnHandleClick }) => (
  <IconButton
    sx={{ paddingY: 0 }}
    disableRipple
    aria-label='more'
    onClick={actionMenuBtnHandleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

const getParcelCodeFromParcelNumber = shipment =>
  `${
    get(shipment, `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0]`)?.parcelNumber
  }*${DateUtil.convertToInternalDate(shipment?.createdDate)}`;

const Shipment = () => {
  const { shipmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showBackButton, setShowBackButton] = useState(false);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [shipment, setShipment] = useState(null);
  const theme = useTheme();
  const { errorHandler } = useErrorHandler();

  useEffect(() => {
    if (location.state?.fromOrderDetails) {
      setShowBackButton(true);
    }
  }, [location]);

  const handleBack = () => {
    navigate(-1);
  };

  const handlePickupPointChange = point => {
    setSelectedPickupPoint(point);
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const actionEditBtnHandleClick = event => {
    setActionMenuAnchorEl(event.currentTarget);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getTitleText = dropAtShop => {
    if (!dropAtShop) {
      return STRINGS.CONFIRMATION.HOME_COLLECTION_INSTRUCTIONS;
    }
    return selectedTab === 0
      ? STRINGS.CONFIRMATION.PARCEL_PASS
      : STRINGS.CONFIRMATION.DROP_OFF_INSTRUCTION;
  };

  const dropAtShop = get(shipment, FORM.SHIPMENT_FIELDS.DROP_AT_SHOP.KEY);

  const loadData = useCallback(async () => {
    try {
      const shipmentData = await shipmentsApis.getShipmentById(shipmentId);

      if (get(shipmentData, FORM.SHIPMENT_FIELDS.DROP_AT_SHOP.KEY)) {
        const barcodeData = await getBarcode(shipmentId);
        setBarcode(barcodeData?.barcodes);
      }

      setShipment(shipmentData);
    } catch (error) {
      errorHandler(STRINGS.FETCHING_ERROR, error);
      if (error?.status === HttpStatusCode.Forbidden) {
        navigate(ROUTE_PATH.DONT_HAVE_ACCESS);
      }
    }
  }, [shipmentId]);

  const handleDownloadInvoice = format => {
    // Invoice contains all of the related parcels, to generate the invoice we need to provide one of these parcel numbers
    const parcelNumber = get(
      shipment,
      `${FORM.SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelNumber`
    );

    if (parcelNumber) {
      const url = getOrderInvoiceDownloadUrl(parcelNumber, format);
      window.open(url, '_blank');
      actionMenuBtnHandleClose();
    }
  };

  const isPrintInvoiceAvailable = useMemo(
    () =>
      !!ObjectUtil.getValue(
        shipment,
        FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY,
        ''
      ),
    [shipment]
  );

  return (
    <Trackable loadId={ANALYTICS.BASKET.LOAD}>
      <Loader
        promiseFn={loadData}
        errorComponent={error => <Error values={{ error }} />}
        triggerOnMount={true}
        triggerOnCondition={() => false}
      >
        <Grid
          sx={{
            background: theme.palette.primary.pageBackground,
            flexGrow: 1,
            pb: 4,
          }}
        >
          <Grid container alignItems='center' justifyContent='center'>
            <Grid
              alignItems='center'
              justifyContent='center'
              spacing={2}
              sx={{ mt: 0 }}
            >
              <Grid item xs={12} md={12}>
                <Grid
                  sx={{
                    m: 4,
                    marginX: 0,
                    paddingX: 2,
                    maxWidth: 400,
                  }}
                  item
                >
                  {showBackButton && (
                    <Button
                      onClick={handleBack}
                      sx={{ mb: 2 }}
                      variant='outlined'
                      startIcon={<NavigateBefore />}
                    >
                      {STRINGS.ORDER_SUMMARY}
                    </Button>
                  )}
                  <Typography variant='body1'>
                    {STRINGS.CONFIRMATION.THANK_YOU}
                  </Typography>
                  <Typography variant='h2' sx={{ mt: 1 }}>
                    {STRINGS.CONFIRMATION.ALL_DONE}
                  </Typography>
                </Grid>
                {isPrintInvoiceAvailable && (
                  <>
                    <Box
                      sx={{
                        background: theme.palette.primary.pink,
                        width: '400px',
                        p: 1,
                        border: `1px solid ${theme.palette.primary.light}`,
                        borderRadius: '5px',
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <InfoOutlined />
                        <Box>
                          <Typography variant='body2'>
                            {STRINGS.CONFIRMATION.INTERNATIONAL.INFO_TITLE}
                          </Typography>
                          <Typography variant='body2'>
                            {
                              STRINGS.CONFIRMATION.INTERNATIONAL
                                .INFO_DESCRIPTION
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Button
                      variant='contained'
                      sx={{ width: '400px', mt: 1, mb: 2 }}
                      href={InvoiceHelpers.getShipmentInvoiceDownloadUrl(
                        shipmentId
                      )}
                    >
                      {
                        STRINGS.CONFIRMATION.INTERNATIONAL
                          .CREATE_PROFORMA_INVOICE
                      }
                    </Button>
                  </>
                )}
                <Grid sx={{ mt: 1 }}>
                  <Paper sx={{ p: 0, maxWidth: '400px' }}>
                    <Box
                      sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant='h3'>
                        {getTitleText(dropAtShop)}
                      </Typography>
                      <ActionMenuBtn
                        actionMenuBtnHandleClick={actionEditBtnHandleClick}
                      />
                    </Box>
                    <Menu
                      anchorEl={actionMenuAnchorEl}
                      open={!!actionMenuAnchorEl}
                      onClose={actionMenuBtnHandleClose}
                      MenuListProps={{ sx: { width: '154px' } }}
                      anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 120,
                        vertical: 90,
                      }}
                    >
                      <StyledMenuItem
                        disableRipple
                        theme={theme}
                        onClick={() =>
                          handleDownloadInvoice(STRINGS.FILE_FORMAT.PDF)
                        }
                      >
                        {STRINGS.CONFIRMATION.DOWNLOAD_INVOICE}
                      </StyledMenuItem>
                      <StyledMenuItem
                        disableRipple
                        theme={theme}
                        onClick={() => {
                          handleOpenShareModal();
                          actionMenuBtnHandleClose();
                        }}
                      >
                        {STRINGS.CONFIRMATION.SHARE_PARCEL_INFO}
                      </StyledMenuItem>
                    </Menu>
                    {dropAtShop && (
                      <Box
                        sx={{
                          mt: 0,
                          mb: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Tabs
                          sx={{ width: '100%' }}
                          value={selectedTab}
                          onChange={handleTabChange}
                          TabIndicatorProps={{
                            sx: {
                              backgroundColor: theme.palette.primary.main,
                              height: '2px',
                            },
                          }}
                        >
                          <Tab
                            sx={{ width: '50%' }}
                            label={STRINGS.CONFIRMATION.VIEW_QR_CODE}
                          />
                          <Tab
                            sx={{ width: '50%' }}
                            label={STRINGS.CONFIRMATION.VIEW_LABEL}
                          />
                        </Tabs>
                      </Box>
                    )}
                    <Divider />
                    <Box>
                      {dropAtShop && selectedTab === 0 ? (
                        <DropOff
                          shipment={shipment}
                          shipmentId={shipmentId}
                          barcode={barcode}
                        />
                      ) : (
                        <Collection
                          shipment={shipment}
                          dropAtShop={dropAtShop}
                        />
                      )}
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 1,
                      }}
                    >
                      {(!dropAtShop || selectedTab === 1) && (
                        <Button
                          target='_blank'
                          href={LabelsHelpers.getShipmentLabelsDownloadUrl(
                            shipmentId
                          )}
                          sx={{ mx: 1, mb: 0, mt: 1 }}
                          variant='contained'
                        >
                          {STRINGS.CONFIRMATION.DOWNLOAD_LABEL_TO_PRINT}
                        </Button>
                      )}
                      {dropAtShop && (
                        <PickupModal
                          label={
                            STRINGS.CONFIRMATION.SHOW_NEAREST_PICKUP_POINTS
                          }
                          id='pickup-modal'
                          variant={selectedTab === 0 ? 'contained' : 'outlined'}
                          disabled={false}
                          style={{ mt: 0 }}
                          selectedPickupPoint={selectedPickupPoint}
                          onChange={handlePickupPointChange}
                          showSelectButton={false}
                        />
                      )}
                      <Button
                        href='/quotes'
                        sx={{
                          mx: 1,
                          mb: 2,
                          mt: !dropAtShop ? 1 : 0,
                          color: theme.palette.primary.main,
                        }}
                        variant={selectedTab === 1 ? 'none' : 'outlined'}
                      >
                        {STRINGS.CONFIRMATION.SEND_ANOTHER_PARCEL}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                <ShareModal
                  parcel={{
                    parcelCode: getParcelCodeFromParcelNumber(shipment),
                  }}
                  open={openShareModal}
                  onClose={() => setOpenShareModal(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Loader>
    </Trackable>
  );
};

export default Shipment;
