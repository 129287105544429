import {
  formattedDayOfTheWeekDayMonthShipmentDate,
  getNextSaturday,
  getNextSunday,
  getNextWorkingDay,
} from '../utils/dateAndTime';

export const REQUIRED_TYPE = {
  MANDATORY: 'M',
  OPTIONAL: 'Y',
  NEEDLESS: 'N',
  DESCRIPTION: 'D',
};

export const NETWORK_KEYS = {
  NETWORK_KEY_10_30: '2^08', // 10:30
  NETWORK_KEY_12: '2^13', // 12:00
  NETWORK_KEY_NEXT_DAY: '2^12', // Next Day
  NETWORK_KEY_SHIP_TO_SHOP: '2^91', // Ship to Shop
  NETWORK_KEY_TWO_DAY: '2^11', // Two Day
  NETWORK_KEY_SAT: '2^71', // Saturday
  NETWORK_KEY_SAT_10_30: '2^09', // Sat By 10:30
  NETWORK_KEY_SAT_12: '2^17', // Sat by 12
  NETWORK_KEY_SUN: '2^75', // Sunday service
  NETWORK_KEY_SUN_12: '2^15', // Sun by 12
};

export const WEEKEND_NETWORKS = [
  NETWORK_KEYS.NETWORK_KEY_SAT,
  NETWORK_KEYS.NETWORK_KEY_SAT_10_30,
  NETWORK_KEYS.NETWORK_KEY_SAT_12,
  NETWORK_KEYS.NETWORK_KEY_SUN,
  NETWORK_KEYS.NETWORK_KEY_SUN_12,
];

export const PARCEL_ARRIVAL_DESCRIPTION = 'We’ll deliver ';
export const DELIVERY_SPEEDY_CONTENT =
  'DPD Pickups usually deliver in 1 working day. Occasionally, due to collection schedules at your selected location, it might take 2-3 working days depending on the day and time you drop-off your parcel.';
export const PARCEL_TWO_DAYS_DESCRIPTION = `It might take 2-3 working days depending on the day and time you drop-off your parcel.`;

export const PARCEL_ARRIVAL_TIMES = {
  BEFORE_10_30: 'Before 10:30',
  BEFORE_12: 'Before 12:00',
  NEXT_DAY: 'Parcel Next day',
  SHIP_TO_SHOP: 'Speedy Delivery with a Heads Up:',
  TWO_DAY: 'Two day Parcel',
  SAT: 'Parcel Saturday',
  SAT_10_30: 'Saturday Before 10:30',
  SAT_12: 'Saturday Before 12:00',
  SUN: 'Parcel Sunday',
};

export const NETWORK_DETAILS = {
  [NETWORK_KEYS.NETWORK_KEY_10_30]: {
    title: PARCEL_ARRIVAL_TIMES.BEFORE_10_30,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextWorkingDay(shippingDate)
      )} before 10:30`,
  },
  [NETWORK_KEYS.NETWORK_KEY_12]: {
    title: PARCEL_ARRIVAL_TIMES.BEFORE_12,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextWorkingDay(shippingDate)
      )} before 12:00`,
  },
  [NETWORK_KEYS.NETWORK_KEY_NEXT_DAY]: {
    title: PARCEL_ARRIVAL_TIMES.NEXT_DAY,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextWorkingDay(shippingDate)
      )}`,
  },
  [NETWORK_KEYS.NETWORK_KEY_SHIP_TO_SHOP]: {
    title: PARCEL_ARRIVAL_TIMES.SHIP_TO_SHOP,
    desc: () => DELIVERY_SPEEDY_CONTENT,
  },
  [NETWORK_KEYS.NETWORK_KEY_TWO_DAY]: {
    title: PARCEL_ARRIVAL_TIMES.TWO_DAY,
    desc: () => PARCEL_TWO_DAYS_DESCRIPTION,
  },
  [NETWORK_KEYS.NETWORK_KEY_SAT]: {
    title: PARCEL_ARRIVAL_TIMES.SAT,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextSaturday(shippingDate)
      )}`,
  },
  [NETWORK_KEYS.NETWORK_KEY_SAT_10_30]: {
    title: PARCEL_ARRIVAL_TIMES.SAT_10_30,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextSaturday(shippingDate)
      )} before 10:30`,
  },
  [NETWORK_KEYS.NETWORK_KEY_SAT_12]: {
    title: PARCEL_ARRIVAL_TIMES.SAT_12,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextSaturday(shippingDate)
      )} before 12:00`,
  },
  [NETWORK_KEYS.NETWORK_KEY_SUN]: {
    title: PARCEL_ARRIVAL_TIMES.SUN,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        getNextSunday(shippingDate)
      )}`,
  },
};

export const DEFAULT_FIRST_IN_AREA = '9:00';
export const DEFAULT_LAST_IN_AREA = '18:00';
