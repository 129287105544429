import { FORM } from '../../constants';

export const HELP_TYPES = {
  CHAT: 'chat',
  ISSUE: 'issue',
};

export const CONTACT_US_TYPE = {
  CHAT: 'Chat',
};

export const MULTILINE_FIELDS = [
  FORM.HELP_KEYS.PACKAGE_DESCRIPTION,
  FORM.HELP_KEYS.ADDITIONAL_INFORMATION,
  FORM.HELP_KEYS.DAMAGE_DESCRIPTION,
  FORM.HELP_KEYS.MISSING_DESCRIPTION,
  FORM.HELP_KEYS.DAMAGED_ITEM,
];

export const FIELDS_TO_RESET = [
  FORM.HELP_KEYS.PARCEL_CONTENTS,
  FORM.HELP_KEYS.GOODS_VALUE,
  FORM.HELP_KEYS.PACKAGE_DESCRIPTION,
  FORM.HELP_KEYS.ADDITIONAL_INFORMATION,
  FORM.HELP_KEYS.DAMAGED_ITEM,
  FORM.HELP_KEYS.DAMAGE_DESCRIPTION,
  FORM.HELP_KEYS.MISSING_DESCRIPTION,
  FORM.HELP_KEYS.ATTACHED_IMAGES,
];

export const FIELDS_TO_RESET_ON_PARCEL_CHANGE = [
  ...FIELDS_TO_RESET,
  FORM.HELP_FIELDS.ISSUE_TYPE.KEY,
  `${FORM.HELP_KEYS.CONTACT_DETAILS}.name`,
  `${FORM.HELP_KEYS.CONTACT_DETAILS}.email`,
  `${FORM.HELP_KEYS.CONTACT_DETAILS}.phone`,
  FORM.HELP_KEYS.ATTACHED_IMAGES,
];

export const DAYS_LIMIT_FOR_CLAIM_PROCESSING = 28;

export const ACCEPT_IMAGE_MIME_TYPE = {
  'image/svg+xml': [],
  'image/png': [],
  'image/jpeg': [],
  'image/gif': [],
};

export const MAX_IMAGES_UPLOAD_LIMIT = 4;

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5Mb

export const COMPRESSION_OPTIONS = {
  maxSizeMB: 2,
  useWebWorker: true,
};
