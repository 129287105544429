import { STRINGS } from '../constants';
import { StringUtil } from '../utils';

const PARCEL_NUMBER_LENGTH = 14;

export const normalizeParcelNumber = parcelNumber =>
  StringUtil.trimAllSpaces(parcelNumber).substring(0, PARCEL_NUMBER_LENGTH);

export const getParcelsQuantity = (parcels = []) =>
  parcels.reduce((accumulator, { _quantity }) => accumulator + _quantity, 0);

export const isGuestAccessLevel = accessLevel =>
  accessLevel === STRINGS.PARCEL_ACCESS_LEVEL.GUEST;
