import { Grid } from '@mui/material';
import { find, max } from 'lodash';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import ActionButton from '../../../../../components/ActionButton';
import { getDisplayedExtraPrice } from '../../../helpers/shipment';
import { getFieldsToUpdate } from '../helpers';
import { Plane, Road } from '../../../../../components/icons';
import { FormHelpers, NetworkHelpers } from '../../../../../helpers';
import { SHIPMENT } from '../../../../../constants';

export const ServiceButtons = ({
  networks,
  price,
  shipmentDate,
  fieldName,
  form,
  collectFrom,
  formValues,
  disabled,
}) => (
  <Field name={fieldName}>
    {({ input }) => (
      <Grid container spacing={2} sx={{ my: 2, alignItems: 'stretch' }}>
        {networks.map(network => {
          const { extraPrice } =
            find(price.arrivalServicesExtraPrices, [
              'networkKey',
              network.networkKey,
            ]) || {};

          return (
            <Grid
              container
              item
              md={max([12 / networks.length, 4])}
              xs={12}
              key={network.networkKey}
            >
              <ActionButton
                selected={input.value === network.networkKey}
                title={
                  SHIPMENT.NETWORK_DETAILS[network.networkKey]?.title ||
                  network.networkDesc
                }
                bodyText={SHIPMENT.NETWORK_DETAILS[network.networkKey]?.desc(
                  shipmentDate,
                  collectFrom
                )}
                price={
                  network.networkKey !==
                    SHIPMENT.NETWORK_KEYS.NETWORK_KEY_SHIP_TO_SHOP &&
                  getDisplayedExtraPrice(extraPrice)
                }
                cardOnclick={() => {
                  input.onChange(network.networkKey);
                  FormHelpers.bulkUpdate(
                    form,
                    getFieldsToUpdate({
                      network,
                      values: formValues,
                    })
                  );
                }}
                AdditionalIconComponent={() =>
                  NetworkHelpers.removeBusinessUnit(
                    network.service?.serviceKey
                  ) === '0' ? (
                    <Plane />
                  ) : (
                    <Road />
                  )
                }
                disabled={disabled}
              />
            </Grid>
          );
        })}
      </Grid>
    )}
  </Field>
);

PropTypes.ServiceButtons = {
  networks: PropTypes.array.isRequired,
  price: PropTypes.object.isRequired,
  shipmentDate: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};
