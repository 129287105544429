/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Field } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { cloneDeep, filter, find, findIndex, get } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDeepCompareEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import Debugger from '../../../../components/Debugger/Debugger';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import ActionButton from '../../../../components/ActionButton';
import MaxParcelDimsModal from '../../../../components/MaxParcelDimsModal/MaxParcelDimsModal';
import PickupModal from '../../../../components/PickupModal';
import AddressDetailsForm from '../../../../components/AddressDetailsForm/AddressDetailsForm';
import PickupPointWidget from '../../../../components/PickupPointwidget/PickupPointWidget';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import {
  ANALYTICS,
  DATE_FORMAT,
  ERRORS,
  FORM,
  POINT_TYPE,
  STRINGS,
} from '../../../../constants';
import { useOverlay } from '../../../../features/Overlay';
import { PickupHelper, ShipmentHelper } from '../../helpers';
import { useOrderSnackbar, useReferencesLoader } from '../../hooks';
import {
  DEFAULT_MAX_PARCEL_WEIGHT,
  INSURANCE_SECTION,
  REFERENCE_NAME,
} from '../../constants';
import AddressBookModal from '../../../../components/AddressBookModal';
import * as OrderSelectors from '../../selectors';
import configSlice from '../../../../redux/configSlice';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import Loader from '../../../../features/Loader';
import * as AppActions from '../../../../redux/appActions';
import {
  getDisplayedExtraPrice,
  getDisplayedPrice,
} from '../../helpers/shipment';
import {
  maxTotalParcelsValue,
  minParcelValue,
  parcelSchema,
} from '../../validators/parcel';
import { ArrayUtil, ObjectUtil, StringUtil } from '../../../../utils';
import OrderForm from '../../components/OrderForm';
import {
  AddressHelpers,
  FormHelpers,
  NetworkHelpers,
  NormalizerHelpers,
  ShipmentHelpers,
  Validators,
} from '../../../../helpers';
import Summary from '../../components/Summary';
import { FormInput } from '../../../../components/FormInput';
import useDebounceCallback from '../../../../hooks/useDebounceCallback';
import useAnalytics from '../../../../hooks/useAnalytics';
import * as StepSelectors from './selectors';
import { SECTION, SECTION_FIELDS_MAP } from './constants';
import FormSection from './components/FormSection';
import { ServiceButtons } from './components/ServiceButtons';

const ParcelDetails = ({ nextStep, values, references, setReferences }) => {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const overlay = useOverlay();
  const [searchParams, setSearchParams] = useSearchParams();
  const snackbar = useOrderSnackbar();
  const referencesLoader = useReferencesLoader();
  const [recommended, setRecommended] = useState({});

  const quantitySymbolsLengthLimit = 2;
  const valueSymbolsLengthLimit = 4;
  const weightFieldConfig = {
    maxIntegerLength: 2,
    maxDecimalLength: 2,
    minValue: 0.1,
  };
  const totalQuantityLimit = 99;

  const { networks } = references;
  const filteredNetworks = useSelector(StepSelectors.getFilteredNetworks);
  const selectedNetwork = useSelector(OrderSelectors.getSelectedNetwork);
  const parcelsInvalid = useSelector(StepSelectors.validateParcelsValues);
  const shipToShopAvailable = useSelector(StepSelectors.getShipToShopAvailable);
  const networksQuery = useSelector(StepSelectors.getOutboundNetworkParameters);
  const defaultCutOff = useSelector(
    configSlice.selectors.getCollectionCutOffConfig
  );
  const context = useSelector(OrderSelectors.getValidationContext);
  const requiredKeys = useSelector(
    StepSelectors.getParcelDetailsRequiredFields
  );
  const requiredKeysMap = useSelector(
    StepSelectors.getParcelDetailsRequiredFieldsMap
  );
  const price = useSelector(OrderSelectors.getTotalAmount);

  const insuranceConfig = useSelector(configSlice.selectors.getInsuranceConfig);
  const freeInsurance = insuranceConfig[0];
  const recommendedInsurance = ArrayUtil.findInArray({
    array: insuranceConfig,
    key: 'insuranceValue',
    value: Number(Object.keys(recommended)[0]),
  });

  const countries = useSelector(countriesSelectors.getCountries);

  const dispatch = useDispatch();
  const { errorHandler } = useErrorHandler();
  const [maxDimsModalOpen, setMaxDimsModalOpen] = useState(false);
  const basketId = useSelector(BasketSelectors.getBasketId);

  useEffect(() => {
    setInterfaceId(ANALYTICS.PARCEL_DETAILS.INTERFACE_ID);
    overlay.show();

    const loadReferences = async () => {
      try {
        await Promise.all([
          referencesLoader.loadDropOffCollection(),
          referencesLoader.loadDeliveryPickup(),
        ]);
      } catch (error) {
        errorHandler(STRINGS.PARCEL_FETCHING_ERROR, error);
      } finally {
        overlay.hide();
      }
    };

    loadReferences();
  }, []);

  const onSubmit = useCallback(async formValues => {
    try {
      overlay.show();

      let basketItemId = searchParams.get('basketItemId');
      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: {
          ...formValues,
          outboundConsignment: {
            ...formValues.outboundConsignment,
            shippingRef2: basketId,
          },
        },
      };

      if (basketItemId) {
        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();
      } else {
        const { basketItemId: itemId } = await dispatch(
          BasketActions.createBasketItem(formValues)
        ).unwrap();
        basketItemId = itemId;
      }

      setSearchParams({ ...searchParams, basketItemId });

      nextStep();
    } catch (e) {
      snackbar.showSubmitError({
        originError: e,
        message:
          e?.errorCode === 'basketLimitExceeded'
            ? e.message
            : STRINGS.FAILED_TO_SAVE_BASKET,
        persist: e?.errorCode === 'basketLimitExceeded',
      });
    } finally {
      overlay.hide();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [navigate]);

  // PRICING LOGIC
  const collectionCountryCode = get(
    values,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
  );
  const deliveryCountryCode = get(
    values,
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
  );

  const deliveryCountryValue = useMemo(
    () =>
      AddressHelpers.getCountryValue({
        countries,
        countryCode: deliveryCountryCode,
      }),
    [countries, deliveryCountryCode]
  );
  const collectionCountryValue = useMemo(
    () =>
      AddressHelpers.getCountryValue({
        countries,
        countryCode: collectionCountryCode,
      }),
    [countries, collectionCountryCode]
  );

  const debounceTime = 300;
  const debouncedFetchNetworks = useDebounceCallback(
    query => referencesLoader.loadNetworks(query),
    debounceTime
  );

  const networksSchema = useMemo(() => Validators.getNetworksSchema(), []);

  useDeepCompareEffect(() => {
    const { error } = networksSchema.validate(networksQuery, {
      context: {
        deliveryCountryValue,
        collectionCountryValue,
      },
    });

    if (!error) {
      debouncedFetchNetworks(networksQuery);
    }
  }, [networksQuery]);

  const collectFrom = get(values, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const deliverTo = get(values, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY);
  const isTotalQuantityOverLimit =
    get(values, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY) >
    totalQuantityLimit;
  const parcels = ObjectUtil.getValue(
    values,
    FORM.SHIPMENT_FIELDS.PARCELS.KEY,
    []
  );
  const totalParcelsValue = useMemo(
    () => ShipmentHelpers.getTotalParcelsValue(parcels),
    [parcels]
  );
  const isTotalValueOverLimit = totalParcelsValue > maxTotalParcelsValue;
  const activeCollectionPickupPoint =
    references[REFERENCE_NAME.COLLECTION_PICKUP];
  const showActiveCollectionPickupPoint =
    collectFrom === POINT_TYPE.SHOP && activeCollectionPickupPoint;
  const activeDestinationPickupPoint =
    references[REFERENCE_NAME.DELIVERY_PICKUP];
  const showActiveDestinationPickupPoint =
    deliverTo === POINT_TYPE.SHOP && activeDestinationPickupPoint;
  const collectionDateAvailable = collectFrom === POINT_TYPE.DOOR;

  const loadCollectionDates = useCallback(
    async ({ collectionCountryCode, collectionPostcode }) => {
      try {
        const data = await dispatch(
          AppActions.fetchCollectionDates({
            countryCode: collectionCountryCode,
            postcode: collectionPostcode,
          })
        ).unwrap();

        const dates = ShipmentHelper.filterCollectionDatesInPast(data).map(
          ShipmentHelper.formatShipmentDate
        );

        if (!dates?.length) {
          snackbar.showError({
            message: STRINGS.NO_COLLECTION_DATES_AVAILABLE,
          });
        }

        setReferences(REFERENCE_NAME.COLLECTION_DATES, dates);
        return dates;
      } catch (error) {
        snackbar.showError({
          message: STRINGS.NO_COLLECTION_DATES_AVAILABLE,
        });
        setReferences(REFERENCE_NAME.COLLECTION_DATES, []);
        return [];
      }
    },
    []
  );

  const handleParcelValueChange = useCallback(() => {
    const recommendedCoverage = FormHelpers.getTotalCoverage(
      parcels,
      insuranceConfig
    );

    setRecommended({ [recommendedCoverage]: true });
  }, [insuranceConfig, parcels]);

  useDeepCompareEffect(() => {
    handleParcelValueChange();
  }, [values]);

  const handleTotalValueChange = (form, values) => {
    const insuranceValue = get(
      values,
      FORM.SHIPMENT_FIELDS.LIABILITY_VALUE.KEY
    );
    const recommendedCoverage = FormHelpers.getTotalCoverage(
      parcels,
      insuranceConfig
    );
    if (
      insuranceValue === freeInsurance?.insuranceValue ||
      insuranceValue === recommendedCoverage
    ) {
      return;
    }
    form.change(
      FORM.SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
      freeInsurance.insuranceValue
    );
  };

  const onSelectFromAddressBook = async (form, selectedAddress, field) => {
    try {
      const {
        addressBookId,
        countryCode,
        addressLine1,
        addressLine2,
        addressLine3: town,
        addressLine4: county,
        postcode,
        contactName,
        organisation,
        phoneNumber,
        email,
      } = selectedAddress;

      const addressBookFormValues = {
        address: {
          [FORM.FIELDS.ADDRESS_KEY.KEY]: null,
          countryCode,
          street: addressLine1,
          locality: addressLine2,
          town,
          county,
          postcode,
          organisation,
          _coordinates: null,
        },
        contactDetails: {
          _addressBookId: addressBookId,
          contactName,
          telephone: phoneNumber,
        },
      };

      if (FORM.DELIVERY_DETAILS_KEY === field) {
        addressBookFormValues.notificationDetails = {
          mobile: phoneNumber,
          email,
        };

        setReferences(REFERENCE_NAME.DELIVERY_ADDRESS_BOOK, selectedAddress);
      } else {
        addressBookFormValues.contactDetails.email = email;
        setReferences(REFERENCE_NAME.COLLECTION_ADDRESS_BOOK, selectedAddress);
      }

      form.batch(() => {
        const pairValues = ObjectUtil.flattenObjectToDotNotation(
          addressBookFormValues,
          field
        );
        pairValues.forEach(([field, value]) => {
          form.change(field, value);
          form.blur(field);
        });
      });
    } catch (err) {
      snackbar.showError({
        message: 'Can not retrieve address details',
      });
    } finally {
      overlay.hide();
    }
  };

  const showTotalNotificationError = field => {
    if (
      field === FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY &&
      isTotalQuantityOverLimit
    ) {
      return snackbar.showError({
        message: StringUtil.formatMessage(
          ERRORS.TOTAL_QUANTITY_ERROR_$,
          totalQuantityLimit
        ),
        actionButtonText: STRINGS.DISMISS,
      });
    }

    if (isTotalValueOverLimit) {
      return snackbar.showError({
        message: StringUtil.formatMessage(
          ERRORS.TOTAL_VALUE_ERROR_$,
          maxTotalParcelsValue
        ),
        actionButtonText: STRINGS.DISMISS,
      });
    }
  };

  const addParcel = useCallback(
    form => {
      const newParcels = cloneDeep(parcels);
      newParcels.push({
        _parcelId: uuidv4(),
      });
      form.change(FORM.SHIPMENT_FIELDS.PARCELS.KEY, newParcels);
      SECTION_FIELDS_MAP.parcel.push(
        ...[
          {
            name: FORM.SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(parcels.length),
          },
          {
            name: FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(parcels.length),
          },
        ]
      );
    },
    [parcels]
  );
  const deleteParcel = useCallback(
    (form, parcelId) => {
      const newParcels = cloneDeep(parcels);
      const parcelToDelete = newParcels.find(
        item => item._parcelId === parcelId
      );
      const deletedParcelIndex = newParcels.indexOf(parcelToDelete);
      newParcels.splice(deletedParcelIndex, 1);

      form.batch(() => {
        form.change(FORM.SHIPMENT_FIELDS.PARCELS.KEY, newParcels);
        form.change(
          FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
          NetworkHelpers.getParcelTotalQuantity(newParcels)
        );
        form.change(
          FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
          NetworkHelpers.getParcelsTotalWeight(values, newParcels)
        );
      });

      // Filter out from SECTION_FIELDS_MAP items that contain
      // deletedParcelIndex in the name
      SECTION_FIELDS_MAP.parcel = filter(
        SECTION_FIELDS_MAP.parcel,
        item => !item.name.includes(`parcels[${deletedParcelIndex}]`)
      );
    },
    [parcels]
  );

  return (
    <Trackable loadId={ANALYTICS.PARCEL_DETAILS.LOAD}>
      <OrderForm
        onSubmit={onSubmit}
        context={context}
        requiredKeys={requiredKeys}
        validateOnBlur={false}
        validateOnInit
      >
        {({ form, errors, invalid, submitting, values: formValues }) => {
          const shipmentDate = get(
            values,
            FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY
          );
          const collectionPostcode = get(
            formValues,
            FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
          );
          const senderAddressDisabled = get(
            errors,
            FORM.DESTINATION_ADDRESS_KEY
          );
          const parcelSizeDisabled =
            senderAddressDisabled ||
            !collectionPostcode ||
            get(errors, FORM.COLLECTION_ADDRESS_KEY);
          const parcelDestinationDisabled =
            !networks?.length ||
            parcelSizeDisabled ||
            parcelsInvalid ||
            isTotalQuantityOverLimit ||
            isTotalValueOverLimit;
          const parcelCollectionDisabled =
            parcelDestinationDisabled ||
            !deliverTo ||
            (!get(formValues, FORM.SHIPMENT_FIELDS.PICKUP_DETAILS.KEY) &&
              deliverTo !== POINT_TYPE.DOOR) ||
            isTotalQuantityOverLimit ||
            isTotalValueOverLimit;
          const networkDisabled =
            parcelDestinationDisabled ||
            !collectFrom ||
            (collectFrom === POINT_TYPE.DOOR && !shipmentDate) ||
            isTotalQuantityOverLimit ||
            isTotalValueOverLimit;
          const insuranceVisible = !!(
            selectedNetwork &&
            selectedNetwork.insurance &&
            !networkDisabled
          );
          const setPickupPoint = pickupPoint => {
            setReferences(REFERENCE_NAME.DELIVERY_PICKUP, pickupPoint);
            form.change(
              FORM.SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
              PickupHelper.convertPickupToFormValues(pickupPoint)
            );
          };

          return (
            <form id='parcelDetailsForm'>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={8}>
                  <FormSection
                    title={STRINGS.WHERE_YOUR_PARCEL_GOES}
                    section={SECTION.DELIVERY_ADDRESS}
                    related={[
                      SECTION.DELIVER_TO,
                      SECTION.COLLECT_FROM,
                      SECTION.SHIPMENT_DATE,
                      SECTION.NETWORK,
                      SECTION.INSURANCE,
                    ]}
                  >
                    <AddressDetailsForm
                      config={{
                        fields: {
                          addressId: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_ADDRESS_KEY
                              .KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          coordinates: {
                            name: FORM.SHIPMENT_FIELDS
                              .DESTINATION_ADDRESS_COORDINATES.KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          country: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
                              ],
                          },
                          postcode: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                              ],
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                            maxLength: 8,
                            parse: value => StringUtil.removeWhitespaces(value),
                          },
                          organisation: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                              .KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                                .LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                                  .KEY
                              ],
                            maxLength: 35,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                          street: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_STREET.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_STREET.KEY
                              ],
                            maxLength: 35,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                          locality: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_LOCALITY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY
                              ],
                            maxLength: 35,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                          town: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_CITY.KEY,
                            label: FORM.SHIPMENT_FIELDS.DESTINATION_CITY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_CITY.KEY
                              ],
                            maxLength: 35,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                          county: {
                            name: FORM.SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DESTINATION_COUNTY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY
                              ],
                            maxLength: 35,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                          deliveryInstructions: {
                            name: FORM.SHIPMENT_FIELDS.DELIVERY_INSTRUCTIONS
                              .KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.DELIVERY_INSTRUCTIONS.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.DELIVERY_INSTRUCTIONS.KEY
                              ],
                            maxLength: 50,
                            disabled: !deliveryCountryCode,
                            canResetOption: true,
                          },
                        },
                        hasActionButtons: true,
                        idPrefix: 'delivery',
                        addressBookConfig: {
                          addressBookType:
                            FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
                        },
                      }}
                    />
                    <AddressBookModal
                      sx={{ mt: 2 }}
                      onSelect={selectedAddresses =>
                        onSelectFromAddressBook(
                          form,
                          selectedAddresses,
                          FORM.DELIVERY_DETAILS_KEY
                        )
                      }
                    />
                  </FormSection>
                  <FormSection
                    title={STRINGS.WHERE_YOUR_PARCEL_FROM}
                    section={SECTION.COLLECTION_ADDRESS}
                    disabled={senderAddressDisabled}
                    related={[
                      SECTION.DELIVER_TO,
                      SECTION.COLLECT_FROM,
                      SECTION.SHIPMENT_DATE,
                      SECTION.NETWORK,
                      SECTION.INSURANCE,
                    ]}
                  >
                    <AddressDetailsForm
                      config={{
                        availableCountryCodes: [STRINGS.GB],
                        fields: {
                          addressId: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_KEY
                              .KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          coordinates: {
                            name: FORM.SHIPMENT_FIELDS
                              .COLLECTION_ADDRESS_COORDINATES.KEY,
                            props: { sx: { display: 'none' } },
                            canResetOption: true,
                          },
                          country: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.LABEL,
                            // Disable change to make only GB set
                            disabled: !!get(
                              values,
                              FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                            ),
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                              ],
                          },
                          postcode: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                              ],
                            canResetOption: true,
                            maxLength: 8,
                            parse: value => StringUtil.removeWhitespaces(value),
                          },
                          organisation: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION
                              .KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION
                                .LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                              ],
                            maxLength: 35,
                            canResetOption: true,
                          },
                          street: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
                            label: FORM.SHIPMENT_FIELDS.COLLECTION_STREET.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_STREET.KEY
                              ],
                            maxLength: 35,
                            canResetOption: true,
                          },
                          locality: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY,
                            label:
                              FORM.SHIPMENT_FIELDS.COLLECTION_LOCALITY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY
                              ],
                            maxLength: 35,
                            canResetOption: true,
                          },
                          town: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_CITY.KEY,
                            label: FORM.SHIPMENT_FIELDS.COLLECTION_CITY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_CITY.KEY
                              ],
                            maxLength: 35,
                            canResetOption: true,
                          },
                          county: {
                            name: FORM.SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY,
                            label: FORM.SHIPMENT_FIELDS.COLLECTION_COUNTY.LABEL,
                            required:
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY
                              ],
                            maxLength: 35,
                            canResetOption: true,
                          },
                        },
                        hasActionButtons: true,
                        idPrefix: 'collect',
                        addressBookConfig: {
                          addressBookType:
                            FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
                          customization: {
                            // Disable all non 'GB' addresses in address book
                            rowStyle: item =>
                              !AddressHelpers.isCountryGB(item.countryCode)
                                ? { opacity: 0.5, pointerEvents: 'none' }
                                : undefined,
                          },
                        },
                      }}
                      disabled={senderAddressDisabled}
                    />
                    <AddressBookModal
                      sx={{ mt: 2 }}
                      onSelect={selectedAddresses =>
                        onSelectFromAddressBook(
                          form,
                          selectedAddresses,
                          FORM.COLLECTION_DETAILS_KEY
                        )
                      }
                      config={{
                        customization: {
                          // Disable all non 'GB' addresses in address book
                          rowStyle: item =>
                            !AddressHelpers.isCountryGB(item.countryCode)
                              ? { opacity: 0.5, pointerEvents: 'none' }
                              : undefined,
                        },
                      }}
                    />
                  </FormSection>
                  {/* parcels section */}
                  {parcels.map((parcel, index) => (
                    <FormSection
                      key={parcel._parcelId}
                      section={SECTION.PARCEL}
                      title={STRINGS.HOW_BIG_IS_YOUR_PARCEL}
                      disabled={parcelSizeDisabled}
                      related={[
                        SECTION.DELIVER_TO,
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <Grid
                        container
                        sx={{
                          my: { xs: 1, md: 2 },
                          mt: { md: 3 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.WEIGHT}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.MAX_PARCEL_WEIGHT_$(
                              ObjectUtil.getValue(
                                context,
                                'maxWeight',
                                DEFAULT_MAX_PARCEL_WEIGHT
                              )
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          md={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_WEIGHT.LABEL}
                            disabled={parcelSizeDisabled}
                            required={
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                              ]
                            }
                            fieldProps={{
                              parse: value =>
                                NormalizerHelpers.formatAndConstrainDecimal({
                                  ...weightFieldConfig,
                                  value,
                                }),
                              format: StringUtil.formatDecimalString,
                              formatOnBlur: true,
                            }}
                          />
                          <OnChange
                            name={FORM.SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                          >
                            {() => {
                              const fieldState = form.getFieldState(
                                FORM.SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)
                              );

                              if (fieldState.valid) {
                                form.change(
                                  FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
                                  NetworkHelpers.getParcelsTotalWeight(
                                    formValues
                                  )
                                );
                              }
                            }}
                          </OnChange>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.HEIGHT}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.MAX_PARCEL_HEIGHT_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_HEIGHT.KEY(index)}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_HEIGHT.LABEL}
                            disabled={parcelSizeDisabled}
                            required={
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                              ]
                            }
                            fieldProps={{
                              parse: value =>
                                StringUtil.restrictToDigitsAndLimit(value),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.WIDTH}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.MAX_PARCEL_WIDTH_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_WIDTH.KEY(index)}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_WIDTH.LABEL}
                            disabled={parcelSizeDisabled}
                            required={
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                              ]
                            }
                            fieldProps={{
                              parse: value =>
                                StringUtil.restrictToDigitsAndLimit(value),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.LENGTH}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.MAX_PARCEL_LENGTH_$(100)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_LENGTH.KEY(index)}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_LENGTH.LABEL}
                            disabled={parcelSizeDisabled}
                            required={
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                              ]
                            }
                            fieldProps={{
                              parse: value =>
                                StringUtil.restrictToDigitsAndLimit(value),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.QUANTITY}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.QUANTITY_DESCRIPTION}
                          </Typography>
                          <br />
                          <Typography variant='caption'>
                            {STRINGS.MAX_QUANTITY_$(
                              context.maxParcelNumber || 99
                            )}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                              index
                            )}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.LABEL}
                            disabled={parcelSizeDisabled}
                            required={
                              requiredKeysMap[
                                FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                              ]
                            }
                            fieldProps={{
                              parse: value =>
                                StringUtil.restrictToDigitsAndLimit(
                                  value,
                                  quantitySymbolsLengthLimit
                                ),
                            }}
                            InputProps={
                              isTotalQuantityOverLimit && {
                                error: isTotalQuantityOverLimit,
                              }
                            }
                            InputLabelProps={
                              isTotalQuantityOverLimit && {
                                error: isTotalQuantityOverLimit,
                              }
                            }
                          />
                          <OnChange
                            name={FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                              index
                            )}
                          >
                            {() => {
                              const fieldState = form.getFieldState(
                                FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(index)
                              );
                              if (fieldState.valid) {
                                form.batch(() => {
                                  form.change(
                                    FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
                                    NetworkHelpers.getParcelTotalQuantity(
                                      get(
                                        formValues,
                                        FORM.SHIPMENT_FIELDS.PARCELS.KEY,
                                        []
                                      )
                                    )
                                  );
                                  form.change(
                                    FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
                                    NetworkHelpers.getParcelsTotalWeight(
                                      formValues
                                    )
                                  );
                                  handleTotalValueChange(form, formValues);
                                });
                              }
                            }}
                          </OnChange>
                          <OnBlur
                            name={FORM.SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                              index
                            )}
                          >
                            {() => {
                              showTotalNotificationError(
                                FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY
                              );
                            }}
                          </OnBlur>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          my: 2,
                          mt: { xs: 1, md: 2 },
                          alignItems: 'flex-start',
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          sx={{
                            paddingTop: { xs: '18px', md: 0 },
                          }}
                        >
                          <Typography variant='subtitle2'>
                            {STRINGS.VALUE}
                          </Typography>
                          <Typography variant='caption'>
                            {STRINGS.VALUE_DESCRIPTION}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            pr: 1,
                            mt: { xs: 2, md: 0 },
                          }}
                        >
                          <FormInput
                            name={FORM.SHIPMENT_FIELDS.PARCEL_VALUE.KEY(index)}
                            label={FORM.SHIPMENT_FIELDS.PARCEL_VALUE.LABEL}
                            disabled={parcelSizeDisabled}
                            required={Validators.isFieldRequired(
                              parcelSchema(context),
                              '_value'
                            )}
                            fieldProps={{
                              parse: value =>
                                NormalizerHelpers.formatAndConstrainDecimal({
                                  value,
                                  maxIntegerLength: valueSymbolsLengthLimit,
                                  maxDecimalLength: quantitySymbolsLengthLimit,
                                  minValue: minParcelValue,
                                }),
                              format: StringUtil.formatDecimalString,
                              formatOnBlur: true,
                            }}
                            InputProps={
                              isTotalValueOverLimit && {
                                error: isTotalValueOverLimit,
                              }
                            }
                            InputLabelProps={
                              isTotalValueOverLimit && {
                                error: isTotalValueOverLimit,
                              }
                            }
                          />
                          <OnBlur
                            name={FORM.SHIPMENT_FIELDS.PARCEL_VALUE.KEY(index)}
                          >
                            {() => {
                              showTotalNotificationError();
                              handleTotalValueChange(form, formValues);
                            }}
                          </OnBlur>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ my: 1 }}>
                        <Grid item>
                          <Button
                            sx={{ px: 0 }}
                            variant='text'
                            onClick={() => setMaxDimsModalOpen(true)}
                            disabled={parcelSizeDisabled}
                          >
                            {STRINGS.VIEW_MAX_PARCEL_SIZES}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          mt: 1,
                          flexDirection: {
                            xs: 'column-reverse',
                            md: 'row',
                          },
                          justifyContent: {
                            xs: 'space-between',
                            md: 'flex-end',
                          },
                        }}
                      >
                        {parcels.length > 1 && (
                          <Button
                            data-testid={`outboundConsignment.parcels[${index}]._delete_button`}
                            variant={'outlined'}
                            onClick={() => deleteParcel(form, parcel._parcelId)}
                            disabled={parcelSizeDisabled}
                            startIcon={<Delete />}
                            sx={{ marginTop: { xs: 1, md: 0 } }}
                          >
                            {STRINGS.DELETE_PARCEL}
                          </Button>
                        )}
                        {index === parcels.length - 1 && (
                          <Button
                            variant='outlined'
                            onClick={() => addParcel(form)}
                            disabled={parcelSizeDisabled}
                            startIcon={<Add />}
                            sx={{ ml: { xs: 0, md: 1 } }}
                          >
                            {STRINGS.ADD_ANOTHER_PARCEL}
                          </Button>
                        )}
                      </Grid>
                    </FormSection>
                  ))}
                  <FormSection
                    title={STRINGS.WHERE_DELIVER_PARCEL_TO}
                    section={SECTION.DELIVER_TO}
                    disabled={parcelDestinationDisabled}
                    related={[
                      SECTION.COLLECT_FROM,
                      SECTION.SHIPMENT_DATE,
                      SECTION.NETWORK,
                      SECTION.INSURANCE,
                    ]}
                  >
                    <Field name={FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 2, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.SHOP}
                              highlightChip={STRINGS.MOST_POPULAR}
                              title={STRINGS.DIRECT_TO_PICKUP}
                              bodyText={STRINGS.DIRECT_TO_PICKUP_DESCRIPTION}
                              price={getDisplayedPrice(price.minShopPrice)}
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.SHOP);
                              }}
                              disabled={
                                parcelDestinationDisabled ||
                                !shipToShopAvailable
                              }
                            />
                            <PickupModal
                              label={STRINGS.SELECT_DPD_PICKUP_POINT}
                              id='view-collection-pickups'
                              variant='text'
                              showSelectButton={true}
                              disabled={
                                input.value !== POINT_TYPE.SHOP ||
                                parcelDestinationDisabled ||
                                !shipToShopAvailable
                              }
                              selectedPickupPoint={activeDestinationPickupPoint}
                              onChange={setPickupPoint}
                              initialPostcodeValue={get(
                                values,
                                FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                              )}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.DOOR}
                              title={STRINGS.DELIVER_TO_RECIPIENT}
                              bodyText={
                                STRINGS.DELIVER_TO_RECIPIENT_DESCRIPTION
                              }
                              price={getDisplayedPrice(price.minDoorPrice)}
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.DOOR);
                                form.change(
                                  FORM.SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                  undefined
                                );
                              }}
                              disabled={parcelDestinationDisabled}
                            />
                          </Grid>
                          {showActiveDestinationPickupPoint && (
                            <Grid container item md={12} xs={12}>
                              <PickupPointWidget
                                pickupPoint={activeDestinationPickupPoint}
                                pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                showAddressInLine
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Field>
                    <OnChange name={FORM.SHIPMENT_FIELDS.PICKUP_DETAILS.KEY}>
                      {value => {
                        //NOTE: if value we set pickup inside PickupModal
                        if (!value) {
                          setReferences(REFERENCE_NAME.DELIVERY_PICKUP, null);
                        }
                      }}
                    </OnChange>
                  </FormSection>
                  <FormSection
                    title={STRINGS.WOULD_YOU_LIKE_COLLECTION}
                    section={SECTION.COLLECT_FROM}
                    disabled={parcelCollectionDisabled}
                    related={[
                      SECTION.NETWORK,
                      SECTION.SHIPMENT_DATE,
                      SECTION.INSURANCE,
                    ]}
                  >
                    <Field name={FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 2, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.SHOP}
                              highlightChip={STRINGS.MOST_POPULAR}
                              title={STRINGS.NO_DROP_OFF}
                              bodyText={STRINGS.NO_DROP_OFF_DESCRIPTION}
                              price='Free'
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.SHOP);
                              }}
                              disabled={parcelCollectionDisabled}
                            />
                            <PickupModal
                              label={STRINGS.VIEW_NEARBY_PICKUPS}
                              id='view-collection-pickups'
                              variant='text'
                              showSelectButton={false}
                              disabled={
                                parcelCollectionDisabled ||
                                input.value !== POINT_TYPE.SHOP
                              }
                              selectedPickupPoint={activeCollectionPickupPoint}
                              initialPostcodeValue={collectionPostcode}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === POINT_TYPE.DOOR}
                              title={STRINGS.YES_COLLECT}
                              bodyText={STRINGS.YES_COLLECT_DESCRIPTION}
                              price={getDisplayedExtraPrice(
                                price.collectionExtraPrice
                              )}
                              printerRequired
                              cardOnclick={() => {
                                input.onChange(POINT_TYPE.DOOR);
                              }}
                              disabled={parcelCollectionDisabled}
                            />
                          </Grid>
                          {showActiveCollectionPickupPoint && (
                            <Grid container item md={12} xs={12}>
                              <PickupPointWidget
                                pickupPoint={activeCollectionPickupPoint}
                                pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                showAddressInLine
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Field>
                    <OnChange name={FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                      {value => {
                        const isCollectFromShop = value === POINT_TYPE.SHOP;

                        form.change(
                          FORM.SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                          isCollectFromShop ? true : undefined
                        );

                        setReferences(REFERENCE_NAME.COLLECTION_DATES, []);
                      }}
                    </OnChange>
                  </FormSection>
                  {collectionDateAvailable && (
                    <Loader
                      promiseFn={loadCollectionDates}
                      defaultCutOff={defaultCutOff}
                      collectionCountryCode={collectionCountryCode}
                      collectionPostcode={collectionPostcode}
                    >
                      {collectionDates => {
                        if (
                          collectionDates?.length > 0 &&
                          (!shipmentDate ||
                            findIndex(
                              collectionDates,
                              ({ date }) => date === shipmentDate
                            ) === -1)
                        ) {
                          form.batch(() => {
                            form.change(
                              FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY,
                              collectionDates[0].date
                            );
                            form.change(
                              FORM.SHIPMENT_FIELDS.COLLECTION_FIRST_IN_AREA.KEY,
                              collectionDates[0].firstInArea
                            );
                            form.change(
                              FORM.SHIPMENT_FIELDS.COLLECTION_LAST_IN_AREA.KEY,
                              collectionDates[0].lastInArea
                            );
                          });
                        }

                        return (
                          <FormSection
                            title={STRINGS.COLLECTION_DATE_HEADER}
                            section={SECTION.SHIPMENT_DATE}
                            disabled={
                              parcelCollectionDisabled ||
                              !collectionDates?.length
                            }
                            related={[SECTION.NETWORK, SECTION.INSURANCE]}
                          >
                            <Box sx={{ mt: 2 }}>
                              <FormInput
                                name={FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY}
                                label={FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.LABEL}
                                disabled={
                                  parcelCollectionDisabled ||
                                  !collectionDates?.length
                                }
                                select
                                size='small'
                                required={
                                  requiredKeysMap[
                                    FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY
                                  ]
                                }
                                sx={{ width: '100%' }}
                              >
                                {collectionDates.map(({ date }, index) => (
                                  <MenuItem key={index} value={date}>
                                    {moment(
                                      date,
                                      DATE_FORMAT.DATE_TIME_FORMAT
                                    ).format('dddd Do MMMM YYYY')}
                                  </MenuItem>
                                ))}
                              </FormInput>
                              <OnChange
                                name={FORM.SHIPMENT_FIELDS.SHIPMENT_DATE.KEY}
                              >
                                {value => {
                                  if (value) {
                                    form.batch(() => {
                                      const dateObj = find(
                                        collectionDates,
                                        ({ date }) => date === value
                                      );
                                      form.change(
                                        FORM.SHIPMENT_FIELDS
                                          .COLLECTION_FIRST_IN_AREA.KEY,
                                        dateObj.firstInArea
                                      );
                                      form.change(
                                        FORM.SHIPMENT_FIELDS
                                          .COLLECTION_LAST_IN_AREA.KEY,
                                        dateObj.lastInArea
                                      );
                                    });
                                  }
                                }}
                              </OnChange>
                            </Box>
                          </FormSection>
                        );
                      }}
                    </Loader>
                  )}
                  <FormSection
                    title={
                      collectFrom === POINT_TYPE.SHOP &&
                      deliverTo === POINT_TYPE.SHOP
                        ? STRINGS.WHEN_ARRIVE
                        : STRINGS.WHEN_DELIVER
                    }
                    section={SECTION.NETWORK}
                    disabled={networkDisabled}
                  >
                    {networkDisabled ? (
                      <Box sx={{ mt: 2 }}>
                        <ActionButton
                          title={STRINGS.AVAILABLE_SERVICES}
                          bodyText={STRINGS.WE_ARE_CALCULATING_SERVICES}
                          price='--,--'
                          disabled
                        />
                      </Box>
                    ) : (
                      <ServiceButtons
                        fieldName={FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY}
                        form={form}
                        formValues={formValues}
                        networks={filteredNetworks}
                        price={price}
                        collectFrom={collectFrom}
                        shipmentDate={shipmentDate}
                        disabled={networkDisabled}
                      />
                    )}
                  </FormSection>
                  {insuranceVisible && (
                    <FormSection
                      title={STRINGS.WOULD_YOU_LIKE_INSURANCE}
                      section={SECTION.INSURANCE}
                      disabled={
                        isTotalQuantityOverLimit || isTotalValueOverLimit
                      }
                    >
                      <Field name={FORM.SHIPMENT_FIELDS.LIABILITY_VALUE.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid
                              container
                              item
                              md={
                                recommendedInsurance?.insuranceCost !== 0
                                  ? '6'
                                  : '12'
                              }
                              xs={12}
                              key={`${freeInsurance.insuranceValue}-${freeInsurance.insuranceCost}`}
                            >
                              <ActionButton
                                selected={
                                  String(input.value) ===
                                  String(freeInsurance.insuranceValue)
                                }
                                title={INSURANCE_SECTION.TITLE(
                                  freeInsurance.insuranceValue
                                )}
                                highlightChip={
                                  recommended[freeInsurance.insuranceValue] &&
                                  STRINGS.RECOMMENDED
                                }
                                bodyText={INSURANCE_SECTION.DESCRIPTION(
                                  freeInsurance.insuranceValue,
                                  freeInsurance.insuranceCost
                                )}
                                price={INSURANCE_SECTION.PRICE(
                                  freeInsurance.insuranceCost
                                )}
                                cardOnclick={() =>
                                  input.onChange(freeInsurance.insuranceValue)
                                }
                              />
                            </Grid>
                            {recommendedInsurance &&
                              recommendedInsurance.insuranceCost !== 0 && (
                                <Grid
                                  container
                                  item
                                  md={6}
                                  xs={12}
                                  key={`${recommendedInsurance.insuranceValue}-${recommendedInsurance.insuranceCost}`}
                                >
                                  <ActionButton
                                    selected={
                                      String(input.value) ===
                                      String(
                                        recommendedInsurance.insuranceValue
                                      )
                                    }
                                    title={INSURANCE_SECTION.TITLE(
                                      recommendedInsurance.insuranceValue
                                    )}
                                    highlightChip={
                                      recommended[
                                        recommendedInsurance.insuranceValue
                                      ] && STRINGS.RECOMMENDED
                                    }
                                    bodyText={INSURANCE_SECTION.DESCRIPTION(
                                      recommendedInsurance.insuranceValue,
                                      recommendedInsurance.insuranceCost
                                    )}
                                    price={INSURANCE_SECTION.PRICE(
                                      recommendedInsurance.insuranceCost
                                    )}
                                    cardOnclick={() =>
                                      input.onChange(
                                        recommendedInsurance.insuranceValue
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                          </Grid>
                        )}
                      </Field>
                    </FormSection>
                  )}
                  <Debugger>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  </Debugger>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Summary
                    displayItemsPriceDetails={
                      !parcelSizeDisabled && !!deliverTo
                    }
                    displayInsurance={insuranceVisible}
                    onClickBack={handleBack}
                    submitDisabled={invalid || submitting}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </OrderForm>

      <MaxParcelDimsModal
        open={maxDimsModalOpen}
        onClose={() => setMaxDimsModalOpen(false)}
      />
    </Trackable>
  );
};

export default ParcelDetails;
