export const PRIVACY_POLICY_URL = 'https://www.dpd.co.uk/privacy_policy.jsp';
export const COOKIE_POLICY_URL = 'https://www.dpd.co.uk/cookie-policy.jsp';
export const CYBER_SECURITY_URL =
  'https://www.dpd.co.uk/content/about_dpd/phishing.jsp';
export const POLICY_FOR_DISABLED_URL =
  'https://www.dpd.co.uk/pdf/dpd-policy-for-collection-delivery-pickup-of-parcels-for-disabled-consumers.pdf';
export const FUEL_ENERGY_SURCHARGE_URL =
  'https://www.dpd.co.uk/content/my_dpd/fuel_surcharge.jsp';
export const GEOPOST_URL = 'https://www.geopost.com/en/';
export const DPD_URL = 'https://www.dpd.co.uk/';
export const DPO_PRIVACY_NOTICE =
  'https://www.dpd.co.uk/dpo-privacy-notice-1.jsp';
export const GOV_TRADE_TARIFF = 'https://www.gov.uk/trade-tariff';
