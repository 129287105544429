import { useCallback, useState } from 'react';
import { PICKUP_POINT_TYPE } from '../../../constants/pickupPoint';

const useShopFinderControls = (data, setFilteredLocations) => {
  const [allAnchorEl, setAllAnchorEl] = useState(null);
  const [allExpanded, setAllExpanded] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [selectedAllItem, setSelectedAllItem] = useState(null);
  const [selectedFilterItem, setSelectedFilterItem] = useState([]);

  const handleAllClick = useCallback(event => {
    setAllAnchorEl(event.currentTarget);
    setAllExpanded(prev => !prev);
  }, []);

  const handleFilterClick = useCallback(event => {
    setFilterAnchorEl(event.currentTarget);
    setFilterExpanded(prev => !prev);
  }, []);

  const handleCloseAll = useCallback(() => {
    setAllAnchorEl(null);
    setAllExpanded(false);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setFilterAnchorEl(null);
    setFilterExpanded(false);
  }, []);

  const applyFilter = useCallback(
    (type, filterItems = []) => {
      let filtered = data;
      if (
        type === PICKUP_POINT_TYPE.SHOP ||
        type === PICKUP_POINT_TYPE.LOCKER
      ) {
        filtered = filtered.filter(item => item.pickupLocation.type === type);
      }
      if (filterItems.length > 0) {
        filtered = filtered.filter(item => filterItems.includes(item.key));
      }
      setFilteredLocations(filtered);
    },
    [data, setFilteredLocations]
  );

  const handleAllItemClick = useCallback(
    item => {
      const newSelectedItem = item.key === selectedAllItem?.key ? null : item;
      setSelectedAllItem(newSelectedItem);
      handleCloseAll();
      applyFilter(
        newSelectedItem ? newSelectedItem.key : null,
        selectedFilterItem
      );
    },
    [selectedAllItem, applyFilter, handleCloseAll, selectedFilterItem]
  );

  const handleFilterItemClick = useCallback(
    item => {
      const currentSelectedItems = selectedFilterItem || [];
      const isSelected = currentSelectedItems.includes(item.key);
      const newSelectedItems = isSelected
        ? currentSelectedItems.filter(i => i !== item.key)
        : [...currentSelectedItems, item.key];

      setSelectedFilterItem(newSelectedItems);
      applyFilter(selectedAllItem?.key, newSelectedItems);
    },
    [selectedFilterItem, applyFilter, selectedAllItem]
  );

  return {
    allAnchorEl,
    allExpanded,
    filterAnchorEl,
    filterExpanded,
    selectedAllItem,
    selectedFilterItem,
    setSelectedFilterItem,
    setSelectedAllItem,
    handleAllClick,
    handleFilterClick,
    handleCloseAll,
    handleCloseFilter,
    handleAllItemClick,
    handleFilterItemClick,
  };
};

export default useShopFinderControls;
