/* eslint-disable max-lines */
/* eslint-disable complexity */
import { cloneDeep, get, isBoolean, isEmpty, isNil, set } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { OnChange } from 'react-final-form-listeners';

import { Radios } from 'mui-rff';
import {
  ANALYTICS,
  FORM,
  INVOICE_CONSTANTS,
  STRINGS,
} from '../../../../constants';
import InformationCollecting from '../../../../components/InformationCollecting/index';
import AddressWidget from '../../../../components/AddressWidget';
import DropDown from '../../../../components/DropDown/';
import PickupMapImage from '../../../../components/MapBox/components/PickupMapImage';
import { useOrderSnackbar, useReferencesLoader } from '../../hooks';
import { useOverlay } from '../../../../features/Overlay';
import { DEFAULT_BOOLEAN_KEY_VALUE, USER_TYPES } from '../../constants';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import addressBooksSlice from '../../../../redux/addressBooksSlice';
import { useAuth } from '../../../../features/Auth';
import Loader from '../../../../features/Loader';
import { ObjectUtil, StringUtil } from '../../../../utils';
import { ShipmentHelper as PageShipmentHelpers } from '../../helpers';
import Debugger from '../../../../components/Debugger';
import {
  FormHelpers,
  InvoiceHelpers,
  NetworkHelpers,
  NormalizerHelpers,
  ShipmentHelpers,
} from '../../../../helpers';
import OrderForm from '../../components/OrderForm';
import Summary from '../../components/Summary';
import { FormInput } from '../../../../components/FormInput';
import * as OrderSelectors from '../../selectors';
import { NiRequiredFieldsActions } from '../../../../redux/niRequiredFieldsSlice';
import useAnalytics from '../../../../hooks/useAnalytics';
import { isConflictError } from '../../../../helpers/error';
import * as AddressDetailsHelpers from './helpers';

// Form field wrapper styled for flex layout
const FieldWrapper = ({ children, sx }) => (
  <Box
    sx={{
      width: { xs: '100%', md: '50%' },
      p: 1,
      pb: 2,
      ...sx,
    }}
  >
    {children}
  </Box>
);

const AddressDetails = ({
  previousStep,
  values,
  setValues,
  references,
  goToNamedStep,
}) => {
  const { setInterfaceId, Trackable, trackAction } = useAnalytics();
  const snackbar = useOrderSnackbar();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [saveForNextTime, setSaveForNextTime] = useState(false);
  const [saveForNextTimeReceiver, setSaveForNextTimeReceiver] = useState(false);
  const overlay = useOverlay();
  const referencesLoader = useReferencesLoader();
  const { currentSession } = useAuth();
  const availableFields = AddressDetailsHelpers.getAllowedFields(
    values,
    references,
    currentSession
  );
  const { deliveryPickup } = references;
  const requiredFields =
    ShipmentHelpers.getAddressDetailsRequiredFields(values);
  const requiredKeys = ObjectUtil.convertObjectKeysToArray(requiredFields);
  const exportReasons = InvoiceHelpers.filterExportReasons(
    references.exportReasons,
    values
  );
  const basketItemId = searchParams.get('basketItemId');
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );
  const context = useSelector(OrderSelectors.getValidationContext);

  useEffect(() => {
    setInterfaceId(ANALYTICS.ORDER_ADDRESS_DETAILS.INTERFACE_ID);
  }, []);

  const handleBack = useCallback(
    event => {
      trackAction(event);
      previousStep();
    },
    [previousStep, setValues, values]
  );

  const saveAddressBook = useCallback(
    details =>
      dispatch(
        addressBooksSlice.actions.createAddressBook(
          PageShipmentHelpers.mapAddressDetailsToAddressBook(details)
        )
      ).unwrap(),
    []
  );

  const onSubmit = useCallback(
    async formValues => {
      try {
        overlay.show();
        const values = cloneDeep(formValues);

        if (saveForNextTime) {
          try {
            const { addressBookId } = await saveAddressBook({
              ...get(values, FORM.SHIPMENT_FIELDS.COLLECTION_DETAILS.KEY),
              vatNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
              ),
              eoriNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY
              ),
              ukimsNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
              ),
              iossNumber: get(values, FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY),
            });
            set(
              values,
              FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
              addressBookId
            );
          } catch (e) {
            if (isConflictError(e?.error)) {
              snackbar.showError({
                message: STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CONFLICT,
              });
            } else {
              snackbar.showError({
                message:
                  STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR
                    .CREATE_ADDRESS_BOOK,
              });
            }
            return;
          }
        }
        if (saveForNextTimeReceiver) {
          try {
            const { addressBookId } = await saveAddressBook({
              ...get(values, FORM.SHIPMENT_FIELDS.DELIVERY_DETAILS.KEY),
              pidNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
              ),
              eoriNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY
              ),
              vatNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
              ),
              ukimsNumber: get(
                values,
                FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY
              ),
            });
            set(
              values,
              FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
              addressBookId
            );
          } catch (e) {
            if (isConflictError(e?.error)) {
              snackbar.showError({
                message: STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.CONFLICT,
              });
            } else {
              snackbar.showError({
                message:
                  STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR
                    .CREATE_ADDRESS_BOOK,
              });
            }
            return;
          }
        }

        const updatedBasketItem = {
          basketItemId: basketItemId,
          shipment: values,
        };

        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();

        if (availableFields.isProductAvailable) {
          goToNamedStep('customsDetails', values);
        } else {
          goToNamedStep('payment', values);
        }
      } catch (e) {
        snackbar.showSubmitError({
          originError: e,
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [
      dispatch,
      overlay,
      saveForNextTime,
      saveForNextTimeReceiver,
      availableFields.isProductAvailable,
      goToNamedStep,
      snackbar,
      saveAddressBook,
      basketItemId,
    ]
  );

  const loadInitialData = useCallback(async () => {
    const refs = {};
    const invoiceAvailable = ShipmentHelpers.isInvoiceAvailable(
      ShipmentHelpers.getSaebiFlags(basketItem?.shipment)
    );
    const promises = [
      !references?.networks?.length &&
        referencesLoader.loadNetworks(
          NetworkHelpers.getNetworkQuery(basketItem?.shipment)
        ),
      referencesLoader.loadDropOffCollection(),
      referencesLoader.loadDeliveryPickup(),
      referencesLoader.loadCollectionAddress(),
      referencesLoader.loadDeliveryAddress(),
      invoiceAvailable &&
        referencesLoader.loadDeliveryAddressBook().then(r => {
          refs.deliveryAddressBook = r;
        }),
      invoiceAvailable &&
        referencesLoader.loadCollectionAddressBook().then(r => {
          refs.collectionAddressBook = r;
        }),
      invoiceAvailable &&
        isEmpty(references.exportReasons) &&
        referencesLoader.loadExportReasons(),
    ].filter(Boolean);

    await Promise.all(promises);

    return AddressDetailsHelpers.getInitialValues(basketItem?.shipment, refs);
  }, []);

  const loadNiRequiredFields = useCallback(
    async query => {
      try {
        overlay.show();
        return await dispatch(
          NiRequiredFieldsActions.fetchNiRequiredFields(query)
        ).unwrap();
      } catch (error) {
        snackbar.showError({
          message: StringUtil.formatMessage(
            STRINGS.FAILED_TO_GET_$,
            'NI required fields'
          ),
          persist: true,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch]
  );

  return (
    <Trackable loadId={ANALYTICS.ORDER_ADDRESS_DETAILS.LOAD}>
      <Loader promiseFn={loadInitialData}>
        {initialValues => (
          <OrderForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            context={{
              ...context,
              withContactDetails: true,
            }}
            requiredKeys={requiredKeys}
            validateOnInit
          >
            {({ submitting, values, form, errors, invalid }) => {
              const handleBusinessChange = async (isBusiness, atRisk) => {
                let newSaebiFlags = get(
                  values,
                  FORM.SHIPMENT_FIELDS.NETWORK.KEY
                );

                if (ShipmentHelpers.isNiShipment(values)) {
                  newSaebiFlags = await loadNiRequiredFields({
                    ...NetworkHelpers.getNiRequiredFieldsQuery(values),
                    isBusiness,
                    atRisk,
                  });
                }

                const { fieldsToUpdate, fieldsToReset } =
                  AddressDetailsHelpers.getFieldsToUpdate({
                    values,
                    shipment: basketItem.shipment,
                    isBusiness,
                    atRisk,
                    newSaebiFlags,
                    references,
                  });
                FormHelpers.bulkUpdate(form, fieldsToUpdate);
                FormHelpers.bulkReset(form, fieldsToReset);
              };

              const onIossBlur = event => {
                const value = StringUtil.trim(event.target.value);
                const gstValue = get(
                  values,
                  FORM.SHIPMENT_FIELDS.GST_VAT_PAID.KEY
                );

                if (value && isNil(gstValue)) {
                  form.change(FORM.SHIPMENT_FIELDS.GST_VAT_PAID.KEY, false);
                }

                if (!value && isBoolean(gstValue)) {
                  form.change(FORM.SHIPMENT_FIELDS.GST_VAT_PAID.KEY, undefined);
                }
              };

              return (
                <form id='addressForm'>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} md={8}>
                      {availableFields.isProductAvailable && (
                        <Paper
                          sx={{
                            p: 2,
                            mb: 2,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography variant='h3' sx={{ pl: 1 }}>
                            {STRINGS.ADDRESS_DETAILS.CUSTOMS_DETAILS}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              my: 1,
                            }}
                          >
                            {availableFields[
                              FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY
                            ] && (
                              <FieldWrapper>
                                <DropDown
                                  name={
                                    FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON
                                      .KEY
                                  }
                                  label={
                                    FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON
                                      .LABEL
                                  }
                                  required={
                                    requiredFields[
                                      FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON
                                        .KEY
                                    ]
                                  }
                                  options={exportReasons}
                                  onChange={event =>
                                    form.batch(() => {
                                      form.change(
                                        FORM.SHIPMENT_FIELDS
                                          .INVOICE_EXPORT_REASON.KEY,
                                        event.target.value
                                      );
                                      form.change(
                                        FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY,
                                        INVOICE_CONSTANTS.INVOICE_TYPES[
                                          event.target.value
                                        ]
                                      );
                                    })
                                  }
                                />
                              </FieldWrapper>
                            )}
                            <FieldWrapper>
                              <FormInput
                                name={FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY}
                                label={STRINGS.CURRENCY_SYMBOLS.GBP}
                                fullWidth
                                disabled
                              />
                            </FieldWrapper>

                            {availableFields[
                              FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS
                                .KEY
                            ] && (
                              <>
                                <FieldWrapper>
                                  <DropDown
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_EXPORTER_IS_BUSINESS.KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_EXPORTER_IS_BUSINESS.LABEL
                                    }
                                    options={USER_TYPES}
                                    required={
                                      requiredFields[
                                        FORM.SHIPMENT_FIELDS
                                          .INVOICE_IMPORTER_IS_BUSINESS.KEY
                                      ]
                                    }
                                    fieldProps={{
                                      parse: value =>
                                        NormalizerHelpers.booleanOrNullValueNormalize(
                                          value
                                        ),
                                    }}
                                  />
                                </FieldWrapper>
                                <FieldWrapper>
                                  <DropDown
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_IMPORTER_IS_BUSINESS.KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS
                                        .INVOICE_IMPORTER_IS_BUSINESS.LABEL
                                    }
                                    options={USER_TYPES}
                                    fieldProps={{
                                      parse: value =>
                                        NormalizerHelpers.booleanOrNullValueNormalize(
                                          value
                                        ),
                                    }}
                                    onChange={async event => {
                                      const value =
                                        NormalizerHelpers.booleanOrNullValueNormalize(
                                          event.target.value
                                        );

                                      if (
                                        value ===
                                        get(
                                          values,
                                          FORM.SHIPMENT_FIELDS
                                            .INVOICE_IMPORTER_IS_BUSINESS.KEY
                                        )
                                      ) {
                                        return;
                                      }

                                      await handleBusinessChange(value, false);
                                    }}
                                  />
                                </FieldWrapper>
                              </>
                            )}
                          </Box>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY
                          ] && (
                            <FieldWrapper
                              sx={{
                                width: { xs: '100%' },
                              }}
                            >
                              <FormControl
                                component='fieldset'
                                sx={{
                                  p: 2,
                                  backgroundColor: '#E5F6FD',
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={7}>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Roboto',
                                        fontSize: 16,
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        letterSpacing: '0.15px',
                                        textAlign: 'justify',
                                      }}
                                    >
                                      {STRINGS.AT_RISK_TITLE}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: 'Pluto Sans DPD',
                                        fontSize: '12px',
                                        fontWeight: 370,
                                        lineHeight: '14.82px',
                                        letterSpacing: '0.17px',
                                        textAlign: 'justify',
                                        mt: 1,
                                      }}
                                    >
                                      {STRINGS.AT_RISK_TEXT}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} md={5}>
                                    <Radios
                                      radioGroupProps={{
                                        row: true,
                                      }}
                                      name={
                                        FORM.SHIPMENT_FIELDS
                                          .INVOICE_IMPORTER_AT_RISK.KEY
                                      }
                                      required={true}
                                      data={DEFAULT_BOOLEAN_KEY_VALUE}
                                      fieldProps={{
                                        parse: value =>
                                          NormalizerHelpers.booleanOrNullValueNormalize(
                                            value
                                          ),
                                        format: value =>
                                          NormalizerHelpers.booleanOrNullValueFormat(
                                            value
                                          ),
                                      }}
                                      onChange={async event => {
                                        await handleBusinessChange(
                                          get(
                                            values,
                                            FORM.SHIPMENT_FIELDS
                                              .INVOICE_IMPORTER_IS_BUSINESS.KEY,
                                            false
                                          ),
                                          NormalizerHelpers.booleanOrNullValueNormalize(
                                            event.target.value
                                          )
                                        );
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </FieldWrapper>
                          )}
                        </Paper>
                      )}

                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant='h3' sx={{ pl: 1 }}>
                          {STRINGS.ADDRESS_DETAILS.DETAILS_TITLE}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            my: 1,
                          }}
                        >
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.CONTACT_NAME}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME
                                    .KEY
                                ]
                              }
                              maxLength={35}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY
                            }
                          >
                            {value => {
                              form.change(
                                FORM.SHIPMENT_FIELDS
                                  .INVOICE_EXPORTER_CONTACT_NAME.KEY,
                                value
                              );
                            }}
                          </OnChange>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.EMAIL}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL
                                  .KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL
                                    .KEY
                                ]
                              }
                              maxLength={100}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.PHONE}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS
                                  .COLLECTION_CONTACT_TELEPHONE.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS
                                    .COLLECTION_CONTACT_TELEPHONE.KEY
                                ]
                              }
                              maxLength={15}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE
                                .KEY
                            }
                          >
                            {value => {
                              form.change(
                                FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_TELEPHONE
                                  .KEY,
                                value
                              );
                            }}
                          </OnChange>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_EORI_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_EORI_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_EORI_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_VAT_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_VAT_NUMBER.LABEL
                                }
                                fullWidth
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_UKIMS_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_UKIMS_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_UKIMS_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={32}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_FDA_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_EXPORTER_FDA_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_EXPORTER_FDA_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={30}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY}
                                label={FORM.SHIPMENT_FIELDS.IOSS_NUMBER.LABEL}
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                onBlur={onIossBlur}
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          <AddressWidget
                            address={get(
                              values,
                              FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY,
                              {}
                            )}
                            onEditClick={handleBack}
                            additionalInfo={
                              STRINGS.ADDRESS_DETAILS.COLLECT_NOTATION
                            }
                          />
                          {availableFields.saveForNextTime && (
                            <FormControlLabel
                              sx={{ pl: 2 }}
                              control={
                                <Checkbox
                                  onChange={e =>
                                    setSaveForNextTime(e.target.checked)
                                  }
                                  name='saveForNextTime'
                                  value={saveForNextTime}
                                />
                              }
                              label={STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME}
                            />
                          )}
                        </Box>
                      </Paper>
                      <Paper sx={{ p: 2, mb: 2 }}>
                        <Typography variant='h3' sx={{ pl: 1 }}>
                          {STRINGS.ADDRESS_DETAILS.RECEIVER_DETAILS_TITLE}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            my: 1,
                          }}
                        >
                          <FieldWrapper>
                            <FormInput
                              label={
                                STRINGS.ADDRESS_DETAILS.RECEIVER_CONTACT_NAME
                              }
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                                ]
                              }
                              maxLength={35}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY
                            }
                          >
                            {value => {
                              form.change(
                                FORM.SHIPMENT_FIELDS
                                  .INVOICE_IMPORTER_CONTACT_NAME.KEY,
                                value
                              );
                            }}
                          </OnChange>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.RECEIVER_EMAIL}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL
                                    .KEY
                                ]
                              }
                              maxLength={100}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY
                            }
                          >
                            {value => {
                              form.change(
                                FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EMAIL.KEY,
                                value
                              );
                            }}
                          </OnChange>
                          <FieldWrapper>
                            <FormInput
                              label={STRINGS.ADDRESS_DETAILS.RECEIVER_PHONE}
                              fullWidth
                              name={
                                FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY
                              }
                              required={
                                requiredFields[
                                  FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE
                                    .KEY
                                ]
                              }
                              maxLength={15}
                              fieldProps={{
                                formatOnBlur: true,
                                format: StringUtil.trim,
                              }}
                            />
                          </FieldWrapper>
                          <OnChange
                            name={
                              FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY
                            }
                          >
                            {value => {
                              form.batch(() => {
                                form.change(
                                  FORM.SHIPMENT_FIELDS
                                    .DELIVERY_CONTACT_TELEPHONE.KEY,
                                  value
                                );
                                form.change(
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_TELEPHONE.KEY,
                                  value
                                );
                              });
                            }}
                          </OnChange>
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_EORI_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_EORI_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_EORI_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_VAT_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_VAT_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_VAT_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER
                              .KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_UKIMS_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_UKIMS_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_UKIMS_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={32}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY
                          ] && (
                            <FieldWrapper>
                              <FormInput
                                name={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_PID_NUMBER.KEY
                                }
                                label={
                                  FORM.SHIPMENT_FIELDS
                                    .INVOICE_IMPORTER_PID_NUMBER.LABEL
                                }
                                required={
                                  requiredFields[
                                    FORM.SHIPMENT_FIELDS
                                      .INVOICE_IMPORTER_PID_NUMBER.KEY
                                  ]
                                }
                                fullWidth
                                maxLength={45}
                                fieldProps={{
                                  formatOnBlur: true,
                                  format: StringUtil.trim,
                                }}
                              />
                            </FieldWrapper>
                          )}
                          <AddressWidget
                            address={get(
                              values,
                              FORM.SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY,
                              {}
                            )}
                            onEditClick={handleBack}
                          />
                          {availableFields.saveForNextTimeReceiver && (
                            <FormControlLabel
                              sx={{ pl: 2 }}
                              control={
                                <Checkbox
                                  onChange={e =>
                                    setSaveForNextTimeReceiver(e.target.checked)
                                  }
                                  name='saveForNextTimeReceiver'
                                  value={saveForNextTimeReceiver}
                                />
                              }
                              label={STRINGS.ADDRESS_DETAILS.SAVE_FOR_NEXT_TIME}
                            />
                          )}
                        </Box>
                      </Paper>
                      {availableFields.destinationPickupPoint && (
                        <PickupMapImage
                          title={STRINGS.RECEIVER_PICKUP_POINT}
                          pickupPoint={deliveryPickup}
                          buttonLabel={STRINGS.CHANGE_YOUR_PICKUP_POINT}
                          buttonOnClick={handleBack}
                        />
                      )}
                      <Debugger>
                        <pre>{JSON.stringify(errors, null, 2)}</pre>
                      </Debugger>
                      <InformationCollecting sx={{ pl: 2, pr: 2 }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Summary submitDisabled={submitting || invalid} />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </OrderForm>
        )}
      </Loader>
    </Trackable>
  );
};

export default AddressDetails;
