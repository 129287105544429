import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

import { STRINGS } from '../../../constants';
import { BACK_TO_HELP } from '../../../features/ErrorBoundary/constants';

const SuccessScreen = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        textAlign: 'center',
        px: { xs: 2, md: 1 },
        py: { xs: 4 },
        color: theme => theme.palette.text.primary,
      }}
    >
      <CheckCircleIcon fontSize='large' fillOpacity='0.56' />
      <Typography
        variant={'h2'}
        sx={{
          color: 'inherit',
          py: 2,
        }}
      >
        {STRINGS.YOUR_ENQUIRY_HAS_BEEN_PASSED}
      </Typography>

      <Typography
        variant={'body1'}
        sx={{
          color: 'inherit',
          maxWidth: '680px',
        }}
      >
        {STRINGS.WE_ARE_SORRY_TO_HEAR}
      </Typography>
      <Button variant='contained' sx={{ mt: 1.5 }} onClick={() => navigate(0)}>
        {BACK_TO_HELP}
      </Button>
    </Box>
  );
};

export default SuccessScreen;
