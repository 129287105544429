import { CardMedia, IconButton, Paper, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageThumb = ({ image, alt, onRemoveClick }) => {
  const theme = useTheme();

  return (
    <Paper
      variant='outlined'
      sx={{
        position: 'relative',
        width: 116,
        height: 116,
        padding: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardMedia
        component='img'
        image={image}
        alt={alt}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <IconButton
        onClick={onRemoveClick}
        size='small'
        sx={{
          position: 'absolute',
          top: '-8px',
          right: '-8px',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.palette.primary.shadow,
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </Paper>
  );
};

export default ImageThumb;
