import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme } from '@mui/material';
import useAnalytics from '../../hooks/useAnalytics';
import { BASKET } from '../../constants/analytics';
import ShareModal from '../../components/ShareModal/ShareModal';
import { COLLECTION_STEPS_CONFIG } from './config';
import { StepsList } from './components/StepsList';

const Collection = ({ shipment, dropAtShop }) => {
  const theme = useTheme();
  const [openShareModal, setOpenShareModal] = useState(false);
  const { setInterfaceId, Trackable } = useAnalytics();

  useEffect(() => {
    setInterfaceId(BASKET.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={BASKET.LOAD}>
      <Grid sx={{ background: theme.palette.primary.contrastText }}>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid
            alignItems='center'
            justifyContent='center'
            spacing={2}
            sx={{ mt: 0 }}
          >
            <Grid item xs={12} md={12}>
              <Grid sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <StepsList
                    config={COLLECTION_STEPS_CONFIG(shipment, dropAtShop)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareModal
          open={openShareModal}
          onClose={() => setOpenShareModal(false)}
        />
      </Grid>
    </Trackable>
  );
};

Collection.propTypes = {
  shipment: PropTypes.object,
};

export default Collection;
