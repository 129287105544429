import { FORM, STRINGS } from '../../../../constants';
import { StringUtil } from '../../../../utils';
import DeleteIconButton from './components/DeleteIconButton';

export const MAX_NO_OF_PRODUCTS = 4294967295;
export const MAX_VALUE_OF_PRODUCTS = 99999999.99;

export const PRODUCT_TABLE_CONFIG = (isDescriptionOnly, onDelete) => {
  const renderButton = (item, disabled) => (
    <DeleteIconButton onClick={onDelete} item={item} disabled={disabled} />
  );

  return isDescriptionOnly
    ? {
        columns: [
          {
            path: FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY,
            heading: STRINGS.PRODUCT_DESCRIPTION,
            sx: { width: '60%' },
          },
          {
            render: renderButton,
            align: 'right',
            heading: ' ',
          },
        ],
      }
    : {
        columns: [
          {
            path: FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY,
            heading: STRINGS.PRODUCT_DESCRIPTION,
            sx: { width: { xs: '50%', sm: '60%' } },
          },
          {
            path: FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY,
            heading: STRINGS.PRODUCT_QUANTITY,
            sx: { width: '15%' },
            align: 'right',
          },
          {
            path: FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY,
            heading: StringUtil.formatMessage(
              STRINGS.PRODUCT_UNIT_VALUE_$,
              STRINGS.CURRENCY_SYMBOLS.GBP
            ),
            sx: { width: { xs: '35%', sm: '25%' } },
            headColspan: 3,
            bodyColspan: 2,
          },
          {
            render: renderButton,
            align: 'right',
            bodyColspan: 1,
          },
        ],
      };
};
export const TOTAL_TABLE_CONFIG = {
  columns: [
    {
      path: 'quantity',
      heading: STRINGS.TOTAL_PRODUCTS,
      align: 'right',
      sx: { width: { xs: '33%', sm: '45%' } },
    },
    {
      path: 'weight',
      heading: STRINGS.TOTAL_WEIGHT,
      align: 'right',
      sx: { width: { xs: '33%', sm: '30%' } },
    },
    {
      path: 'value',
      heading: StringUtil.formatMessage(
        STRINGS.TOTAL_VALUE_$,
        STRINGS.CURRENCY_SYMBOLS.GBP
      ),
      align: 'left',
      sx: { width: { xs: '33%', sm: '25%' } },
    },
  ],
};
