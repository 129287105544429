import { createSelector } from '@reduxjs/toolkit';
import {
  OrderPriceAccumulator,
  ShipmentPrice,
} from '@dpdgroupuk/consumer-shipping-helper';
import { get } from 'lodash';

import { SHIPMENT_FIELDS } from '../../constants/forms';
import configSlice from '../../redux/configSlice';
import { NetworkHelpers, ShipmentHelpers } from '../../helpers';
import { countriesSelectors } from '../../redux/countriesSlice';
import { OrderSliceSelectors } from './slice';
import { DEFAULT_NAVIGATION_STEPS, REFERENCE_NAME } from './constants';
import { ShipmentHelper as PageShipmentHelpers } from './helpers';

export const getTotalAmount = createSelector(
  [
    OrderSliceSelectors.getReferences,
    OrderSliceSelectors.getValues,
    configSlice.selectors.getPriceConfig,
  ],
  (references, shipment, config) => {
    const deliverTo = get(shipment, SHIPMENT_FIELDS.DELIVER_TO.KEY);
    const network = get(shipment, SHIPMENT_FIELDS.NETWORK.KEY);

    if (!network) {
      const price = new ShipmentPrice().toViewJSON(2);
      const newPrice = NetworkHelpers.getPriceData({
        price,
        networks: references.networks,
        shipment,
        config,
      });

      if (!deliverTo) {
        return {
          ...price,
          minShopPrice: newPrice.minShopPrice,
          minDoorPrice: newPrice.minDoorPrice,
        };
      }

      return newPrice;
    }
    const accumulator = OrderPriceAccumulator.aggregate(
      new OrderPriceAccumulator(),
      'new',
      ShipmentPrice.calculateShipmentPrice(shipment, network.price, config)
    );
    if (references.voucher) {
      accumulator.applyVoucherDiscount(references.voucher);
    }
    const price = accumulator.toViewJSON(2);

    return NetworkHelpers.getPriceData({
      price,
      networks: references.networks,
      shipment,
      config,
    });
  }
);

export const getSelectedNetwork = createSelector(
  OrderSliceSelectors.getValues,
  OrderSliceSelectors.getReferences,
  (values, references) =>
    NetworkHelpers.getNetworkByCode(
      references[REFERENCE_NAME.NETWORKS],
      get(values, SHIPMENT_FIELDS.NETWORK_CODE.KEY)
    )
);

export const getNavigationSteps = createSelector(
  OrderSliceSelectors.getValues,
  values => {
    if (!ShipmentHelpers.isProductAvailable(values)) {
      return DEFAULT_NAVIGATION_STEPS.filter(
        ({ key }) => key !== 'customsDetails'
      );
    }

    return DEFAULT_NAVIGATION_STEPS;
  }
);

export const getFullShipmentRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  OrderSliceSelectors.getReferences,
  countriesSelectors.getCountries,
  ShipmentHelpers.getFullShipmentRequiredFields
);

export const getValidationContext = createSelector(
  OrderSliceSelectors.getValues,
  OrderSliceSelectors.getReferences,
  countriesSelectors.getCountries,
  PageShipmentHelpers.getValidationContext
);
