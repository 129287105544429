import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';
import { getProductRowBgColor } from '../helpers';

// eslint-disable-next-line complexity
const ProductTableItem = ({ item, column }) => {
  const { path, render, bodyColspan, align } = column;

  if (!path && !render) {
    return null;
  }

  return (
    <TableCell
      sx={{
        py: path ? 2 : 0.5,
        px: { xs: 0, sm: path ? 2 : 0.5 },
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: { xs: path ? 'left' : 'right', sm: align || 'left' },
      }}
      colSpan={bodyColspan}
    >
      {path ? item[path] || '--' : render(item, item.isDefaultValue)}
    </TableCell>
  );
};

const StyledTableRow = styled(props => (
  <TableRow {...omit(props, ['isInvalid', 'isSelectable', 'isSelected'])} />
))(({ theme, isInvalid, isSelectable, isSelected }) => {
  const backgroundColor = getProductRowBgColor(theme, isSelected, isInvalid);
  return {
    cursor: isSelectable ? 'pointer' : 'auto',
    backgroundColor,
  };
});

const ProductTable = ({
  ariaLabel,
  config,
  items = [],
  selectedItemId,
  invalidIds = [],
  onRowSelect,
}) => (
  <Table aria-label={ariaLabel} size='small' sx={{ tableLayout: 'fixed' }}>
    <TableHead>
      <TableRow>
        {config.columns.map(
          ({ heading, headColspan, align, sx }, index) =>
            heading && (
              <TableCell
                key={index}
                colSpan={headColspan}
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: 0, //NOTE: Needed to make text-overflow work
                  px: { xs: 0, sm: 2 },
                  textAlign: { xs: 'left', sm: align || 'left' },
                  ...sx,
                }}
              >
                {heading}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {items.map((item = {}, index) => {
        const isInvalid = invalidIds.includes(item._id);
        const isSelected = selectedItemId && selectedItemId === item._id;

        return (
          <StyledTableRow
            key={`${item._id}-${index}`}
            isSelected={isSelected}
            onClick={() =>
              onRowSelect && !item.isDefaultValue && onRowSelect(item)
            }
            isInvalid={isInvalid}
            isSelectable={onRowSelect && !item.isDefaultValue}
          >
            {config.columns.map((column, index) => (
              <ProductTableItem
                key={`${column.path}-${index}`}
                item={item}
                column={column}
              />
            ))}
          </StyledTableRow>
        );
      })}
    </TableBody>
  </Table>
);

ProductTable.propTypes = {
  ariaLabel: PropTypes.string,
  config: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        heading: PropTypes.string,
        headColspan: PropTypes.number,
        bodyColspan: PropTypes.number,
        align: PropTypes.oneOf(['left', 'right', 'center']),
        render: PropTypes.func,
        sx: PropTypes.object,
      })
    ).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isDefaultValue: PropTypes.bool,
      [PropTypes.string]: PropTypes.any,
    })
  ).isRequired,
  selectedItemId: PropTypes.string,
  invalidIds: PropTypes.arrayOf(PropTypes.string),
  onRowSelect: PropTypes.func,
};

export default ProductTable;
