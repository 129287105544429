import Async from 'react-async';
import PropTypes from 'prop-types';

import Rejected from './Rejected';
import Pending from './Pending';

const Loader = ({
  children,
  promiseFn,
  errorComponent,
  pendingProps,
  ...args
}) => (
  <Async promiseFn={promiseFn} {...args}>
    <Async.Pending>
      <Pending {...pendingProps} />
    </Async.Pending>
    <Async.Fulfilled>{children}</Async.Fulfilled>
    <Async.Rejected>
      {error => <Rejected error={error} component={errorComponent} />}
    </Async.Rejected>
  </Async>
);

Loader.propTypes = {
  ...Async.propTypes,
  errorComponent: PropTypes.elementType,
};

export default Loader;
