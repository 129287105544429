import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Menu, MenuItem, useTheme } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import styled from '@emotion/styled';

import { BasketActions } from '../../../redux/basket';
import { STRINGS } from '../../../constants';
import useBreakpoint from '../../../hooks/useBreakpoint';
import OrderDetails from '../../../components/OrderDetails/index';
import Loader from '../../../features/Loader';
import EmptySearchResults from '../../../components/EmptySearchResults';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../../features/ErrorBoundary';
import { useOverlay } from '../../../features/Overlay';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import parcelSlice from '../../../redux/parcelSlice';
import { InvoiceHelpers, LabelsHelpers } from '../../../helpers';
import { shipmentsApis } from '../../../apis';
import { DateUtil } from '../../../utils';
import configSlice from '../../../redux/configSlice';
import { getOrderInvoiceDownloadUrl } from './utils/downloadUrl';
import { getDuplicateBasketItemError } from './helper';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '14px',
  minHeight: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const OrderView = () => {
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();
  const isSmallScreen = useBreakpoint('sm');
  const { parcelNumber } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();
  const { csvInvoiceDownload } = useSelector(
    configSlice.selectors.getFeatureConfig
  );

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [parcel, setParcel] = useState(null);
  const [shipment, setShipment] = useState(null);

  const isPresentOrFeatureShipment =
    !!shipment && DateUtil.isPresentOrFutureDate(shipment.shipmentDate);

  const navigateToOrders = () => navigate('/profile/orders');

  const handleParcelFetch = useCallback(async () => {
    try {
      if (parcelNumber) {
        const data = await dispatch(
          parcelSlice.actions.fetchParcelByNumber(parcelNumber)
        ).unwrap();

        setParcel(data);
      }
    } catch (error) {
      setParcel(null);

      throw error;
    }
  }, [dispatch, parcelNumber]);

  const handleActionMenuClick = event => {
    setActionMenuAnchorEl(event.currentTarget);
    setIsMenuOpen(prev => !prev);
  };

  const actionMenuBtnHandleClose = () => {
    setActionMenuAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleDownloadInvoice = (parcelNumber, format) => {
    const url = getOrderInvoiceDownloadUrl(parcelNumber, format);
    window.open(url, '_blank');
    actionMenuBtnHandleClose();
  };

  const handleDownloadProformaInvoice = shipmentId => {
    const url = InvoiceHelpers.getShipmentInvoiceDownloadUrl(shipmentId);
    window.open(url, '_blank');
    actionMenuBtnHandleClose();
  };

  const handleDownloadLabel = shipmentId => {
    const url = LabelsHelpers.getShipmentLabelsDownloadUrl(shipmentId);
    window.open(url, '_blank');
    actionMenuBtnHandleClose();
  };

  const sendAgainOrder = useCallback(
    async parcelCode => {
      try {
        overlay.show();

        const { basketItemId } = await dispatch(
          BasketActions.duplicateBasketItem(parcelCode)
        ).unwrap();

        navigate(`/order?basketItemId=${basketItemId}`);
      } catch (error) {
        showError({
          message: getDuplicateBasketItemError(error),
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, navigate, overlay, showError]
  );

  useEffect(() => {
    const handleShipmentFetch = async () => {
      try {
        if (parcelNumber) {
          const shipmentData = await shipmentsApis.getShipmentByParcelNumber(
            parcelNumber
          );
          setShipment(shipmentData);
        }
      } catch (error) {
        setShipment(null);
        showError({ message: STRINGS.FETCHING_ERROR });
      }
    };

    handleShipmentFetch();
  }, []);

  return (
    <Box
      sx={{
        background: theme => theme.palette.primary.pageBackground,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2,
        paddingBottom: 6,
        paddingX: isSmallScreen ? 0 : 1,
      }}
    >
      <Loader
        promiseFn={handleParcelFetch}
        pendingProps={{
          sx: { marginTop: { xs: '50px', md: '100px' } },
        }}
        parcelNumber={parcelNumber}
        errorComponent={error =>
          ErrorBoundary({
            config: getErrorBoundaryConfig[ERROR_BOUNDARY_TYPES.ORDER_ERROR](
              error.message
            ),
            sx: {
              height: '100%',
              minHeight: '100%',
            },
          })
        }
      >
        {parcel ? (
          <OrderDetails
            parcel={parcel}
            onBackClick={navigateToOrders}
            footerButtons={
              <>
                <Button
                  variant='outlined'
                  onClick={() => sendAgainOrder(parcel?.parcelCode)}
                >
                  {STRINGS.ORDER_DETAILS.SEND_AGAIN}
                </Button>
                <Button
                  variant='outlined'
                  sx={{ pr: 1 }}
                  onClick={handleActionMenuClick}
                  endIcon={
                    <ArrowDropDown
                      sx={{
                        transition: 'transform 0.3s',
                        transform: isMenuOpen
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                    />
                  }
                >
                  {STRINGS.ORDER_DETAILS.DOWNLOAD}
                </Button>
                <Menu
                  anchorEl={actionMenuAnchorEl}
                  open={!!actionMenuAnchorEl}
                  onClose={actionMenuBtnHandleClose}
                  MenuListProps={{
                    sx: { minWidth: '115px' },
                  }}
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: -26,
                    vertical: 125,
                  }}
                >
                  {isPresentOrFeatureShipment && (
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={() => handleDownloadLabel(shipment.shipmentId)}
                    >
                      {STRINGS.ORDER_DETAILS.LABEL_PDF}
                    </StyledMenuItem>
                  )}
                  {parcel.invoiceType && isPresentOrFeatureShipment && (
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={() =>
                        handleDownloadProformaInvoice(shipment.shipmentId)
                      }
                    >
                      {STRINGS.ORDER_DETAILS.PROFORMA_INVOICE_PDF}
                    </StyledMenuItem>
                  )}
                  <StyledMenuItem
                    disableRipple
                    theme={theme}
                    onClick={() =>
                      handleDownloadInvoice(
                        parcelNumber,
                        STRINGS.FILE_FORMAT.PDF
                      )
                    }
                  >
                    {STRINGS.ORDER_DETAILS.INVOICE_PDF}
                  </StyledMenuItem>
                  {csvInvoiceDownload && (
                    <StyledMenuItem
                      disableRipple
                      theme={theme}
                      onClick={() =>
                        handleDownloadInvoice(
                          parcelNumber,
                          STRINGS.FILE_FORMAT.CSV
                        )
                      }
                    >
                      {STRINGS.ORDER_DETAILS.INVOICE_CSV}
                    </StyledMenuItem>
                  )}
                </Menu>
              </>
            }
          />
        ) : (
          <EmptySearchResults
            button={
              <Button
                variant={'contained'}
                sx={{ mt: 2 }}
                onClick={navigateToOrders}
              >
                {STRINGS.ORDER_DETAILS.BACK_TO_ORDERS}
              </Button>
            }
          />
        )}
      </Loader>
    </Box>
  );
};

export default OrderView;
