import { Divider, MenuItem, TextField, useTheme } from '@mui/material';
import { COUNTRIES } from '../../constants';

const CountriesSelect = ({
  countries,
  component: Component = TextField,
  ...props
}) => {
  const topCountriesLength = COUNTRIES.TOP_COUNTRIES.length;
  const theme = useTheme();

  return (
    <Component {...props}>
      {countries.map(({ countryKey, countryName } = {}, index) =>
        index === topCountriesLength - 1 ? (
          <Divider
            key='delimeter'
            sx={{
              borderBottom: `1px dashed ${theme.palette.info.light}`,
              my: 1,
            }}
          />
        ) : (
          <MenuItem key={countryKey} value={countryKey}>
            {countryName}
          </MenuItem>
        )
      )}
    </Component>
  );
};

export default CountriesSelect;
