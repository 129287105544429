// Check if app is in development mode
export const IS_DEV_MODE =
  import.meta.env.MODE === 'development' || import.meta.env.DEV;

export const DEV_APIS_BASE_URL = 'http://localhost:8030';
export const DEV_AUTH_BASE_URL = 'http://localhost:8031';

export const APIS_BASE_URL = IS_DEV_MODE
  ? DEV_APIS_BASE_URL
  : import.meta.env.VITE_APIS_URL;

export const AUTH_BASE_URL = IS_DEV_MODE
  ? DEV_AUTH_BASE_URL
  : import.meta.env.VITE_APIS_URL;

export const MAX_BASKET_ITEMS = parseInt(import.meta.env.VITE_MAX_BASKET_ITEMS);

const DEV_FMP_BASE_URL = 'https://d1-track.dpd.co.uk';
const Q1_FMP_BASE_URL = 'https://q1-track.dpd.co.uk';
const Q2_FMP_BASE_URL = 'https://q2-track.dpd.co.uk';
const PROD_FMP_BASE_URL = 'https://track.dpd.co.uk';

const getFMPBaseProdUrl = () => {
  const urlPrefix = import.meta.url.split('//')[1].substring(0, 2);
  switch (urlPrefix) {
    case 'd1':
      return DEV_FMP_BASE_URL;
    case 'q1':
      return Q1_FMP_BASE_URL;
    case 'q2':
      return Q2_FMP_BASE_URL;
    default:
      return PROD_FMP_BASE_URL;
  }
};

export const FMP_BASE_URL = IS_DEV_MODE
  ? DEV_FMP_BASE_URL
  : getFMPBaseProdUrl();

export const AFD = {
  ID: import.meta.env.VITE_AFD_ID,
  TOKEN: import.meta.env.VITE_AFD_TOKEN,
  SERIAL: import.meta.env.VITE_AFD_SERIAL,
  PASSWORD: import.meta.env.VITE_AFD_PASSWORD,
  API_BASE_URL_SERIAL_PASSWORD: 'https://pce.afd.co.uk/afddata.pce',
  API_BASE_URL_ID_TOKEN: 'https://apps.afd.co.uk/json',
};

export const CHAT_CONFIG = {
  chatBotHost: APIS_BASE_URL,
  client: import.meta.env.VITE_REACT_APP_SF_AUTH_CLIENT_ID,
  salesforce: import.meta.env.VITE_REACT_APP_SF_LIVEAGENT_URL,
  organizationId: import.meta.env.VITE_REACT_APP_SF_ORGANIZATION,
  eliteDeploymentId: import.meta.env.VITE_REACT_APP_SF_DEPLOYMENT_ID,
  platinumDeploymentId: import.meta.env.VITE_REACT_APP_SF_DEPLOYMENT_ID,
  eliteButtonId: import.meta.env.VITE_REACT_APP_SF_BUTTON_ID,
  platinumButtonId: import.meta.env.VITE_REACT_APP_SF_BUTTON_ID,
  businessUnit: 2,
  isSalesforceCaseRequired: false,
  apiVersion: import.meta.env.VITE_REACT_APP_SF_API_VERSION,
  platform: import.meta.env.VITE_REACT_APP_SF_PLATFORM,
};
