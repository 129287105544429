import { isBoolean } from 'lodash';

export const phoneNumberNormalize = value => {
  if (value == null) {
    return value;
  }

  let phoneValue = value.replace(/[^+\d]/g, '');
  phoneValue = phoneValue.replace(/(?!^)\+/g, '');

  return phoneValue.substring(0, 15);
};

export const formatAndConstrainDecimal = ({
  value,
  maxIntegerLength = 2,
  maxDecimalLength = 2,
  minValue = 1,
}) => {
  const numericValue = String(value).replace(/[^0-9.]/g, '');
  const [integerPart, decimalPart] = numericValue.split('.');

  if (decimalPart !== undefined) {
    const fieldValue = `${integerPart.slice(
      0,
      maxIntegerLength
    )}.${decimalPart.slice(0, maxDecimalLength)}`;
    if (
      decimalPart.length >= maxDecimalLength &&
      Number(fieldValue) < minValue
    ) {
      return minValue.toString();
    }
    return fieldValue;
  }
  return integerPart.slice(0, maxIntegerLength);
};

export const addTrailingZeros = (value, zeroCount = 2) => {
  if (!value) {
    return value;
  }

  const numericValue = parseFloat(value);
  if (Number.isInteger(numericValue)) {
    return `${numericValue}.${'0'.repeat(zeroCount)}`;
  }
  const [integerPart, decimalPart = ''] = String(numericValue).split('.');
  return `${integerPart}.${decimalPart.padEnd(zeroCount, '0')}`;
};

const TRUTHY = ['true', 'y', 1, '1', 'a'];
const FALSY = ['false', 'n', 0, '0', 'b'];

export const convertToBool = (value, forceCust = false) => {
  const normalizedValue =
    typeof value === 'string' ? value.toLowerCase() : value;

  if (TRUTHY.includes(normalizedValue)) {
    return true;
  }
  if (FALSY.includes(normalizedValue)) {
    return false;
  }

  return forceCust ? null : value;
};

export const booleanOrNullValueNormalize = value => convertToBool(value, true);

export const booleanOrNullValueFormat = value => {
  const castValue = convertToBool(value, false);
  if (isBoolean(castValue)) {
    return castValue ? '1' : '0';
  }
  return '';
};
