import { useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../features/ErrorBoundary';
import Loader from '../../features/Loader/Loader';
import { MAX_BASKET_ITEMS } from '../../constants/env';
import { ORDER } from '../../constants/routes';
import { FIELDS } from '../../constants/forms';
import { BasketSelectors } from '../../redux/basket';
import * as QuotesActions from './actions';

const Quotes = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();
  const basketItems = useSelector(BasketSelectors.getBasketItems);

  const load = useCallback(
    async params => {
      if (basketItems.length >= MAX_BASKET_ITEMS) {
        const error = new Error('Reached max number of basket items');
        error.type = ERROR_BOUNDARY_TYPES.REACHED_MAX_BASKET_ITEMS;
        throw error;
      }
      return dispatch(QuotesActions.load(params)).unwrap();
    },
    [dispatch, basketItems]
  );

  return (
    <Loader
      promiseFn={load}
      deliveryCountryCode={searchParams.get(FIELDS.DESTINATION_COUNTRY.KEY)}
      deliveryAddressKey={searchParams.get(FIELDS.ADDRESS_KEY.KEY)}
      errorComponent={error => (
        <ErrorBoundary config={getErrorBoundaryConfig[error.type]()} />
      )}
    >
      {shipmentDto => (
        <Navigate to={ORDER} state={{ shipment: shipmentDto }} replace />
      )}
    </Loader>
  );
};

export default Quotes;
