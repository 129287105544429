import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { PAGINATIONS, STRINGS } from '../../constants';
import useErrorHandler from '../../hooks/useErrorHandler';
import { commodityApis } from '../../apis';
import CommodityList from './components/CommodityList';
import CommoditySearch from './components/CommoditySearch';

const CommodityFinderModal = ({
  open,
  onClose,
  setCommoditySelected,
  productDescription,
  deliveryCountryCode,
  setIsValid,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [commodities, setCommodities] = useState({});
  const [levels, setLevels] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchValue, setSearchValue] = useState(productDescription || '');
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const { errorHandler } = useErrorHandler();
  const theme = useTheme();

  const searchPageSize = PAGINATIONS.DEFAULT_SEARCH_MODAL_SIZE;
  const isSearchDisabled =
    !searchValue.trim() || (searchSubmitted && totalResults === 0);

  const fetchCommodities = async (
    args,
    { isCodeSearch = false, shouldAppend = false }
  ) => {
    try {
      setIsLoadingMore(true);

      const { data } = isCodeSearch
        ? await commodityApis.getCommoditiesByCode(args)
        : await commodityApis.getCommodities(args);

      setCommodities(prev => {
        const newCommodities = isCodeSearch
          ? data?.result?.reduce((acc, item) => {
              acc[item.code] = item;
              return acc;
            }, {})
          : data?.result?.commodities || {};

        return shouldAppend ? { ...prev, ...newCommodities } : newCommodities;
      });

      setLevels(prev => {
        const newLevels = isCodeSearch ? [] : data?.result?.levels || [];
        return shouldAppend ? [...prev, ...newLevels] : newLevels;
      });

      setCurrentPage(prev => (shouldAppend ? prev + 1 : 1));
      setTotalResults(data?.totalResults || 0);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleLoadMore = async () => {
    if (isLoadingMore) {
      return;
    }

    const args = {
      searchPage: currentPage + 1,
      searchPageSize,
      searchString: searchValue.trim(),
    };

    await fetchCommodities(args, { shouldAppend: true });
  };

  const searchCommodities = async ({ commodityCode, searchString }) => {
    const args = {
      searchPage: 1,
      searchPageSize,
    };

    if (commodityCode) {
      args.commodityCode = commodityCode.trim();
      await fetchCommodities(args, { isCodeSearch: true });
      setSearchSubmitted(false);
    } else if (searchString) {
      args.searchString = searchString.trim();
      await fetchCommodities(args, { isCodeSearch: false });
      setSearchSubmitted(true);
    }
  };

  const handleFormSubmit = values => {
    const { commodity } = values;

    if (!commodity) {
      return;
    }

    setSearchValue(commodity);
    setIsValid(true);
    searchCommodities({ searchString: searchValue });
  };

  useEffect(() => {
    if (open && productDescription?.trim()) {
      setSearchValue(productDescription);
      searchCommodities({ searchString: productDescription });
    }
  }, [open, productDescription]);

  const clearInput = () => {
    setSearchValue('');
    setCommodities({});
    setLevels([]);
    setSearchSubmitted(false);
  };

  const handleClose = () => {
    onClose();
    setCurrentPage(1);
    clearInput();
  };

  const handleChange = useCallback(
    (e, input) => {
      input.onChange(e);
      setSearchValue(e.target.value);
      if (e.target.value.trim() === '') {
        setCommodities({});
        setLevels([]);
      }
      if (searchSubmitted) {
        setSearchSubmitted(false);
      }
    },
    [searchSubmitted]
  );

  const hasMore =
    totalResults && Object.keys(commodities).length < totalResults;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.contrastText,
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '75%',
          },
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.primary.contrastText,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography variant='h3'>
              {STRINGS.COMMODITY_FINDER.TITLE}
            </Typography>
            <IconButton
              aria-label='close'
              sx={{ mr: 0.5 }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <CommoditySearch
            onSubmit={handleFormSubmit}
            searchValue={searchValue}
            disabled={isSearchDisabled}
            clearInput={clearInput}
            handleChange={handleChange}
          />
          <Divider />
        </Box>
        <Box sx={{ padding: 2 }}>
          <CommodityList
            onClose={onClose}
            pristine={!searchSubmitted}
            commodities={commodities}
            levels={levels}
            setCommoditySelected={setCommoditySelected}
            deliveryCountryCode={deliveryCountryCode}
            setIsValid={setIsValid}
            searchCommodities={searchCommodities}
          />
          {!!(searchValue && commodities && searchSubmitted && hasMore) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant='outlined'
                onClick={handleLoadMore}
                disabled={isLoadingMore}
                sx={{ marginTop: 2 }}
              >
                {STRINGS.COMMODITY_FINDER.LOAD_MORE}
              </Button>
            </Box>
          )}
        </Box>
        <Grid
          container
          justifyContent='center'
          sx={{ padding: '8px 16px 16px', position: 'relative' }}
        >
          <Divider sx={{ position: 'absolute', width: '100%', top: '0' }} />
        </Grid>
      </Box>
    </Modal>
  );
};

CommodityFinderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setCommoditySelected: PropTypes.func,
  productDescription: PropTypes.string,
  deliveryCountryCode: PropTypes.string,
  setIsValid: PropTypes.func,
};

export default CommodityFinderModal;
