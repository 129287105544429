import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import QuoteForm from '../../components/QuoteForm';
import bgMain from '../../assets/images/homeBgMain.png';
import bgMainMobile from '../../assets/images/homeBgMainMobile.png';
import homeImg1 from '../../assets/images/homeImg1.png';
import homeImg2 from '../../assets/images/homeImg2.png';
import {
  EASY_INTENATIONAL_SHIPPING,
  FREE_LABEL_PRINTING_DESCRIPTION,
  GO_GLOBAL,
  INTERNATION_SHIPPING_DESCRIPTION,
  LABEL_FREE_DROP_OFF,
  NO_PRINTER_NO_PROBLEM,
  SEND_PARCEL,
  SEND_PARCEL_DESCRIPTION,
  SHIP_ANYWHERE_CHOSE_DPD,
  SHIP_PRICE_FROM,
} from '../../constants/strings';
import { HOME } from '../../constants/analytics';
import { countriesSelectors } from '../../redux/countriesSlice';
import { FIELDS } from '../../constants/forms';
import useBreakpoint from '../../hooks/useBreakpoint';
import useAnalytics from '../../hooks/useAnalytics';

function Home() {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const countries = useSelector(countriesSelectors.getCountries);
  const isSmallScreen = useBreakpoint('md');

  useEffect(() => {
    setInterfaceId(HOME.INTERFACE_ID);
  }, []);

  const handleQuoteFormSubmit = useCallback(
    values => {
      navigate(
        `/quotes?${FIELDS.DESTINATION_COUNTRY.KEY}=${
          values[FIELDS.DESTINATION_COUNTRY.KEY]
        }&${FIELDS.ADDRESS_KEY.KEY}=${values[FIELDS.ADDRESS_KEY.KEY]}`
      );
    },
    [navigate]
  );

  const sendParcelSection = (
    <Box
      sx={{
        position: { sx: 'relative', md: 'absolute' },
        bottom: 0,
        width: { xs: '100%', md: '50%' },
        display: 'flex',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.main',
          display: 'flex',
          justifyContent: 'space-between',
          paddingX: 5,
          paddingY: 4,
          flexDirection: { xs: 'column', md: 'row' },
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Typography variant='h1' sx={{ color: 'primary.contrastText' }}>
            {SEND_PARCEL}
          </Typography>
          <Box sx={{ width: { sx: '100%' }, mr: 2 }}>
            <Typography
              variant='subtitle1'
              sx={{ color: 'primary.contrastText' }}
            >
              {SHIP_PRICE_FROM}
            </Typography>
            <Typography
              variant={'body1'}
              sx={{ color: 'primary.contrastText' }}
            >
              {SEND_PARCEL_DESCRIPTION}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: { xs: 2, md: 9 },
            flexGrow: 1,
          }}
        >
          <QuoteForm countries={countries} onSubmit={handleQuoteFormSubmit} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Trackable loadId={HOME.LOAD}>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundImage: {
              xs: `url(${bgMainMobile})`,
              sm: `url(${bgMain})`,
            },
            backgroundSize: { xs: 'cover', md: 'contain' },
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 0,
            paddingTop: {
              xs: 'calc(225 / 390 * 100%)',
              sm: '700px',
              md: 'calc(500 / 1200 * 100%)',
            },
            position: 'relative',
            backgroundPosition: { sm: '50%', md: 'center' },
          }}
        >
          {!isSmallScreen && sendParcelSection}
        </Box>
        {isSmallScreen && sendParcelSection}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Box
            sx={{
              display: { xs: 'flex', md: 'block' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: '100%',
            }}
          >
            <img src={homeImg1} style={{ width: '100%', height: '100%' }} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ px: { xs: 4, md: 5 }, py: { xs: 5, md: 0 } }}>
              <Typography variant='h2' sx={{ py: 2 }}>
                {EASY_INTENATIONAL_SHIPPING}
              </Typography>
              <Typography variant='body1'>{GO_GLOBAL}</Typography>
              <Typography variant='body1'>
                {INTERNATION_SHIPPING_DESCRIPTION}
              </Typography>
              <Typography variant='body1'>{SHIP_ANYWHERE_CHOSE_DPD}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ width: '100%', flex: '1 1 0px' }}>
            <Box
              sx={{
                backgroundColor: 'primary.main',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ px: { xs: 4, md: 5 }, py: { xs: 5, md: 0 } }}>
                <Typography
                  variant='h2'
                  sx={{ color: 'primary.contrastText', mb: { xs: 1, md: 2 } }}
                >
                  {LABEL_FREE_DROP_OFF}
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'primary.contrastText' }}
                >
                  {NO_PRINTER_NO_PROBLEM}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ color: 'primary.contrastText' }}
                >
                  {FREE_LABEL_PRINTING_DESCRIPTION}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: { xs: '100%', md: '50%' },
            }}
          >
            <img src={homeImg2} style={{ width: '100%', flexGrow: 1 }} />
          </Box>
        </Box>
      </Box>
    </Trackable>
  );
}

export default Home;
