import moment from 'moment-timezone';
import { omit } from 'lodash';

import { FORM } from '../../constants';
import * as ParcelHelpers from '../../helpers/parcel';
import { DAYS_LIMIT_FOR_CLAIM_PROCESSING } from './constants';

export const isMissingFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.MISSING_CONTENTS;

export const isDamagedFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.DAMAGED;

export const isNotArrivedFlow = issueType =>
  issueType === FORM.ISSUE_TYPES.NOT_ARRIVED;

export const isClaimInvalid = deliveredDate => {
  const currentDate = moment();

  return (
    deliveredDate &&
    currentDate.diff(deliveredDate, 'days') > DAYS_LIMIT_FOR_CLAIM_PROCESSING
  );
};

export const getValidationContext = issueType => {
  if (!issueType) {
    return null;
  }

  return {
    isGoodsValueRequired: !isMissingFlow(issueType),
    isPackageDescriptionRequired: isNotArrivedFlow(issueType),
    isAdditionalInfoRequired: isNotArrivedFlow(issueType),
    isDamageDescriptionRequired: !isNotArrivedFlow(issueType),
    isMissingDescriptionRequired: isMissingFlow(issueType),
    isDamagedItemRequired: isDamagedFlow(issueType),
  };
};

export const resetFields = (fields, form) => {
  fields.forEach(field => {
    if (form.getFieldState(field)) {
      form.change(field, undefined);
      form.resetFieldState(field);
    }
  });
};

export const getClaimData = (values, parcel) => ({
  parcelCode: parcel?.parcelCode,
  postcode: parcel?.deliveryDetails?.address?.postcode,
  ...values,
});

export const normalizeClaimData = data => ({
  ...omit(
    data,
    'parcelNumber',
    'helpType',
    'postcodeOrOrderId',
    'contactUsType'
  ),
  ...ParcelHelpers.getPostcodeOrOrderId(data.postcodeOrOrderId),
  reasonForContact: data.reasonForContact || data.contactUsType,
  attachedImages:
    data.attachedImages?.map(image => ({
      image: image.image,
      fileName: image.fileName,
      contentType: image.contentType,
    })) || [],
});

export const getIsChatAvailable = config => {
  if (!config) {
    return false;
  }
  const { openTime, closeTime, botAvailable, advisorAvailable } = config;
  const currentDate = moment();
  const startWindow = moment(openTime, 'HH:mm');
  const endWindow = moment(closeTime, 'HH:mm');
  const isOutOfHours =
    currentDate.isBefore(startWindow) || currentDate.isAfter(endWindow);

  return !isOutOfHours && botAvailable === 'Y' && advisorAvailable;
};
