import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Pagination,
  Typography,
} from '@mui/material';
import { PAGINATIONS, STRINGS, TABLES } from '../../constants';
import addressBooksSlice from '../../redux/addressBooksSlice';
import {
  AddressBookSearch,
  AddressBookTable,
} from '../../pages/UserPreferences/AddressesSection/components';
import { useAuth } from '../../features/Auth';
import useErrorHandler from '../../hooks/useErrorHandler';
import useBreakpoint from '../../hooks/useBreakpoint';
import useAddressBookSearch from '../../hooks/useAddressBookSearch';

const AddressBookModal = ({ onSelect, config, sx }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const {
    currentSession: { uid },
  } = useAuth();
  const { customization } = { ...config };

  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const searchPageSize = PAGINATIONS.DEFAULT_SEARCH_MODAL_SIZE;

  const isSmallScreen = useBreakpoint('md');
  const {
    isSearchMode,
    searchString,
    searchTrigger,
    handleSearchChange,
    handleSearchSubmit,
    resetSearch,
  } = useAddressBookSearch({
    setCurrentPage,
  });

  const addressBooks = useSelector(
    addressBooksSlice.selectors.getAddressBooksByPage
  );

  const addressBooksLength = useSelector(
    addressBooksSlice.selectors.getAddressBookLength
  );

  const hasDefaultAddressBook = !!useSelector(
    addressBooksSlice.selectors.getDefaultAddressBook
  );

  useEffect(() => {
    const fetchAddressBooks = async () => {
      let data;
      if (uid) {
        try {
          if (isSearchMode) {
            data = await dispatch(
              addressBooksSlice.actions.searchAddressBooks({
                searchPage: currentPage,
                searchPageSize,
                searchString,
              })
            ).unwrap();
          } else {
            data = await dispatch(
              addressBooksSlice.actions.fetchAddressBooks({
                searchPage: currentPage,
                searchPageSize,
              })
            ).unwrap();
          }

          setTotalPages(data.totalPages);
        } catch (error) {
          errorHandler(
            STRINGS.ADDRESSES_SECTION.NOTIFICATIONS.ERROR.FETCHING_ERROR,
            error
          );
        }
      }
    };

    fetchAddressBooks();
  }, [
    uid,
    isSearchMode,
    searchTrigger,
    setTotalPages,
    dispatch,
    currentPage,
    searchPageSize,
  ]);

  const isAddressBooksButtonAvailable = !!(
    uid &&
    (addressBooksLength || hasDefaultAddressBook)
  );

  return isAddressBooksButtonAvailable ? (
    <>
      <Grid container justifyContent='space-between' sx={{ gap: 2, ...sx }}>
        <Button
          id='choose-destination-address-button'
          variant='outlined'
          onClick={() => setIsOpen(true)}
          sx={{ alignSelf: 'flex-end' }}
        >
          {STRINGS.CHOOSE_FROM_ADDRESS_BOOK}
        </Button>
      </Grid>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setCurrentPage(1);
          resetSearch();
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'primary.contrastText',
            position: 'relative',
            m: { sm: '10% auto' },
            borderRadius: 1,
            maxWidth: { sm: 600, md: 800 },
            height: {
              xs: '100%',
              sm: 'auto',
            },
            maxHeight: {
              //md: '55%',
            },
            minHeight: {
              // md: '50%',
            },
            overflowY: {
              xs: 'scroll',
              sm: 'auto',
              md: 'hidden',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h3' sx={{ p: 2 }}>
              {STRINGS.ADDRESS_BOOK_MODAL.TITLE}
            </Typography>
            <IconButton
              aria-label='close'
              sx={{ mr: 0.5 }}
              onClick={() => {
                setIsOpen(false);
                setCurrentPage(1);
                resetSearch();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, overflowY: 'auto' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Grid item sx={{ maxWidth: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    overflowY: 'hidden',
                  }}
                >
                  <Divider />
                  <Box sx={{ padding: 2, overflowX: 'auto' }}>
                    <AddressBookSearch
                      inputWidth={isSmallScreen ? '168px' : '220px'}
                      searchString={searchString}
                      onSearchChange={handleSearchChange}
                      onSearchSubmit={handleSearchSubmit}
                    />
                    <AddressBookTable
                      rows={addressBooks}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      onSelect={selectedAddresses => {
                        setIsOpen(false);
                        onSelect(selectedAddresses);
                        setCurrentPage(1);
                      }}
                      searchPageSize={searchPageSize}
                      isSearchMode={isSearchMode}
                      resetSearch={resetSearch}
                      config={TABLES.ADDRESS_BOOK_MODAL_CONFIG}
                      styles={{
                        head: { position: 'sticky' },
                        container: { maxHeight: 270, overflowY: 'auto' },
                      }}
                      customization={customization}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '8px 16px 16px',
                    bottom: '0',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Divider
                    sx={{ position: 'absolute', width: '100%', top: '0' }}
                  />
                  <Pagination
                    page={Number(currentPage) || 1}
                    count={totalPages || 1}
                    siblingCount={0}
                    shape='rounded'
                    showFirstButton
                    showLastButton
                    onChange={(_, page) => setCurrentPage(page)}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  ) : null;
};

AddressBookModal.propTypes = {
  sx: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  config: PropTypes.shape({
    customization: PropTypes.shape({
      rowStyle: PropTypes.func,
    }),
  }),
};

export default AddressBookModal;
