import { useCallback, useState } from 'react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { cloneDeep, get, round, set } from 'lodash';
import { HttpStatusCode } from 'axios';

import { format } from 'date-fns';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { PAYMENT } from '../../../../constants/analytics';
import PurchaseWidget from '../../../../components/PurchaseWidget/PurchaseWidget';
import useAnalytics from '../../../../hooks/useAnalytics';
import { SummaryDetails } from '../../../../components/SummaryDetails/index';
import SalesConditions from '../../../../components/SalesConditions/index';
import InformationCollecting from '../../../../components/InformationCollecting';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import * as OrderSelectors from '../../selectors';
import { BasketActions, BasketSelectors } from '../../../../redux/basket';
import configSlice from '../../../../redux/configSlice';
import { useOverlay } from '../../../../features/Overlay';
import Voucher from '../../../../features/Voucher';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import useGetBillingAddress from '../../../../hooks/useGetBillingAddress';
import ParcelInsuranceSection from '../../../../components/ParcelInsuranse';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import { validateFullShipmentSync } from '../../helpers/shipment';
import { POINT_TYPE, STRINGS } from '../../../../constants';
import { validateCollectionDateAsync } from '../../../Basket/slice';
import { NetworkHelpers, ShipmentHelpers } from '../../../../helpers';
import { useOrderSnackbar, useReferencesLoader } from '../../hooks';
import Loader from '../../../../features/Loader';
import PaymentCard from './components/PaymentCard';
import * as StepSelectors from './selectors';

const Payment = ({
  goToNamedStep,
  values,
  references,
  setValues,
  setReferences,
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const referencesLoader = useReferencesLoader();
  const [searchParams] = useSearchParams();
  const basketItemId = searchParams.get('basketItemId');
  const overlay = useOverlay();
  const snackbar = useOrderSnackbar();
  const isSmallScreen = useBreakpoint('md');
  const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(
    ShipmentHelpers.getSaebiFlags(values)
  );
  const hasInsurance = get(values, `${SHIPMENT_FIELDS.NETWORK.KEY}.insurance`);

  const basketId = useSelector(BasketSelectors.getBasketId);
  const threeDSecure = useSelector(StepSelectors.getThreeDSecureData);
  const price = useSelector(OrderSelectors.getTotalAmount);
  const countries = useSelector(countriesSelectors.getCountries);
  const defaultCutOff = useSelector(
    configSlice.selectors.getCollectionCutOffConfig
  );
  const basketItem = useSelector(state =>
    BasketSelectors.getBasketItemById(state, basketItemId)
  );

  const totalPriceValue = round(
    get(values, SHIPMENT_FIELDS.PARCELS.KEY, []).reduce(
      (totalValue, parcel) =>
        totalValue + Number(parcel._value) * Number(parcel._quantity),
      0
    )
  );

  const { terms: termsLink } = useSelector(
    configSlice.selectors.getLinksConfig
  );
  const { voucher: displayVoucher } = useSelector(
    configSlice.selectors.getFeatureConfig
  );

  const { Trackable, trackAction } = useAnalytics();

  const destinationCountryCode = get(
    values,
    SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
  );
  const deliveryCountryName = useSelector(state =>
    countriesSelectors.getCountryName(state, destinationCountryCode)
  );

  const updateValuesInBasketItem = useCallback(
    async newValues => {
      setValues(newValues);

      const basketItemId = searchParams.get('basketItemId');
      await dispatch(
        BasketActions.updateBasketItem({
          basketItemId: basketItemId,
          shipment: newValues,
        })
      ).unwrap();
    },
    [basketItemId]
  );

  const billingAddress = useGetBillingAddress(
    get(values, SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY, {}),
    get(values, SHIPMENT_FIELDS.COLLECTION_CONTACT_DETAILS.KEY, {})
  );

  const handleOnchangeInsurance = useCallback(
    async liabilityValue => {
      try {
        overlay.show();
        const newValues = cloneDeep(values);

        set(newValues, SHIPMENT_FIELDS.LIABILITY_VALUE.KEY, liabilityValue);
        await updateValuesInBasketItem(newValues);
      } catch (e) {
        snackbar.showError({
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [overlay, snackbar, values]
  );

  const handleCheckoutSubmit = useCallback(
    async payload => {
      const errors = validateFullShipmentSync(values, references, countries);

      if (errors.length) {
        snackbar.showError({
          autoHideDuration: null,
          // TODO: put correct message later
          message: 'Please verify your shipment details',
        });
        return;
      }

      if (
        !errors.includes(STRINGS.DATE_CANNOT_BE_IN_THE_PAST) &&
        get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR
      ) {
        const isValidCollectionDate = await dispatch(
          validateCollectionDateAsync({ values, defaultCutOff })
        ).unwrap();

        if (!isValidCollectionDate) {
          snackbar.showError({
            message: STRINGS.INVALID_COLLECTION_DATE,
          });
          return;
        }
      }

      try {
        overlay.show();
        const basketItemId = searchParams.get('basketItemId');
        const purchaseData = {
          amount: price.totalIncVatAmount,
          diaryDate: format(new Date(), 'dd/MM/yyyy'),
          paymentNonce: payload.nonce,
          vendorRef: payload.type,
          voucherId: references.voucher?.voucherId,
          billingAddress,
        };
        const {
          shipments: [shipment],
        } = await dispatch(
          BasketActions.checkout({
            basketItemIds: [basketItemId],
            purchaseData,
          })
        ).unwrap();

        snackbar.showSuccess({
          // put correct message later
          message: 'Shipment have been successfully created',
        });
        navigate(`/shipments/${shipment.shipmentId}`, { replace: true });
      } catch (error) {
        if (error.statusCode === HttpStatusCode.Forbidden) {
          snackbar.showSubmitError({
            originError: error,
            message: STRINGS.FAILED_TO_SAVE_BASKET,
          });
        } else {
          (error.details || [error]).forEach(detail => {
            snackbar.showError({
              autoHideDuration: null,
              message: detail.message,
            });
          });
        }
      } finally {
        overlay.hide();
      }
    },
    [
      overlay,
      searchParams,
      dispatch,
      basketId,
      snackbar,
      navigate,
      values,
      countries,
      price,
      references.voucher,
    ]
  );

  const handleBack = useCallback(
    event => {
      trackAction(event);
      if (isInvoiceAvailable) {
        goToNamedStep('customsDetails');
      } else {
        goToNamedStep('addressDetails');
      }
    },
    [goToNamedStep, trackAction]
  );

  const VoucherComponent = useCallback(
    sx =>
      displayVoucher && (
        <Box sx={sx}>
          <Voucher voucher={references.voucher} setReferences={setReferences} />
        </Box>
      ),
    [references.voucher]
  );

  const loadInitialData = useCallback(async () => {
    if (!references?.networks?.length) {
      await referencesLoader.loadNetworks(
        NetworkHelpers.getNetworkQuery(basketItem?.shipment)
      );
    }
  }, []);

  return (
    <Trackable loadId={PAYMENT.LOAD} interfaceId={PAYMENT.INTERFACE_ID}>
      <Loader promiseFn={loadInitialData}>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} md={8}>
            <PaymentCard shipment={values} references={references} />
            {isSmallScreen && VoucherComponent({ mt: 2 })}
            <Grid item xs={12} sx={{ mt: 2 }}>
              {hasInsurance && (
                <ParcelInsuranceSection
                  parcelValue={totalPriceValue}
                  selectedInsuranceValue={get(
                    values,
                    SHIPMENT_FIELDS.LIABILITY_VALUE.KEY
                  )}
                  onChangeInsurance={handleOnchangeInsurance}
                />
              )}
              <SalesConditions
                onAcceptTerms={setAcceptTerms}
                withProducts={ShipmentHelpers.isProductAvailable(values)}
                salesConditionsLink={termsLink}
                deliveryCountryName={deliveryCountryName}
                isAvailableProhibitedCondition={!isInvoiceAvailable}
              />
              <InformationCollecting
                sx={{
                  px: { xs: 3, md: 0 },
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
              <Box
                sx={{
                  pl: { xs: 3, md: 0 },
                  pr: { xs: 3, md: 0 },
                }}
              >
                <SummaryDetails
                  price={price}
                  basketItemsWithPrice={[{ shipment: values, price }]}
                />
                {!isSmallScreen && VoucherComponent()}
                <Box>
                  <Box
                    sx={{
                      gap: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      pb: 2,
                    }}
                  >
                    <Button
                      actionid={PAYMENT.CLICK_BACK}
                      variant='outlined'
                      onClick={handleBack}
                      sx={{ width: '100%' }}
                    >
                      {STRINGS.BACK}
                    </Button>
                    <Button
                      actionid={PAYMENT.CLICK_VIEW_CART}
                      variant='outlined'
                      onClick={() => {
                        navigate({
                          pathname: '/basket',
                          search:
                            references.voucher?.voucherId &&
                            createSearchParams({
                              voucherId: references.voucher?.voucherId,
                            }).toString(),
                        });
                      }}
                      sx={{ width: '100%' }}
                    >
                      {STRINGS.VIEW_CART}
                    </Button>
                  </Box>
                </Box>
                <PurchaseWidget
                  basketId={basketId}
                  threeDSecure={threeDSecure}
                  amount={price.totalIncVatAmount.toString()}
                  handleCheckout={handleCheckoutSubmit}
                  sx={{ width: '100%' }}
                  disabled={!basketId || !acceptTerms}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Loader>
    </Trackable>
  );
};

export default Payment;
