import { useEffect, useState } from 'react';

export const useLocationController = () => {
  const [permissionStatus, setPermissionStatus] = useState({
    granted: false,
    denied: false,
    prompt: false,
  });
  const [currentPosition, setCurrentPosition] = useState(null);
  const [error, setError] = useState(null);

  const PROMPT = 'prompt';
  const DENIED = 'denied';
  const GRANTED = 'granted';

  useEffect(() => {
    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            setCurrentPosition({ latitude, longitude });
            setError(null);
          },
          error => {
            console.error('Error fetching location:', error);
            setError('Unable to retrieve location');
            setCurrentPosition(null);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser.');
        setError('Geolocation not supported');
      }
    };

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permission => {
        setPermissionStatus({
          granted: permission.state === GRANTED,
          denied: permission.state === DENIED,
          prompt: permission.state === PROMPT,
        });

        if (permission.state === GRANTED) {
          getCurrentPosition();
        }

        permission.onchange = () => {
          setPermissionStatus({
            granted: permission.state === GRANTED,
            denied: permission.state === DENIED,
            prompt: permission.state === PROMPT,
          });
          if (permission.state === GRANTED) {
            getCurrentPosition();
          } else {
            setCurrentPosition(null);
          }
        };
      });
    } else {
      getCurrentPosition();
    }
  }, []);

  return { permissionStatus, currentPosition, error };
};
