import _ from 'lodash';

export function pickDeep(object, paths) {
  return paths.reduce((result, p) => {
    let path = p;
    let defaultValue;
    if (Array.isArray(p)) {
      [path, defaultValue] = p;
    }
    const value = _.get(object, path, defaultValue);
    if (value !== undefined) {
      _.set(result, path, value);
    }
    return result;
  }, {});
}

export function convertToNestedObject(obj) {
  return Object.keys(obj).reduce((result, key) => {
    _.set(result, key, obj[key]);
    return result;
  }, {});
}

export const convertObjectKeysToArray = requiredFields =>
  Object.entries(requiredFields).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(key);
    }

    return acc;
  }, []);

export const getLeafValues = obj => {
  let values = [];

  function traverse(current) {
    if (typeof current === 'object' && current !== null) {
      for (let key in current) {
        // eslint-disable-next-line no-prototype-builtins
        if (current.hasOwnProperty(key)) {
          traverse(current[key]);
        }
      }
    } else {
      values.push(current);
    }
  }

  traverse(obj);

  return values;
};

export const replaceEmptyStringToUndefined = obj =>
  _.mapValues(obj, value => {
    if (_.isString(value) && value.trim() === '') {
      value = undefined;
    } else if (value === null) {
      value = undefined;
    } else if (_.isPlainObject(value)) {
      value = replaceEmptyStringToUndefined(value);
    } else if (Array.isArray(value)) {
      value = value.map(item =>
        item === '' || item === null ? undefined : item
      );
    }

    return value;
  });

export const getValue = (object, path, defaultValue) => {
  const value = _.get(object, path);
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

/**
 * Converts a nested object into an array of [pathWithDotNotation, value] pairs.
 * @param {Object} obj - The nested object to flatten.
 * @param {string} [parentKey=''] - The base key for recursion (used internally).
 * @param {Array} [result=[]] - The accumulator array (used internally).
 * @returns {Array} - Array of [pathWithDotNotation, value] pairs.
 */
export const flattenObjectToDotNotation = (
  obj,
  parentKey = '',
  result = []
) => {
  for (const key in obj) {
    if (_.has(obj, key)) {
      const currentPath = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];

      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        // Recursive call for nested objects
        flattenObjectToDotNotation(value, currentPath, result);
      } else {
        // Push leaf node (key-value pair)
        result.push([currentPath, value]);
      }
    }
  }

  return result;
};
