import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import { HttpStatusCode } from 'axios';

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import PropTypes from 'prop-types';
import {
  APPLIED_VOUCHER_$,
  APPLY,
  ENTER_VOUCHER,
  INVALID_VOUCHER,
  VOUCHER_APPLIED,
  YOUR_VOUCHER,
} from '../../constants/strings';
import { validateWithJoi, voucherSchema } from '../../helpers/validators';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { vouchersApis } from '../../apis';
import { useReferencesLoader } from '../../pages/Order/hooks';
import { REFERENCE_NAME } from '../../pages/Order/constants';
import { StringUtil } from '../../utils';

const formInitialValue = { voucherCode: '' };

const Voucher = ({ voucher, setReferences }) => {
  const [formValues, setFormValues] = useState(formInitialValue);
  const [errors, setErrors] = useState({});
  const [responseErrors, setResponseErrors] = useState();
  const voucherCode = formValues?.voucherCode;
  const snackbar = useCustomSnackbar();
  const { errorHandler } = useErrorHandler();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const referencesLoader = useReferencesLoader();

  const isApplyDisabled =
    voucher ||
    !!validateWithJoi(formValues, voucherSchema).voucherCode ||
    !!responseErrors;

  const handleVoucherApply = useCallback(
    voucher => {
      setReferences(REFERENCE_NAME.VOUCHER, voucher);
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          ...Object.fromEntries(searchParams),
          voucherId: voucher.voucherId,
        }).toString(),
      });
    },
    [searchParams, setReferences, navigate]
  );

  const onApplyVoucher = async () => {
    try {
      const data = await vouchersApis.getVoucherByCode(voucherCode);
      snackbar.showSuccess({
        message: VOUCHER_APPLIED,
        variant: 'success',
      });
      setFormValues(formInitialValue);
      handleVoucherApply(data);
    } catch (error) {
      if (
        error.response.status === HttpStatusCode.NotFound ||
        error.response.status === HttpStatusCode.BadRequest
      ) {
        const errorMessage = get(
          error,
          'response.data.error.message',
          INVALID_VOUCHER
        );
        errorHandler(errorMessage, error);
        setResponseErrors(errorMessage);
      }
    }
  };

  const onBlur = () => {
    const newFormValues = {
      ...formValues,
      voucherCode: StringUtil.trimAllSpaces(voucherCode),
    };

    const validationErrors = validateWithJoi(newFormValues, voucherSchema);

    setFormValues(newFormValues);
    setErrors(validationErrors);
  };

  const onFieldChange = () => {
    const { name, value } = event.target;

    const newFormValues = {
      ...formValues,
      [name]: value,
    };

    setResponseErrors(null);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    if (searchParams.get('voucherId')) {
      referencesLoader.loadVoucher();
    }
  }, []);

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant='h3' sx={{ width: '100%', marginBottom: '16px' }}>
        {YOUR_VOUCHER}
      </Typography>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: 'flex', height: '40px' }}>
          <TextField
            name='voucherCode'
            placeholder={ENTER_VOUCHER}
            fullWidth={true}
            inputProps={{
              style: {
                padding: '9px 14px',
              },
            }}
            sx={{ marginRight: '16px' }}
            onChange={onFieldChange}
            onBlur={onBlur}
            onFocus={() => setErrors({})}
            error={voucherCode && !!(errors.voucherCode || responseErrors)}
            value={voucherCode}
          />
          <Button
            type='submit'
            variant={isApplyDisabled ? 'outlined' : 'contained'}
            disabled={isApplyDisabled}
            onClick={onApplyVoucher}
          >
            {APPLY}
          </Button>
        </Box>
        <Box
          sx={{
            fontSize: '12px',
            lineHeight: '1.5',
            mt: 1,
            color: theme.palette.error.main,
          }}
        >
          {voucherCode && (errors.voucherCode || responseErrors)}
        </Box>
      </Box>
      {voucher && (
        <Grid
          container
          xs={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Grid item xs={10}>
            <Typography
              color='primary.main'
              sx={{ wordWrap: 'break-word', maxWidth: '100%' }}
            >
              {APPLIED_VOUCHER_$(voucher.voucherCode)}
            </Typography>
          </Grid>
          <Grid item xs={2} justifyContent='center' alignItems='center'>
            <IconButton
              sx={{
                color: 'text.secondary',
                '&.MuiIconButton-root:hover': { bgcolor: 'transparent' },
                py: 0,
              }}
              onClick={referencesLoader.deleteVoucher}
            >
              <Delete sx={{ color: 'primary.main', fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

Voucher.propTypes = { setReferences: PropTypes.func };

export default Voucher;
