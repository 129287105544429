import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Link,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunicationPreferences,
  isAccountDetailsLoading,
  updateCommunicationPreferences,
} from '../../redux/userSlice';
import {
  COMMUNICATION_PREFERENCES_SECTION,
  SALES_CONDITIONS,
} from '../../constants/strings';
import { ACCOUNT_DETAILS_FIELDS } from '../../constants/forms';
import { useAuth } from '../../features/Auth';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import ProhibitedModal from '../ProhibitedModal';
import DutyAndTaxInformation from './components/DutyAndTaxInformation';

const StyledTypography = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.4px',
}));

const StyledBox = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const SalesConditions = ({
  onAcceptTerms,
  salesConditionsLink = '#',
  deliveryCountryName,
  isAvailableProhibitedCondition,
  withProducts,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { showError, showDefault } = useCustomSnackbar();

  const { currentSession } = useAuth();
  const notifyByEmailUpdateLoading = useSelector(isAccountDetailsLoading);
  const { notifyByEmail } = useSelector(getCommunicationPreferences);

  const onChangeNotifyByEmail = useCallback(async ({ target }) => {
    if (target) {
      try {
        await dispatch(
          updateCommunicationPreferences({ [target.name]: target.checked })
        ).unwrap();

        showDefault({
          message: COMMUNICATION_PREFERENCES_SECTION.SNACKBAR_MESSAGES.SUCCESS,
        });
      } catch (e) {
        showError({
          message: COMMUNICATION_PREFERENCES_SECTION.SNACKBAR_MESSAGES.FAILED,
        });
      }
    }
  }, []);

  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedProhibitedItem, setCheckedProhibitedItem] = useState(false);
  const [isOpenProhibitedModal, setIsOpenProhibitedModal] = useState(false);

  useEffect(() => {
    onAcceptTerms(
      (checkedProhibitedItem || !isAvailableProhibitedCondition) && checkedTerms
    );
  }, [checkedTerms, checkedProhibitedItem]);

  return (
    <>
      <Box>
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant='h3'>{SALES_CONDITIONS.TITLE}</Typography>
          <StyledBox sx={{ mt: 2 }}>
            <Checkbox
              checked={checkedTerms}
              onChange={event => setCheckedTerms(event.target.checked)}
              sx={{ color: 'primary.main' }}
            />
            <StyledTypography variant='body1'>
              {SALES_CONDITIONS.ACCEPT_THE}
              <Link
                href={salesConditionsLink}
                sx={{
                  textDecoration: 'none',
                }}
                target='_blank'
                rel='nofollow noopener noreferrer'
              >
                {SALES_CONDITIONS.TERMS_AND_CONDITIONS}
              </Link>
            </StyledTypography>
          </StyledBox>
          {isAvailableProhibitedCondition && (
            <StyledBox>
              <Checkbox
                checked={checkedProhibitedItem}
                onChange={event =>
                  setCheckedProhibitedItem(event.target.checked)
                }
                sx={{ color: 'primary.main' }}
              />
              <StyledTypography variant='body1'>
                {SALES_CONDITIONS.CONFIRM_THAT_MY_SHIPMENT}
                <StyledTypography
                  variant='text'
                  onClick={() => setIsOpenProhibitedModal(true)}
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.primary.main,
                  }}
                >
                  {SALES_CONDITIONS.PROHIBITED_ITEMS}
                </StyledTypography>
              </StyledTypography>
            </StyledBox>
          )}
          {currentSession?.uid && (
            <StyledBox>
              <Checkbox
                name={
                  ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.EMAIL.NAME
                }
                disabled={notifyByEmailUpdateLoading}
                onChange={onChangeNotifyByEmail}
                defaultChecked={notifyByEmail}
                sx={{ color: 'primary.main' }}
              />
              <StyledTypography variant='body1'>
                {SALES_CONDITIONS.IF_YOU_LIKE_TO_RECEIVE}
              </StyledTypography>
            </StyledBox>
          )}
          {withProducts && (
            <DutyAndTaxInformation destinationCountry={deliveryCountryName} />
          )}
        </Paper>
      </Box>
      <ProhibitedModal
        isOpen={isOpenProhibitedModal}
        onClose={() => setIsOpenProhibitedModal(false)}
      />
    </>
  );
};

SalesConditions.propTypes = {
  onAcceptTerms: PropTypes.func.isRequired,
  salesConditionsLink: PropTypes.string.isRequired,
  isAvailableProhibitedCondition: PropTypes.bool,
  withProducts: PropTypes.bool.isRequired,
};

export default SalesConditions;
