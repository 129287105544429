import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { USER_PREFERENCES_SECTIONS } from '../../constants/strings';

import useAnalytics from '../../hooks/useAnalytics';
import { USER_PREFERENCES } from '../../constants/analytics';
import { SideMenu } from './components';

const UserPreferences = () => {
  const { setInterfaceId, Trackable } = useAnalytics();

  const userPreferencesSectionsValues = Object.values(
    USER_PREFERENCES_SECTIONS
  );

  useEffect(() => {
    setInterfaceId(USER_PREFERENCES.INTERFACE_ID);
  }, []);

  return (
    <Trackable loadId={USER_PREFERENCES.LOAD}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid
            item
            sx={{ position: { xs: 'absolute', md: 'static' } }}
            xs={12}
            md={3}
            lg={2}
          >
            <SideMenu menuItems={userPreferencesSectionsValues} />
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Trackable>
  );
};

export default UserPreferences;
