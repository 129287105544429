import { round } from 'lodash';
import { NormalizerHelpers, ShipmentHelpers } from '../../../../helpers';
import { FORM, STRINGS } from '../../../../constants';
import { ObjectUtil, StringUtil } from '../../../../utils';
import { MAX_NO_OF_PRODUCTS, MAX_VALUE_OF_PRODUCTS } from './constants';

export const calculateTotalWeight = product => {
  const quantity = Number(ObjectUtil.getValue(product, 'productQty', 1));
  const weight = Number(ObjectUtil.getValue(product, 'unitWeight', 0));

  return round(weight * quantity, 2);
};

export const calculateTotalValue = product => {
  const quantity = Number(ObjectUtil.getValue(product, 'productQty', 1));
  const value = Number(ObjectUtil.getValue(product, 'unitValue', 0));

  return round(value * quantity, 2);
};

export const calculateTotal = (products = []) =>
  products?.reduce(
    (acc, product) => {
      const quantity = Number(ObjectUtil.getValue(product, 'productQty', 0));
      const totalWeight = calculateTotalWeight(product);
      const totalValue = calculateTotalValue(product);
      return {
        quantity: acc.quantity + quantity,
        weight: round(acc.weight + totalWeight, 2),
        value: round(acc.value + totalValue, 2),
      };
    },
    {
      quantity: 0,
      weight: 0,
      value: 0,
    }
  );

export const getFormattedTotal = (products = []) => {
  const total = calculateTotal(products);
  return {
    ...total,
    weight: NormalizerHelpers.addTrailingZeros(total.weight),
    value: NormalizerHelpers.addTrailingZeros(total.value),
  };
};

export const getProductAvailableFields = values => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
  const isDescriptionOnly = ShipmentHelpers.isFlagDescription(
    saebiFlags,
    FORM.NETWORK_FIELDS.PROD_REQUIRED
  );

  return {
    isDescriptionOnly,
    [FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY]:
      !isDescriptionOnly &&
      !ShipmentHelpers.isFlagNeedles(
        saebiFlags,
        FORM.NETWORK_FIELDS.COUNTRY_OF_ORIGIN
      ),
  };
};

export const getProductDataErrors = (
  currentProduct,
  products,
  totalWeight,
  customsValue
) => {
  const errors = {};
  const totalValue = Number(customsValue) || MAX_VALUE_OF_PRODUCTS;
  const productQuantity = Number(
    ObjectUtil.getValue(currentProduct, 'productQty', 0)
  );
  const productWeight = calculateTotalWeight(currentProduct);
  const productValue = calculateTotalValue(currentProduct);
  const restOfProducts = products.filter(
    product => product._id !== currentProduct._id
  );
  const total = calculateTotal(restOfProducts);

  if (
    totalWeight &&
    productWeight &&
    productWeight + total.weight > totalWeight
  ) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.KEY] =
      STRINGS.SHIPMENT_TOTAL_WEIGHT_ERROR;
  }

  if (
    productQuantity &&
    productQuantity + total.quantity > MAX_NO_OF_PRODUCTS
  ) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY] =
      StringUtil.formatMessage(
        STRINGS.SHIPMENT_TOTAL_NUMBER_ERROR,
        MAX_NO_OF_PRODUCTS
      );
  }

  if (productValue && productValue + total.value > totalValue) {
    errors[FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.KEY] =
      StringUtil.formatMessage(
        STRINGS.SHIPMENT_TOTAL_VALUE_ERROR,
        NormalizerHelpers.addTrailingZeros(totalValue)
      );
  }

  return errors;
};

export const getProductRowBgColor = (theme, isSelected, isInvalid) => {
  switch (true) {
    case isInvalid && isSelected:
      return theme.palette.primary._states.focusVisible;
    case isInvalid:
      return theme.palette.primary._states.selected;
    case isSelected:
      return theme.palette.primary.blue;
    default:
      return 'inherit';
  }
};
