import { HttpStatusCode } from 'axios';
import { useCallback } from 'react';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../ErrorBoundary';

const getLoaderErrorBoundaryType = error => {
  if (
    error?.status === HttpStatusCode.Forbidden ||
    error?.statusCode === HttpStatusCode.Forbidden
  ) {
    return ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS_LOGGED;
  } else if (
    error?.status === HttpStatusCode.Unauthorized ||
    error?.statusCode === HttpStatusCode.Unauthorized
  ) {
    return ERROR_BOUNDARY_TYPES.DONT_HAVE_ACCESS;
  }
  return ERROR_BOUNDARY_TYPES.LOADING_CRASH;
};

const Rejected = ({ error, component }) => {
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();

  const errorComponent = useCallback(
    error => (
      <ErrorBoundary
        config={getErrorBoundaryConfig[getLoaderErrorBoundaryType(error)](
          error?.message
        )}
      />
    ),
    [getErrorBoundaryConfig]
  );

  return component ? component(error) : errorComponent(error);
};

export default Rejected;
