import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

import { combineAppReducer as chatReducers } from '@dpdgroupuk/mydpd-chat';
import { sentryReduxEnhancer } from '../sentry';
import { AuthReducer } from '../features/Auth';
import { VerifyEmailReducer } from '../pages/Auth/SignIn/slice';
import { SignUpSliceReducer } from '../pages/Auth/SignUp/slice';
import { OrderReducer } from '../pages/Order/slice';
import BasketViewSlice from '../pages/Basket/slice';
import AnalyticsReducer from '../features/Analytics/slice';
import addressBooksSlice from './addressBooksSlice';
import parcelsSlice from './parcelsSlice';
import accountDetails from './userSlice';
import { countriesReducer } from './countriesSlice';
import { ExportReasonsReducer } from './exportReasonSlice';
import { NiRequiredFieldsReducer } from './niRequiredFieldsSlice';
import { networksReducer } from './networksSlice';
import { BasketReducer } from './basket';
import configSlice from './configSlice';
import parcelSlice from './parcelSlice';

const isDevMode = import.meta.env.NODE_ENV !== 'production';

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // Persist only these reducers data
  whitelist: ['basket', 'orders', 'countries'],
};

const appReducer = combineReducers({
  auth: AuthReducer,
  verifyEmail: VerifyEmailReducer,
  order: OrderReducer,
  analytics: AnalyticsReducer,
  addressBook: addressBooksSlice.reducer,
  parcels: parcelsSlice.reducer,
  parcel: parcelSlice.reducer,
  accountDetails: accountDetails.reducer,
  countries: countriesReducer,
  exportReasons: ExportReasonsReducer,
  niRequiredFields: NiRequiredFieldsReducer,
  networks: networksReducer,
  basket: BasketReducer,
  basketView: BasketViewSlice.reducer,
  config: configSlice.reducer,
  signUp: SignUpSliceReducer,
  chat: chatReducers({}),
});

const rootReducer = (state, action) => {
  if (
    [
      'auth/logoutSession/fulfilled',
      'auth/logoutSession/rejected',
      'user/delete/fulfilled',
      'auth/clear',
    ].includes(action.type)
  ) {
    // Return undefined to reset the state to initial values (or provide custom logic)
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logoutMiddleware = () => next => action => {
  if (
    [
      'auth/logoutSession/fulfilled',
      'user/delete/fulfilled',
      'auth/clear',
    ].includes(action.type)
  ) {
    persistor.purge(['basket', 'orders']);
  }

  return next(action);
};

const stateSyncMiddleware = createStateSyncMiddleware({
  predicate: action => {
    // Sync state only for the selected actions
    const actionPrefixes = ['auth/'];

    return actionPrefixes.some(actionPrefix =>
      action.type.includes(actionPrefix)
    );
  },
  broadcastChannelOption: { type: 'localstorage' },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(stateSyncMiddleware)
      .concat(logoutMiddleware),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  devTools: isDevMode,
});

// Initialize the message listener for syncing between tabs
initMessageListener(store);

export const persistor = persistStore(store);
