import { CONFIRMATION } from '../../constants/strings';
import { formattedDayOfTheWeekDayMonthShipmentDate } from '../../utils/dateAndTime';
import {
  HomeCollection,
  LabelDropOff,
  LabelPack,
  LabelPrint,
  Receipt,
} from '../../components/icons';
import { qrIMG } from '../../assets/images';

export const DROP_OFF_STEPS_CONFIG = [
  {
    id: 1,
    icon: LabelPrint,
    text: CONFIRMATION.STEPS.PACK_ITEM,
  },
  {
    id: 2,
    icon: LabelPack,
    text: CONFIRMATION.STEPS.PRINT_LABEL,
  },
  {
    id: 3,
    icon: LabelDropOff,
    text: CONFIRMATION.STEPS.BRING_PARCEL_TO_DROPOFF,
  },
];

export const DIGITAL_INFORMATION_STEPS_CONFIG = [
  {
    id: 1,
    icon: LabelPrint,
    text: CONFIRMATION.STEPS.TAKE_PARCEL_TO_PICKUP,
  },
  {
    id: 2,
    icon: () => (
      <img src={qrIMG} alt={'qrcode icon'} style={{ padding: '5px' }} />
    ),
    text: CONFIRMATION.STEPS.PRESENT_QR_CODE,
  },
  {
    id: 3,
    icon: LabelPack,
    text: CONFIRMATION.STEPS.ASSISTANT_SCAN,
  },
  {
    id: 4,
    icon: Receipt,
    text: CONFIRMATION.STEPS.RECEIVE_RECEIPT,
  },
];

export const COLLECTION_STEPS_CONFIG = (shipment, dropAtShop) => [
  {
    id: 1,
    icon: LabelPrint,
    text: CONFIRMATION.STEPS.PACK_ITEM,
  },
  {
    id: 2,
    icon: LabelPack,
    text: CONFIRMATION.STEPS.PRINT_LABEL,
  },
  {
    id: 3,
    icon: dropAtShop ? LabelDropOff : HomeCollection,
    text: dropAtShop ? (
      CONFIRMATION.STEPS.BRING_PARCEL_TO_DROPOFF
    ) : (
      <>
        {shipment?.shipmentDate &&
          formattedDayOfTheWeekDayMonthShipmentDate(shipment?.shipmentDate)}
        {shipment?.shipmentDate && <br />}
        {CONFIRMATION.STEPS.DRIVER_COLLECT}
      </>
    ),
  },
];
