import { Box, Typography } from '@mui/material';
import FacePensiveIcon from '../../../components/icons/FacePensiveIcon';
import { CHAT_UNAVAILABLE } from '../../../constants/strings';

const ChatUnavailable = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      gap: 1,
      my: 5,
    }}
  >
    <FacePensiveIcon />
    <Typography variant='h3'>{CHAT_UNAVAILABLE}</Typography>
  </Box>
);

export default ChatUnavailable;
