import { useSelector } from 'react-redux';
import addressBooksSlice from '../redux/addressBooksSlice';

const useGetBillingAddress = (collectionAddress, contactDetails) => {
  const billingAddress = useSelector(
    addressBooksSlice.selectors.getBillingAddressBook
  );
  const defaultAddress = useSelector(
    addressBooksSlice.selectors.getDefaultAddressBook
  );

  const {
    organisation = '',
    street,
    locality = '',
    town,
    county = '',
    postcode = '',
    countryCode,
  } = collectionAddress;

  const { telephone, email = '', contactName } = contactDetails;

  if (billingAddress) {
    return getBillingAddressFromAddressBook(billingAddress);
  }

  if (defaultAddress) {
    return getBillingAddressFromAddressBook(defaultAddress);
  }

  return {
    company: organisation,
    street,
    locality,
    town,
    county,
    postcode,
    countryCode,
    phone: telephone,
    email,
    contactName,
  };
};

const getBillingAddressFromAddressBook = ({
  organisation = '',
  addressLine1,
  addressLine2 = '',
  addressLine3,
  addressLine4 = '',
  postcode = '',
  countryCode,
  contactName,
  phoneNumber = '',
  email = '',
}) => ({
  company: organisation,
  street: addressLine1,
  locality: addressLine2,
  town: addressLine3,
  county: addressLine4,
  postcode: postcode,
  countryCode,
  phone: phoneNumber,
  email,
  contactName,
});

export default useGetBillingAddress;
