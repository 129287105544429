import { Box, Button, useTheme } from '@mui/material';
import { get } from 'lodash';
import GooglePayIcon from '../../assets/images/googlePayIcon.png';
import ApplePayIcon from '../../assets/images/applePayIcon.png';
import { getLinkForParselToSetInWallet } from '../../helpers';
import { ADD_TO_WALLET } from '../../constants/strings';
import { SHIPMENT_FIELDS } from '../../constants/forms';

const SingleParcel = ({
  barcodes,
  shipmentId,
  shipment,
  os,
  isSmallScreen,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
        }}
      >
        {barcodes && (
          <img
            src={`data:image/png;base64, ${barcodes[0].barcodeImage.imageData}`}
            alt='QR Code'
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        )}
      </Box>

      {isSmallScreen && (
        <Box
          sx={{
            pt: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {os === 'IOS' ? (
            <Button
              href={getLinkForParselToSetInWallet(
                'apple',
                shipmentId,
                get(shipment, `${SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelId`)
              )}
              rel='noopener noreferrer'
              sx={{
                mx: 1,
                mb: 2,
                width: '100%',
                background: theme.palette.primary.black,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  background: theme.palette.primary.black,
                  color: theme.palette.primary.contrastText,
                },
              }}
              variant='outlined'
            >
              <img
                style={{ marginRight: 8 }}
                src={ApplePayIcon}
                alt='Apple Pay'
              />
              {ADD_TO_WALLET.APPLE}
            </Button>
          ) : (
            <Button
              href={getLinkForParselToSetInWallet(
                'google',
                shipmentId,
                get(shipment, `${SHIPMENT_FIELDS.PARCELS.KEY}[0].parcelId`)
              )}
              rel='noopener noreferrer'
              sx={{
                mx: 1,
                background: theme.palette.primary.black,
                color: theme.palette.primary.contrastText,
                '&:hover': {
                  background: theme.palette.primary.black,
                  color: theme.palette.primary.contrastText,
                },
              }}
              variant='outlined'
            >
              <img
                style={{ marginRight: 8 }}
                src={GooglePayIcon}
                alt='Google Pay'
              />
              {ADD_TO_WALLET.GOOGLE}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default SingleParcel;
