import apis from './apis';

export const getParcels = ({ searchPage, searchPageSize }) =>
  apis.get('parcels/filter', {
    params: {
      searchPage,
      searchPageSize,
    },
  });

export const getParcelByNumber = parcelNumber =>
  apis.get('parcels/', {
    params: {
      parcelNumber,
    },
  });

export const getParcelCodeByParcelNumber = ({
  parcelNumber,
  postcode,
  orderId,
}) =>
  apis.get('parcels/find', {
    params: {
      parcelNumber,
      postcode,
      orderId,
    },
  });

export const getParcelByCode = ({ parcelCode, postcode, orderId }) =>
  apis.get(`parcels/${parcelCode}`, {
    params: {
      postcode,
      orderId,
    },
  });

export const createParcelClaim = claimData =>
  apis.post('parcels/case/create', claimData);
