import { get } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthSelectors } from '../features/Auth';
import { addressApis, collectionsApis } from '../apis';
import { FormatterHelpers } from '../helpers';
import { countriesActions } from './countriesSlice';
import addressBooksSlice from './addressBooksSlice';
import configSlice from './configSlice';
import userSlice from './userSlice';
import { BasketActions, BasketSelectors } from './basket';

export const fetchCollectionDates = createAsyncThunk(
  'app/fetchCollectionDates',
  async ({ countryCode, postcode }, { rejectWithValue }) => {
    try {
      const { data } = await collectionsApis.getCollectionDates({
        countryCode,
        postcode,
      });

      return data;
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data);
      } else {
        throw e;
      }
    }
  }
);

export const fetchAddressDetails = createAsyncThunk(
  'app/fetchAddressDetails',
  async (address, { rejectWithValue }) => {
    try {
      let addressId = address.addressId;
      const countryCode = address.countryCode;
      const postcode = address.postcode;
      const property = address.property;
      const street = address.street;

      if (!addressId) {
        // @example: B66 1AA, 100 High Street
        const lookupResult = await addressApis.fastFind({
          lookup: FormatterHelpers.joinStringsWithComma([
            postcode,
            FormatterHelpers.joinStringsWith([property, street], ' '),
          ]),
          countryISO: countryCode,
        });

        addressId = get(lookupResult, 'Item[0].Key');

        if (!addressId) {
          // No address found
          return;
        }
      }

      const { Item } = await addressApis.retrieve({
        key: addressId,
        countryISO: countryCode,
      });

      return Item[0];
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      } else {
        throw err;
      }
    }
  }
);

export const loadAppData = createAsyncThunk(
  'app/loadInitialData',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const promises = [
        dispatch(countriesActions.fetchCountries()),
        dispatch(configSlice.actions.fetchConfig('common')),
      ];
      const currentSession = AuthSelectors.getCurrentSession(getState());
      const basketId = BasketSelectors.getBasketId(getState());

      if (basketId) {
        promises.push(dispatch(BasketActions.getBasketItems()));
      }
      if (currentSession?.uid) {
        promises.push(dispatch(userSlice.actions.fetchAccountDetails()));
        promises.push(
          dispatch(addressBooksSlice.actions.fetchDefaultAddressBook())
        );
      }
      return await Promise.all(promises);
    } catch (e) {
      if (e.response) {
        return rejectWithValue(e.response.data);
      } else {
        throw e;
      }
    }
  }
);
