import { debounce } from 'lodash';

import { useEffect, useMemo } from 'react';

/**
 * Custom hook for debounced functions using lodash.
 * @param {Function} callback - The function to debounce.
 * @param {number} delay - The debounce delay in milliseconds.
 * @param {Object} options - Optional lodash debounce options (e.g., { leading: true, trailing: false }).
 * @returns {Function} - The debounced function.
 */
const useDebounceCallback = (callback, delay, options) => {
  // Memoize the debounced function to avoid re-creating it on each render
  const debouncedFunction = useMemo(
    () => debounce(callback, delay, options),
    [delay, options]
  );

  // Cleanup the debounce function on unmount
  useEffect(
    () => () => {
      debouncedFunction.cancel();
    },
    [debouncedFunction]
  );

  return debouncedFunction;
};

export default useDebounceCallback;
