export const THE_PAGE_NOT_FOUND =
  'The page you’re looking for \n can’t be found';
export const PLEASE_TRY_AGAIN = 'Please try again';
export const WE_HAVE_A_LITTLE_PROBLEM = 'We have a little problem';
export const IT_LOOKS_LIKE_WE_ENCOUNTERED =
  'It looks like we encountered an issue and need you to\n log back in.';
export const BACK_TO_$ = page => `Back to ${page}`;
export const BACK_TO_LOGIN = BACK_TO_$('Login');
export const BACK_TO_HOME = BACK_TO_$('homepage');
export const BACK_TO_ORDERS = 'Back to orders';
export const BACK_TO_BASKET_CART = BACK_TO_$('Basket Cart');
export const BACK_TO_HELP = BACK_TO_$('Help');
export const SORRY_YOU_DONT_HAVE_ACCESS =
  'Sorry, you don’t have access to\n this page';
export const YOUR_BASKET_IS_FULL = 'Your Basket is Full';
export const OOPS_YOU_HAVE_REACHED_MAX_NUMBER_OF_BASKET_ITEMS =
  maxBasketItems =>
    `Oops! You've reached the maximum limit of ${maxBasketItems} items in your basket.`;
export const REVIEW_YOUR_BASKET_BEFORE_ADDING_MORE = `Please review your basket and manage unpaid items before adding more.`;
export const IT_LOOKS_LIKE_YOUR_TRYING =
  'It looks like you’re trying to access a page that requires\n you to be logged in.';
export const IF_YOU_RECEIVED_THIS_LINK =
  'If you received this link to track a parcel, please visit the\n tracking page and enter your reference number.';
export const LOGIN = 'Login';
export const TRACK_PARCEL = 'Track parcel';
export const CANNOT_PROCESS_CLAIM =
  'Unfortunately, we cannot process your claim';
export const CLAIM_WINDOW_EXPIRED = deliveryDate =>
  `The claim window has expired for your parcel, which was delivered on ${deliveryDate}.`;
export const ORDER_WILL_EXPIRE =
  'Your order will expire in 7 days and be removed from your basket.';
