/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Autocomplete } from 'mui-rff';
import { v4 as uuidv4 } from 'uuid';
import { useOverlay } from '../../../../features/Overlay/index';
import { BasketActions } from '../../../../redux/basket';
import ProhibitedModal from '../../../../components/ProhibitedModal';
import { CommodityFinderModal } from '../../../../features/CommodityFinderModal';
import CommodityCard from '../../../../features/CommodityFinderModal/components/CommodityCard';
import AddCommodityCodeBtn from '../../../../features/CommodityFinderModal/components/AddCommodityCodeBtn';
import {
  minParcelValue,
  minParcelWeight,
  productSchema,
} from '../../validators/parcel';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import Summary from '../../components/Summary';
import { ANALYTICS, FORM, STRINGS } from '../../../../constants';
import { ObjectUtil, StringUtil } from '../../../../utils';
import {
  ErrorHelpers,
  NormalizerHelpers,
  Validators,
} from '../../../../helpers';
import Debugger from '../../../../components/Debugger';
import OrderForm from '../../components/OrderForm';
import * as OrderSelectors from '../../selectors';
import { useOrderSnackbar } from '../../hooks';
import { FormInput } from '../../../../components/FormInput';
import CommodityCodeErrorInvalidModal from '../../../../features/CommodityFinderModal/components/CommodityCodeErrorInvalidModal';
import { DEFAULT_MAX_PARCEL_WEIGHT } from '../../constants';
import useAnalytics from '../../../../hooks/useAnalytics';
import { PRODUCT_TABLE_CONFIG, TOTAL_TABLE_CONFIG } from './constants';
import { getFormattedTotal, getProductDataErrors } from './helpers';
import ProductTable from './components/ProductTable';
import * as CustomsDetailsSelectors from './selectors';

const CustomsDetails = ({ nextStep, values }) => {
  const theme = useTheme();
  const overlay = useOverlay();
  const snackbar = useOrderSnackbar();
  const [searchParams] = useSearchParams();
  const { Trackable, setInterfaceId } = useAnalytics();
  const dispatch = useDispatch();
  const [commodityCodeErrorOpen, setCommodityCodeErrorOpen] = useState(false);
  const countries = useSelector(countriesSelectors.getCountries);
  const availableFields = useSelector(
    CustomsDetailsSelectors.getAvailableFields
  );
  const productRequiredFields = useSelector(
    CustomsDetailsSelectors.getRequiredFields
  );
  const [checkedProhibitedItem, setCheckedProhibitedItem] = useState(false);
  const [isOpenProhibitedModal, setIsOpenProhibitedModal] = useState(false);
  const [commodityFinderOpen, setCommodityFinderOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isValid, setIsValid] = useState(true);
  const products = useMemo(
    () => ObjectUtil.getValue(values, FORM.PRODUCTS_PATH, []),
    [values]
  );
  const total = useMemo(() => getFormattedTotal(products), [products]);
  const deliveryCountryCode = get(
    values,
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
  );
  const totalWeight = get(values, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY);
  const customsValue = get(values, FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY);
  const productRequiredKeys = useMemo(
    () => ObjectUtil.convertObjectKeysToArray(productRequiredFields),
    [productRequiredFields]
  );
  const context = {
    ...useSelector(OrderSelectors.getValidationContext),
    productRequiredKeys,
  };
  const maxWeight = ObjectUtil.getValue(
    context,
    'maxWeight',
    DEFAULT_MAX_PARCEL_WEIGHT
  );
  const validateProductSchema = useMemo(
    () =>
      Validators.requireKeysSchema(
        productSchema(maxWeight),
        productRequiredKeys
      ),
    [maxWeight, productRequiredKeys]
  );
  const validateProduct = useCallback(
    values => {
      const joiErrors = Validators.validateWithJoi(
        values,
        validateProductSchema,
        {
          allowUnknown: true,
        }
      );
      const dataErrors = getProductDataErrors(
        values,
        products,
        totalWeight,
        customsValue
      );

      return { ...joiErrors, ...dataErrors };
    },
    [customsValue, products, totalWeight, validateProductSchema]
  );

  const invalidProductsIds = useMemo(
    () =>
      products.reduce((acc, product) => {
        const errors = validateProduct(product);
        if (!isEmpty(errors)) {
          acc.push(product._id);
        }
        return acc;
      }, []),
    [products, validateProduct]
  );
  const hasInvalidProducts = useMemo(
    () => !isEmpty(invalidProductsIds),
    [invalidProductsIds]
  );
  const errorMessage = useMemo(
    () =>
      ErrorHelpers.getProductErrorMessage(
        invalidProductsIds.length,
        products.length
      ),
    [invalidProductsIds, products]
  );

  useEffect(() => {
    setInterfaceId(ANALYTICS.ORDER_CUSTOMS_DETAILS.INTERFACE_ID);
  }, []);

  const onSubmit = useCallback(
    async (formValues, formApi, callback, skipNextStep = false) => {
      try {
        overlay.show();
        const newValues = cloneDeep(formValues);
        const basketItemId = searchParams.get('basketItemId');

        const firstProductDesc = StringUtil.truncateString(
          ObjectUtil.getValue(
            newValues,
            FORM.SHIPMENT_FIELDS.CONSIGNMENT_PRODUCT_DESCRIPTION.KEY(0, 0),
            ''
          ),
          45
        );
        set(
          newValues,
          FORM.SHIPMENT_FIELDS.DELIVERY_DESCRIPTION.KEY,
          firstProductDesc
        );

        const updatedBasketItem = {
          basketItemId,
          shipment: newValues,
        };

        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();

        if (!skipNextStep) {
          nextStep(newValues);
        }
      } catch (e) {
        snackbar.showSubmitError({
          originError: e,
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, nextStep, overlay, searchParams, snackbar]
  );

  const handleOpenCommodityModal = () => {
    setCommodityFinderOpen(true);
  };
  const handleCloseCommodityModal = () => {
    setCommodityFinderOpen(false);
  };

  const handleSetCommoditySelected = (form, commodityData) => {
    form.batch(() => {
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_DESCRIPTION.KEY,
        ObjectUtil.getValue(commodityData, 'commodity.description', '')
      );
      form.change(
        FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.KEY,
        ObjectUtil.getValue(commodityData, 'commodity.code', '')
      );
    });
  };

  const handleOpenCommodityCodeErrorModal = () => {
    setCommodityCodeErrorOpen(true);
  };

  const handleCloseCommodityCodeErrorModal = () => {
    setCommodityCodeErrorOpen(false);
  };

  useEffect(() => {
    if (!isValid) {
      handleOpenCommodityCodeErrorModal();
      handleCloseCommodityModal();
    }
  }, [isValid]);

  const updateFields = useCallback((product = {}) => {
    setSelectedProduct(product);
    setCheckedProhibitedItem(false);
  }, []);

  const handleSaveProduct = useCallback(
    async (product, form) => {
      const newProducts = cloneDeep(products);
      const productId = ObjectUtil.getValue(product, '_id', uuidv4());
      const existingProductIndex = products.findIndex(
        product => product._id === productId
      );
      const updatedProduct = { ...product, _id: productId };

      if (existingProductIndex > -1) {
        newProducts[existingProductIndex] = updatedProduct;
      } else {
        newProducts.push(updatedProduct);
      }

      form.change(FORM.PRODUCTS_PATH, newProducts);
      updateFields();
      await onSubmit(form.getState().values, undefined, undefined, true);
    },
    [products, updateFields]
  );

  const handleDeleteProduct = useCallback(
    async (productToDelete, form) => {
      const newProducts = products.filter(
        product => product._id !== productToDelete._id
      );

      form.change(FORM.PRODUCTS_PATH, newProducts);

      if (isEmpty(newProducts)) {
        updateFields();
      }

      await onSubmit(form.getState().values, undefined, undefined, true);
    },
    [products, updateFields]
  );

  return (
    <Trackable loadId={ANALYTICS.ORDER_CUSTOMS_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <OrderForm context={context} onSubmit={onSubmit}>
          {({ invalid, submitting, form: orderForm }) => (
            <>
              <Form
                onSubmit={(values, form) => {
                  handleSaveProduct(values, orderForm);
                  form.restart();
                }}
                validate={validateProduct}
                initialValues={selectedProduct}
              >
                {({
                  values: formValues,
                  invalid,
                  errors,
                  form,
                  handleSubmit,
                }) => (
                  <>
                    <Grid item xs={12} md={8}>
                      <form id='customsDetailsForm' onSubmit={handleSubmit}>
                        <Paper sx={{ p: 2, mb: 2 }}>
                          <Typography variant='h3' sx={{ mb: 2 }}>
                            {STRINGS.CUSTOMS_PAGE.TITLE}
                          </Typography>
                          {hasInvalidProducts && (
                            <Alert severity='error' icon={false} sx={{ mb: 2 }}>
                              {errorMessage}
                            </Alert>
                          )}
                          <Typography variant='body1' sx={{ mb: 2 }}>
                            {STRINGS.CUSTOMS_PAGE.PARCEL_DETAILS}
                          </Typography>
                          <Grid
                            item
                            sx={{
                              pr: 1,
                              mt: { xs: 2, md: 0 },
                            }}
                          >
                            <FormInput
                              name={
                                FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.KEY
                              }
                              label={
                                FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.LABEL
                              }
                              helperText={
                                STRINGS.CUSTOMS_PAGE.PROD_DESC_EXAMPLE
                              }
                              required
                              size='small'
                              maxLength={200}
                              sx={{ width: '100%' }}
                            />
                          </Grid>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              mt: 1,
                            }}
                          >
                            <Checkbox
                              checked={checkedProhibitedItem}
                              onChange={event =>
                                setCheckedProhibitedItem(event.target.checked)
                              }
                              sx={{ color: 'primary.main' }}
                            />
                            <Typography variant='caption'>
                              {
                                STRINGS.SALES_CONDITIONS
                                  .CONFIRM_THAT_MY_SHIPMENT
                              }
                              <Typography
                                variant='caption'
                                onClick={() => setIsOpenProhibitedModal(true)}
                                sx={{
                                  cursor: 'pointer',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {STRINGS.PROHIBITED_MODAL.PROHIBITED_ITEMS}
                              </Typography>
                            </Typography>
                          </Box>
                          {!availableFields.isDescriptionOnly && (
                            <>
                              <Grid container sx={{ mt: 0 }} spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <FormInput
                                    name={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY
                                        .LABEL
                                    }
                                    helperText={
                                      STRINGS.CUSTOMS_PAGE
                                        .TOTAL_QUANTITY_HELPER_TEXT
                                    }
                                    required
                                    size='small'
                                    fieldProps={{
                                      parse: value =>
                                        StringUtil.restrictToDigitsAndLimit(
                                          value,
                                          5
                                        ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <FormInput
                                    name={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE
                                        .KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE
                                        .LABEL
                                    }
                                    helperText={
                                      STRINGS.CUSTOMS_PAGE
                                        .PRODUCT_VALUE_HELPER_TEXT
                                    }
                                    required
                                    size='small'
                                    fieldProps={{
                                      parse: value =>
                                        NormalizerHelpers.formatAndConstrainDecimal(
                                          {
                                            value,
                                            maxIntegerLength: 4,
                                            maxDecimalLength: 2,
                                            minValue: minParcelValue,
                                          }
                                        ),
                                    }}
                                    onBlur={event =>
                                      event.target.value &&
                                      form.change(
                                        event.target.name,
                                        NormalizerHelpers.addTrailingZeros(
                                          StringUtil.formatDecimalString(
                                            event.target.value
                                          )
                                        )
                                      )
                                    }
                                    showErrorUntouched={
                                      !!formValues[
                                        FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE
                                          .KEY
                                      ]
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} md={6}>
                                  <FormInput
                                    name={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT
                                        .KEY
                                    }
                                    label={
                                      FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT
                                        .LABEL
                                    }
                                    helperText={StringUtil.formatMessage(
                                      STRINGS.CUSTOMS_PAGE
                                        .PRODUCT_WEIGHT_HELPER_TEXT_$,
                                      maxWeight
                                    )}
                                    required
                                    size='small'
                                    fieldProps={{
                                      parse: value =>
                                        NormalizerHelpers.formatAndConstrainDecimal(
                                          {
                                            maxIntegerLength: 5,
                                            maxDecimalLength: 2,
                                            value,
                                            minValue: minParcelWeight,
                                          }
                                        ),
                                    }}
                                    onBlur={event =>
                                      event.target.value &&
                                      form.change(
                                        event.target.name,
                                        NormalizerHelpers.addTrailingZeros(
                                          StringUtil.formatDecimalString(
                                            event.target.value
                                          )
                                        )
                                      )
                                    }
                                    showErrorUntouched={
                                      !!formValues[
                                        FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT
                                          .KEY
                                      ]
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12} md={12}>
                                  <Field
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .PRODUCT_COMMODITY_CODE.KEY
                                    }
                                  >
                                    {({ input }) => (
                                      <>
                                        {input.value ? (
                                          <CommodityCard
                                            setIsValid={setIsValid}
                                            commodity={{
                                              code: input.value,
                                              description:
                                                formValues[
                                                  FORM.SHIPMENT_FIELDS
                                                    .PRODUCT_COMMODITY_DESCRIPTION
                                                    .KEY
                                                ],
                                              applyCode: true,
                                            }}
                                            setCommoditySelected={() =>
                                              handleSetCommoditySelected(
                                                form,
                                                null
                                              )
                                            }
                                            onEditClick={
                                              handleOpenCommodityModal
                                            }
                                            isValid={isValid}
                                          />
                                        ) : (
                                          <AddCommodityCodeBtn
                                            required={
                                              productRequiredFields[
                                                FORM.SHIPMENT_FIELDS
                                                  .PRODUCT_COMMODITY_CODE.KEY
                                              ]
                                            }
                                            onClick={handleOpenCommodityModal}
                                          />
                                        )}
                                        <CommodityCodeErrorInvalidModal
                                          open={commodityCodeErrorOpen}
                                          setIsValid={setIsValid}
                                          onClose={
                                            handleCloseCommodityCodeErrorModal
                                          }
                                        />
                                        <CommodityFinderModal
                                          open={commodityFinderOpen}
                                          setIsValid={setIsValid}
                                          deliveryCountryCode={
                                            deliveryCountryCode
                                          }
                                          setCommoditySelected={commodity => {
                                            handleSetCommoditySelected(
                                              form,
                                              commodity
                                            );
                                          }}
                                          productDescription={
                                            formValues[
                                              FORM.SHIPMENT_FIELDS
                                                .PRODUCT_DESCRIPTION.KEY
                                            ]
                                          }
                                          onClose={handleCloseCommodityModal}
                                        />
                                      </>
                                    )}
                                  </Field>
                                </Grid>
                              </Grid>
                            </>
                          )}
                          {availableFields[
                            FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.KEY
                          ] && (
                            <Box sx={{ mt: 3 }}>
                              <Autocomplete
                                options={countries}
                                getOptionLabel={option => option.countryName}
                                getOptionValue={option => option.countryKey}
                                name={
                                  FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN
                                    .KEY
                                }
                                renderInput={params => (
                                  <FormInput
                                    label={
                                      FORM.SHIPMENT_FIELDS
                                        .PRODUCT_COUNTRY_OF_ORIGIN.LABEL
                                    }
                                    variant='outlined'
                                    name={
                                      FORM.SHIPMENT_FIELDS
                                        .PRODUCT_COUNTRY_OF_ORIGIN.KEY
                                    }
                                    required={
                                      productRequiredFields[
                                        FORM.SHIPMENT_FIELDS
                                          .PRODUCT_COUNTRY_OF_ORIGIN.KEY
                                      ]
                                    }
                                    size='small'
                                    helperText={
                                      STRINGS.CUSTOMS_PAGE
                                        .ENTER_OR_SELECT_COUNTRY_OF_ORIGIN
                                    }
                                    {...params}
                                  />
                                )}
                              />
                            </Box>
                          )}
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} md={12}>
                              <ProductTable
                                ariaLabel='products table'
                                config={PRODUCT_TABLE_CONFIG(
                                  availableFields.isDescriptionOnly,
                                  product =>
                                    handleDeleteProduct(product, orderForm)
                                )}
                                items={
                                  isEmpty(products)
                                    ? [{ isDefaultValue: true }]
                                    : products
                                }
                                selectedItemId={selectedProduct?._id}
                                onRowSelect={product => {
                                  updateFields(product);
                                  form.restart();
                                }}
                                invalidIds={invalidProductsIds}
                              />
                            </Grid>
                          </Grid>
                          {!availableFields.isDescriptionOnly && (
                            <Grid container sx={{ mt: 2 }}>
                              <Grid item xs={12} md={12}>
                                <ProductTable
                                  ariaLabel='total products table'
                                  config={TOTAL_TABLE_CONFIG}
                                  items={[total]}
                                />
                              </Grid>
                            </Grid>
                          )}
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} md={6}>
                              <Button
                                startIcon={<Add />}
                                onClick={() => {
                                  updateFields();
                                  form.restart();
                                }}
                                disabled={isEmpty(selectedProduct)}
                              >
                                {STRINGS.CUSTOMS_PAGE.ADD_ANOTHER_PRODUCT}
                              </Button>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              display='flex'
                              justifyContent='flex-end'
                            >
                              <Button
                                variant='contained'
                                disabled={invalid || !checkedProhibitedItem}
                                type='submit'
                              >
                                {STRINGS.CUSTOMS_PAGE.SAVE_PRODUCT}
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                        <Debugger>
                          <pre>{JSON.stringify(errors, null, 2)}</pre>
                        </Debugger>
                      </form>
                    </Grid>
                  </>
                )}
              </Form>
              <Grid item xs={12} md={4}>
                <form>
                  <Summary
                    submitDisabled={
                      isEmpty(products) ||
                      hasInvalidProducts ||
                      invalid ||
                      submitting
                    }
                  />
                </form>
              </Grid>
            </>
          )}
        </OrderForm>
      </Grid>
      <ProhibitedModal
        isOpen={isOpenProhibitedModal}
        onClose={() => setIsOpenProhibitedModal(false)}
      />
    </Trackable>
  );
};

export default CustomsDetails;
