import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import StaticMapPreview from '../MapBox/StaticMapPreview';
import { ADDRESS_WIDGET } from '../../constants/strings';
import noMap from '../../assets/images/noMap.jpg';

const AddressWidget = ({ address = {}, onEditClick, additionalInfo }) => {
  const {
    street,
    county,
    town,
    locality,
    organisation,
    postcode,
    _coordinates,
  } = address;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          p: 1,
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {_coordinates ? (
            <StaticMapPreview
              address={street}
              longitude={_coordinates.longitude}
              latitude={_coordinates.latitude}
              zoom={14}
              style={{
                borderRadius: 16,
              }}
              width={320}
              height={150}
            />
          ) : (
            <Box
              width={'100%'}
              height={150}
              sx={{
                borderRadius: 2,
                backgroundImage: `url(${noMap})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
          )}
        </Box>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ pl: 2, wordBreak: 'break-all' }}>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              {organisation}
            </Typography>
            <Typography variant='body2'>{street}</Typography>
            <Typography variant='body2'>{locality}</Typography>
            <Typography variant='body2'>{town}</Typography>
            <Typography variant='body2'>{county}</Typography>
            <Typography variant='body2'>{postcode}</Typography>
          </Box>
          <Button
            variant='outlined'
            fullWidth
            sx={{ mt: 2 }}
            onClick={onEditClick}
          >
            {ADDRESS_WIDGET.EDIT}
          </Button>
          {additionalInfo && (
            <Typography variant='body2' sx={{ px: 2, py: 1 }}>
              {additionalInfo}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

AddressWidget.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    county: PropTypes.string,
    locality: PropTypes.string,
    postcode: PropTypes.string,
    organisation: PropTypes.string,
    _coordinates: PropTypes.shape({
      longitude: PropTypes.number,
      latitude: PropTypes.number,
    }),
  }),
  onEditClick: PropTypes.func,
  additionalInfo: PropTypes.string,
};

export default AddressWidget;
