import { createSelector } from '@reduxjs/toolkit';
import { OrderSliceSelectors } from '../../slice';
import { ShipmentHelpers } from '../../../../helpers';
import { getProductAvailableFields } from './helpers';

export const getAvailableFields = createSelector(
  OrderSliceSelectors.getValues,
  getProductAvailableFields
);

export const getRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  ShipmentHelpers.getProductRequiredFields
);
