/* eslint-disable complexity */
import { useCallback, useState, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { get, isEmpty, isNull } from 'lodash';

import ActionButton from '../../components/ActionButton';
import { Validators } from '../../helpers';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../features/ErrorBoundary';
import { parcelsApis, chatApis } from '../../apis';
import { getDayAndMonth } from '../../utils/dateAndTime';
import { useOverlay } from '../../features/Overlay';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { FORM, STRINGS } from '../../constants';
import EmptySearchResults from '../../components/EmptySearchResults';
import OrderDetails from '../../components/OrderDetails';
import { HELP_TYPES, CONTACT_US_TYPE } from './constants';
import ClaimTypeSelect from './components/ClaimTypeSelect';
import SearchParcelFields from './components/SearchParcelFields';
import ContactDetails from './components/ContactDetails';
import ClaimDetails from './components/ClaimDetails';
import ChatUnavailable from './components/ChatUnavailable';
import {
  getValidationContext,
  isClaimInvalid,
  normalizeClaimData,
  getIsChatAvailable,
  getClaimData,
} from './helpers';
import SuccessScreen from './components/SuccessScreen';
import ChatModal from './components/ChatModal';
import ChatButton from './components/ChatButton';

const Help = () => {
  const theme = useTheme();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();

  const [submitted, setSubmitted] = useState(false);
  const [parcel, setParcel] = useState({});
  const [chatConfig, setChatConfig] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const chatButtonContainer = useRef(null);

  const isChatAvailable = getIsChatAvailable(chatConfig);
  const deliveredDate = get(parcel, 'deliveryDetails.podDetails.podDate');

  const createCase = useCallback(
    async (values, errorMessage, onSuccess) => {
      try {
        overlay.show();

        const normalizedValues = normalizeClaimData(values);
        const result = await parcelsApis.createParcelClaim(normalizedValues);

        onSuccess(result.data.id);
      } catch (error) {
        showError({ message: errorMessage });
      } finally {
        overlay.hide();
      }
    },
    [overlay, showError]
  );

  const onFormSubmit = useCallback(
    async values => {
      const claimData = getClaimData(values, parcel);
      createCase(claimData, STRINGS.SEND_CLAIM_ERROR, () => setSubmitted(true));
    },
    [createCase, parcel]
  );

  const onChatOptionClick = useCallback(async () => {
    try {
      overlay.show();

      const chatConfig = await chatApis.fetchChatConfig();

      setChatConfig(chatConfig.data);
    } catch (error) {
      showError({ message: STRINGS.CHAT_CONFIG_ERROR });
    } finally {
      overlay.hide();
    }
  }, [overlay, showError]);

  const onParcelFound = useCallback(
    async (parcel, values) => {
      const isChatWithUsSelected =
        get(values, FORM.HELP_FIELDS.CONTACT_US_TYPE.KEY) ===
        CONTACT_US_TYPE.CHAT;
      if (!isEmpty(parcel) && isChatWithUsSelected) {
        const claimData = getClaimData(values, parcel);
        await createCase(claimData, STRINGS.CREATE_CASE_ERROR, id => {
          setCaseId(id);
          setIsModalOpen(true);
        });
      }
    },
    [createCase]
  );

  const resetForm = useCallback(form => {
    form.restart();
    setParcel({});
    setCaseId(null);
    setChatConfig(null);
  }, []);

  const openChatModal = useCallback(() => setIsModalOpen(true), []);
  const closeChatModal = useCallback(() => setIsModalOpen(false), []);

  if (!isEmpty(parcel) && isClaimInvalid(deliveredDate)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          flexGrow: 1,
        }}
      >
        <ErrorBoundary
          config={getErrorBoundaryConfig[
            ERROR_BOUNDARY_TYPES.CANNOT_PROCESS_CLAIM
          ](getDayAndMonth(deliveredDate))}
          sx={{ maxWidth: '447px', alignSelf: 'center' }}
        />
      </Box>
    );
  }

  if (submitted) {
    return <SuccessScreen />;
  }

  return (
    <Box
      sx={{
        py: { xs: 2 },
        p: { md: 4 },
        background: theme.palette.primary.backgroundLightGray,
        flexGrow: 1,
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <Grid item>
          <Typography variant='h2' sx={{ m: 2, mt: 0 }}>
            {STRINGS.HOW_CAN_WE_HELP}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems='center' justifyContent='center'>
        <Grid
          item
          xs={12}
          md={9}
          lg={7}
          xl={6}
          sx={{ maxWidth: { md: '562px' } }}
        >
          <Form
            onSubmit={onFormSubmit}
            validate={values =>
              Validators.validateWithJoi(values, Validators.helpParcelSchema, {
                context: getValidationContext(values.reasonForContact),
              })
            }
          >
            {({ values, errors, invalid, handleSubmit, form }) => {
              const isParcelIssueSelected =
                get(values, FORM.HELP_FIELDS.HELP_TYPE.KEY) ===
                HELP_TYPES.ISSUE;
              const isContactUsSelected =
                get(values, FORM.HELP_FIELDS.HELP_TYPE.KEY) === HELP_TYPES.CHAT;
              const isChatWithUsSelected =
                get(values, FORM.HELP_FIELDS.CONTACT_US_TYPE.KEY) ===
                CONTACT_US_TYPE.CHAT;
              const isChatSelected = isChatAvailable && isChatWithUsSelected;
              const showSearchParcelFields =
                isParcelIssueSelected || isChatSelected;
              const showChatButton = isChatSelected && caseId;
              const contactDetails = get(
                values,
                FORM.HELP_KEYS.CONTACT_DETAILS
              );
              const hasContactDetails =
                contactDetails?.name && contactDetails?.email;
              const isContactDetailsValid = !errors.contactDetails;
              const issueDetailsAvailable =
                hasContactDetails && isContactDetailsValid;

              return (
                <form onSubmit={handleSubmit}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                      {STRINGS.CHOOSE_ONE_OF_OPTIONS}
                    </Typography>
                    <Field name={FORM.HELP_FIELDS.HELP_TYPE.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.CHAT}
                              title={STRINGS.CONTACT_US}
                              bodyText={STRINGS.GET_ANSWER_WITH_CHAT}
                              cardOnclick={() => {
                                resetForm(form);
                                input.onChange(HELP_TYPES.CHAT);
                              }}
                              additionalInfo={false}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.ISSUE}
                              title={STRINGS.PARCEL_ISSUE}
                              bodyText={STRINGS.WE_CAN_HELP_PARCEL_ISSUE}
                              additionalInfo={false}
                              cardOnclick={() => {
                                resetForm(form);
                                input.onChange(HELP_TYPES.ISSUE);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Paper>
                  {isContactUsSelected && (
                    <Paper sx={{ p: 2, mb: 2 }}>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        {STRINGS.CHOOSE_ONE_OF_OPTIONS}
                      </Typography>
                      <Field name={FORM.HELP_FIELDS.CONTACT_US_TYPE.KEY}>
                        {({ input }) => (
                          <ActionButton
                            selected={input.value === CONTACT_US_TYPE.CHAT}
                            title={STRINGS.CHAT_WITH_US}
                            bodyText={STRINGS.CHAT_TO_OUR_TEAM}
                            additionalInfo={false}
                            cardOnclick={() => {
                              input.onChange(CONTACT_US_TYPE.CHAT);
                              onChatOptionClick();
                            }}
                          />
                        )}
                      </Field>
                    </Paper>
                  )}
                  {isChatSelected && (
                    <ChatModal
                      open={isModalOpen}
                      onClose={closeChatModal}
                      chatConfig={chatConfig}
                      parcelCode={parcel?.parcelCode}
                      caseId={caseId}
                    />
                  )}
                  {showSearchParcelFields && (
                    <Paper sx={{ p: 2, pb: 3, mb: 2 }}>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        {STRINGS.ENTER_YOUR_PARCEL_NUMBER}
                      </Typography>
                      <Box sx={{ mt: 3 }}>
                        <SearchParcelFields
                          setParcel={setParcel}
                          onParcelFound={parcel =>
                            onParcelFound(parcel, values)
                          }
                          errors={errors}
                        />
                      </Box>
                    </Paper>
                  )}
                  {showChatButton && (
                    <ChatButton
                      disabled={isModalOpen}
                      onClick={openChatModal}
                      container={() => chatButtonContainer.current}
                    />
                  )}
                  {isParcelIssueSelected && !isEmpty(parcel) && (
                    <>
                      <OrderDetails
                        parcel={parcel}
                        summaryTitle={STRINGS.YOUR_PARCEL_SUMMARY}
                        trackingImages={parcel?.parcelEvents[0]?.images}
                        showBackButton={false}
                        isFullView={false}
                      />
                      <ClaimTypeSelect />
                      {values.reasonForContact && (
                        <>
                          <ContactDetails />
                          <ClaimDetails
                            issueType={values.reasonForContact}
                            isAvailable={issueDetailsAvailable}
                          />
                          <Box
                            sx={{
                              ml: 'auto',
                              width: 'fit-content',
                              pr: { xs: 2, md: 0 },
                            }}
                          >
                            <Button
                              variant='contained'
                              type='submit'
                              disabled={invalid}
                            >
                              {STRINGS.SUBMIT}
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </form>
              );
            }}
          </Form>
          {isNull(parcel) && <EmptySearchResults />}
          {chatConfig && !isChatAvailable && <ChatUnavailable />}
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'relative',
          pr: 2,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        ref={chatButtonContainer}
      />
    </Box>
  );
};

export default Help;
