import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { ADDRESSES_SECTION } from '../../../../constants/strings';

const AddressBookSearch = ({
  searchString,
  onSearchChange,
  onSearchSubmit,
  inputWidth = '220px',
}) => {
  const handleInputChange = e => {
    onSearchChange(e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <TextField
        sx={{
          width: inputWidth,
          '& .MuiInputBase-root': {
            paddingRight: '10px',
          },
        }}
        size='small'
        label={ADDRESSES_SECTION.SEARCH.LABEL}
        inputProps={{
          maxlength: 100,
        }}
        InputProps={{
          endAdornment: searchString.length > 0 && (
            <InputAdornment position='end'>
              <IconButton sx={{ width: '28px', height: '28px' }}>
                <DeleteIcon
                  sx={{ width: '20px', height: '20px' }}
                  onClick={() => onSearchChange('')}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchString}
        onChange={handleInputChange}
        onKeyDown={event => {
          if (event.key === 'Enter' && searchString.length >= 3) {
            onSearchSubmit();
          }
        }}
      />
      <Button
        sx={{ ml: 1, height: '40px', padding: '7px 20px', fontSize: '15px' }}
        variant='outlined'
        size='small'
        disabled={searchString.length < 3}
        onClick={onSearchSubmit}
      >
        {ADDRESSES_SECTION.SEARCH.BUTTON}
      </Button>
    </Box>
  );
};

AddressBookSearch.propTypes = {
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  inputWidth: PropTypes.string,
};

export default AddressBookSearch;
