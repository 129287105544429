import { Badge, Box, IconButton, useTheme } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

const GalleryIconButton = ({ imagesNumber, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      position='absolute'
      bottom='8px'
      right='8px'
      display='inline-flex'
      alignItems='center'
      justifyContent='center'
    >
      <IconButton
        sx={{
          backgroundColor: theme.palette.grey[400],
          color: theme.palette.primary.black,
          borderRadius: '50%',
          width: 40,
          height: 40,
          boxShadow: theme.palette.primary.shadow,
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
          },
          '&:active': {
            backgroundColor: theme.palette.grey[400],
          },
        }}
        onClick={onClick}
      >
        <Box position='absolute' display='flex' alignItems='center'>
          <PhotoLibraryIcon fontSize='small' />
        </Box>
      </IconButton>
      <Badge
        badgeContent={imagesNumber}
        color='secondary'
        sx={{
          position: 'absolute',
          bottom: 6,
          right: 6,
        }}
      />
    </Box>
  );
};

export default GalleryIconButton;
