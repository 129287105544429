import { Button, Box, Portal, Fab } from '@mui/material';
import PropTypes from 'prop-types';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { OPEN_CHAT } from '../../../constants/strings';

const ChatButton = ({ disabled, onClick, container }) => {
  const isSmallScreen = useBreakpoint('sm');

  if (isSmallScreen) {
    return (
      <Box sx={{ px: 2, maxWidth: { sm: '500px' }, mx: 'auto' }}>
        <Button
          variant='contained'
          fullWidth
          onClick={onClick}
          disabled={disabled}
        >
          {OPEN_CHAT}
        </Button>
      </Box>
    );
  }

  return (
    <Portal container={container}>
      <Fab size='small' onClick={onClick} color='secondary' disabled={disabled}>
        <ChatOutlinedIcon />
      </Fab>
    </Portal>
  );
};

ChatButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  container: PropTypes.func,
};

export default ChatButton;
