/* eslint-disable complexity */
import { useMemo } from 'react';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { generateRouteMapStaticImageUrlByParcelCode } from '../../helpers/map';
import IssueSection from './components/IssueSection';
import TrackingSection from './components/TrackingSection';
import AddressSection from './components/AddressSection';
import DeliveryStatusSection from './components/DeliveryStatusSection';
import EventsSection from './components/EventsSection';

const OrderDetails = ({
  parcel,
  trackingImages = [],
  summaryTitle,
  footerButtons,
  onBackClick,
  showBackButton = true,
  isFullView = true,
}) => {
  const theme = useTheme();

  const {
    parcelCode,
    collectionDetails,
    deliveryDetails,
    parcelEvents = [],
    parcelNumber,
    parcelStatus,
    parcelStatusHtml,
    service,
    issueDetails,
  } = { ...parcel };
  let images = trackingImages;
  const parcelNumberWithoutChecksum = (parcelNumber || '').slice(0, -1);

  const contactNameOrOrganisation =
    deliveryDetails?.notificationDetails?.contactName ??
    deliveryDetails?.address?.organisation;
  const deliveryAndCollectionAddressPostcode =
    collectionDetails?.address?.postcode && deliveryDetails?.address?.postcode;
  const deliveryAddressCoordinates = get(
    deliveryDetails,
    'address.addressPoint'
  );

  if (parcelEvents?.length > 0 && images.length === 0) {
    const firstEvent = parcelEvents[0];
    images = firstEvent?.images || [];
  }

  const mapSrc = useMemo(() => {
    if (deliveryAndCollectionAddressPostcode) {
      return generateRouteMapStaticImageUrlByParcelCode({
        parcelCode,
        width: 600,
        height: 340,
      });
    }
    return null;
  }, [parcelCode, deliveryAndCollectionAddressPostcode]);

  return (
    <>
      {parcel ? (
        <SectionWrapper
          titleLeftComponent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {showBackButton && (
                <IconButton
                  aria-label='back'
                  sx={{ mr: 0.5, p: 0.5 }}
                  onClick={onBackClick}
                >
                  <ChevronLeft />
                </IconButton>
              )}
              <Box>
                {contactNameOrOrganisation && !summaryTitle && (
                  <Typography>{contactNameOrOrganisation}</Typography>
                )}
                {summaryTitle && (
                  <Typography variant='h3'>{summaryTitle}</Typography>
                )}
                <Typography
                  variant='body2'
                  color='textSecondary'
                  sx={{
                    fontFamily: 'Roboto',
                    m: 0,
                  }}
                >
                  {parcelNumberWithoutChecksum}
                  {service?.serviceDescription
                    ? ` • ${service.serviceDescription}`
                    : ''}
                </Typography>
              </Box>
            </Box>
          }
          titleRightComponent={
            parcelStatus && (
              <Typography
                color={theme.palette.parcelStatus[parcelStatus]}
                sx={{ ml: 2, mt: { xs: 2, md: 0 } }}
              >
                {parcelStatus}
              </Typography>
            )
          }
          sx={{ height: 'auto' }}
        >
          <Box sx={{ maxWidth: 600 }}>
            <TrackingSection
              mapSrc={mapSrc}
              images={images}
              parcelNumber={parcelNumberWithoutChecksum}
              coordinates={deliveryAddressCoordinates}
            />
            <DeliveryStatusSection
              parcelStatus={parcelStatus}
              parcelStatusHtml={parcelStatusHtml}
              onRouteElectric={isFullView && deliveryDetails?.onRouteElectric}
              withBorder={isFullView}
            />
            {isFullView && (
              <>
                <IssueSection issueDetails={issueDetails} />
                {deliveryAndCollectionAddressPostcode && (
                  <AddressSection
                    collectionDetails={collectionDetails}
                    deliveryDetails={deliveryDetails}
                  />
                )}
                <EventsSection parcelEvents={parcelEvents} theme={theme} />
              </>
            )}
          </Box>
          {isFullView && <Divider sx={{ mt: 2, marginX: -2 }} />}
          {footerButtons && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: { md: 'flex-end', xs: 'center' },
                pt: 1,
                gap: 1,
              }}
            >
              {footerButtons}
            </Box>
          )}
        </SectionWrapper>
      ) : null}
    </>
  );
};

OrderDetails.propTypes = {
  parcel: PropTypes.object,
  trackingImages: PropTypes.array,
  summaryTitle: PropTypes.string,
  footerButtons: PropTypes.node,
  onBackClick: PropTypes.func,
  showBackButton: PropTypes.bool,
  isFullView: PropTypes.bool,
};

export default OrderDetails;
