import Close from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { EXTERNAL_LINKS, STRINGS } from '../../../constants';

const CommodityCodeErrorInvalidModal = ({ open, onClose, setIsValid }) => {
  const theme = useTheme();

  const handleClose = () => {
    onClose();
    setIsValid(true);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.contrastText,
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '75%',
          },
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography variant='h3'>
              {STRINGS.COMMODITY_FINDER.INVALID_CODE.TITLE}
            </Typography>
            <IconButton
              aria-label='close'
              sx={{ mr: 0.5 }}
              onClick={() => onClose()}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              p: 2,
            }}
          >
            <Typography sx={{ mb: 2 }} variant='body2'>
              {STRINGS.COMMODITY_FINDER.INVALID_CODE.SUBTITLE}
            </Typography>
            <Typography sx={{ mb: 2 }} variant='body2'>
              {STRINGS.COMMODITY_FINDER.INVALID_CODE.LINK}
              <Link href={EXTERNAL_LINKS.GOV_TRADE_TARIFF}>
                {EXTERNAL_LINKS.GOV_TRADE_TARIFF}
              </Link>
            </Typography>
            <Typography sx={{ mb: 1 }} variant='body2'>
              {STRINGS.COMMODITY_FINDER.INVALID_CODE.DESCRIPTION}
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Grid
          container
          justifyContent='center'
          sx={{ padding: '8px 16px 16px', position: 'relative' }}
        >
          <Divider sx={{ position: 'absolute', width: '100%', top: '0' }} />
        </Grid>
        <Button sx={{ mb: 2, mx: 2 }} variant='contained' onClick={handleClose}>
          {STRINGS.COMMODITY_FINDER.INVALID_CODE.BUTTON}
        </Button>
      </Box>
    </Modal>
  );
};

CommodityCodeErrorInvalidModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
};

export default CommodityCodeErrorInvalidModal;
