import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import GalleryWidget from '../../GalleryWidget';
import { STRINGS } from '../../../constants';
import { getImageUrl } from '../../../utils/images';
import GalleryIconButton from '../../GalleryIconButton';
import notFoundImg from '../../../assets/images/image-not-found.png';
import mapNotAvailable from '../../../assets/images/mapNotAvailable.jpeg';

const TrackingSection = ({ mapSrc, parcelNumber, images, coordinates }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const handleMapError = () => setImageError(true);
  const openGallery = () => setIsGalleryOpen(true);

  const isNotEmpty = images.length > 0;
  const hideMap =
    imageError || !mapSrc || !coordinates?.latitude || !coordinates?.longitude;

  return (
    <>
      <Typography
        variant='body2'
        color='textSecondary'
        sx={{
          fontFamily: 'Roboto',
          my: 1,
        }}
      >
        {STRINGS.ORDER_DETAILS.TRACKING}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {!hideMap && (
          <Box
            sx={{
              width: {
                xs: '100%',
                md: isNotEmpty ? '50%' : '100%',
              },
            }}
          >
            <Box
              component='img'
              src={mapSrc}
              alt='Map Preview'
              width='100%'
              sx={{
                borderRadius: 1,
              }}
              onError={handleMapError}
            />
          </Box>
        )}

        {isNotEmpty && (
          <>
            <GalleryWidget
              title={STRINGS.ORDER_DETAILS.GALLERY_TITLE}
              images={images}
              parcelNumber={parcelNumber}
              isOpen={isGalleryOpen}
              onClose={() => setIsGalleryOpen(false)}
            />
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '50%' },
                position: 'relative',
                height: { xs: '340px', md: '167px' },
              }}
            >
              <Box
                component='img'
                src={getImageUrl({ ...images[0], parcelNumber })}
                alt='Gallery Thumbnail'
                width='100%'
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onError={e => (e.target.src = notFoundImg)}
                onClick={openGallery}
              />
              <GalleryIconButton
                imagesNumber={images.length}
                onClick={openGallery}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

TrackingSection.propTypes = {
  mapSrc: PropTypes.string,
  parcelNumber: PropTypes.string,
  images: PropTypes.string,
  coordinates: PropTypes.object,
};

export default TrackingSection;
