import { isEmpty, toNumber } from 'lodash';
import { formatDuration, intervalToDuration } from 'date-fns';
import { REGEX, STRINGS } from '../constants';
import { ArrayUtil } from '../utils';

export const formatUKPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  // formats phone number and returns in format +447123456789
  const result =
    cleaned.match(REGEX.SHORTEST_VALID_UK_PHONE_NUMBER_REGEXP) || [];

  return result.length > 1
    ? `${STRINGS.COUNTRY_CODE.UK}${result[1]}`
    : phoneNumberString;
};

export const joinStringsWith = (items = [], separator) =>
  !isEmpty(items)
    ? ArrayUtil.filterOnlyStrings(items).filter(Boolean).join(separator).trim()
    : '';

export const joinStringsWithComma = items => joinStringsWith(items, ', ');
export const formatAddressLine = ({
  contactName,
  organisation,
  street,
  property,
  town,
  postcode,
}) =>
  joinStringsWithComma([
    contactName ?? organisation,
    street,
    property,
    town,
    postcode,
  ]);

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Converts time in seconds to a human-readable string in the format of
 * minutes, hours and minutes, or days, hours, and minutes.
 *
 * @param {number} seconds - The number of seconds to convert.
 * @returns {string} A human-readable string representing the time in the appropriate format.
 *
 * @example
 * console.log(formatTime(2700));    // "45 minutes"
 * console.log(formatTime(4500));    // "1 hour 15 minutes"
 * console.log(formatTime(86400));   // "1 day"
 * console.log(formatTime(90000));   // "1 day 1 hour"
 * console.log(formatTime(173100));  // "2 days 5 minutes"
 */
export const formatTime = seconds => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  return formatDuration(duration, {
    format: ['days', 'hours', 'minutes'],
    zero: false, // Exclude zero values
  });
};

export const formatPrice = value => {
  const numericValue = toNumber(value);
  return isNaN(numericValue) ? '0.00' : numericValue.toFixed(2);
};
