import { SvgIcon } from '@mui/material';

const Receipt = ({ styles }) => (
  <SvgIcon
    sx={{ width: '40px', height: '40px', pr: 1, pb: 1, ...styles }}
    viewBox='0 0 36 35'
  >
    <mask
      id='mask0_15599_3608'
      maskUnits='userSpaceOnUse'
      x='5'
      y='5'
      width='36'
      height='35'
    >
      <rect x='5.5' y='5' width='35' height='35' fill='#D9D9D9' />
    </mask>
    <g mask='url(#mask0_15599_3608)'>
      <path
        d='M11.333 35.2885V9.71151L13.184 11.1137L15.1473 9.71151L17.1102 11.1137L19.0735 9.71151L21.0364 11.1137L22.9997 9.71151L24.963 11.1137L26.9259 9.71151L28.8892 11.1137L30.8521 9.71151L32.8153 11.1137L34.6663 9.71151V35.2885L32.8153 33.8863L30.8521 35.2885L28.8892 33.8863L26.9259 35.2885L24.963 33.8863L22.9997 35.2885L21.0364 33.8863L19.0735 35.2885L17.1102 33.8863L15.1473 35.2885L13.184 33.8863L11.333 35.2885ZM14.9788 28.1649H31.0205V26.7066H14.9788V28.1649ZM14.9788 23.2292H31.0205V21.7708H14.9788V23.2292ZM14.9788 18.2934H31.0205V16.8351H14.9788V18.2934ZM12.7913 32.8542H33.208V12.1458H12.7913V32.8542Z'
        fill='#1C1B1F'
      />
    </g>
  </SvgIcon>
);

export default Receipt;
