import { useCallback } from 'react';
import { HttpStatusCode } from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { useOverlay } from '../../../features/Overlay';
import { useAuth } from '../../../features/Auth';
import { STRINGS } from '../../../constants';

export const useOrderSnackbar = () => {
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const auth = useAuth();

  const handleRefresh = useCallback(
    async key => {
      snackbar.closeSnackbar(key);
      try {
        overlay.show();
        await auth.getCurrentSession();
        navigate('/');
      } finally {
        overlay.hide();
      }
    },
    [snackbar, navigate, auth]
  );

  const showSubmitError = useCallback(
    ({ originError, ...params }) => {
      if (originError && originError?.statusCode === HttpStatusCode.Forbidden) {
        return snackbar.showError({
          ...params,
          message: STRINGS.YOUR_SESSION_HAS_EXPIRED_REFRESH_TO_CONTINUE,
          autoHideDuration: null,
          action: key => (
            <Button onClick={() => handleRefresh(key)} color='inherit'>
              {STRINGS.REFRESH}
            </Button>
          ),
        });
      }
      snackbar.showError(params);
    },
    [handleRefresh, snackbar]
  );

  return { ...snackbar, showSubmitError };
};
