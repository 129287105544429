import { Modal, Box } from '@mui/material';
import { ChatBox } from '@dpdgroupuk/mydpd-chat';
import PropTypes from 'prop-types';

const ChatModal = ({ open, onClose, chatConfig, parcelCode, caseId }) => (
  <Modal
    open={open}
    onClose={onClose}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      px: 2,
    }}
    keepMounted
  >
    <Box sx={{ width: '650px', maxWidth: '100%' }}>
      {caseId && parcelCode && (
        <ChatBox
          initialConfig={chatConfig}
          hideChat={onClose}
          ratingEnabled={false}
          parcelCode={parcelCode}
          caseId={caseId}
        />
      )}
    </Box>
  </Modal>
);

ChatModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  chatConfig: PropTypes.object.isRequired,
  parcelCode: PropTypes.string,
  caseId: PropTypes.string,
};

export default ChatModal;
