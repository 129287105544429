import { chain, get, isEmpty, set, unset } from 'lodash';
import {
  InvoiceHelpers,
  NormalizerHelpers,
  ShipmentHelpers,
} from '../../../../helpers';
import { FORM, INVOICE_CONSTANTS, STRINGS } from '../../../../constants';
import { REFERENCE_NAME } from '../../constants';
import { ObjectUtil } from '../../../../utils';

export const getInvoiceAvailableFields = values => {
  const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
  const isBusiness = ObjectUtil.getValue(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(saebiFlags);
  const isProductAvailable = ShipmentHelpers.isProductAvailable(values);
  const isUkimsAvailable = ShipmentHelpers.isUkimsAvailable(values);
  const isIossAvailable = !ShipmentHelpers.isFlagNeedles(
    saebiFlags,
    FORM.NETWORK_FIELDS.TAX_REQUIRED
  );
  const isExportImportRequired =
    ShipmentHelpers.isExportImportEoriRequired(saebiFlags);

  return {
    isInvoiceAvailable,
    isProductAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isInvoiceAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY]:
      ShipmentHelpers.isAtRiskAvailable(values, isInvoiceAvailable),
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]:
      isBusiness && isExportImportRequired,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]:
      isBusiness && isExportImportRequired,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      isInvoiceAvailable && !isBusiness && isExportImportRequired,
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]:
      isExportImportRequired,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY]:
      isExportImportRequired,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]:
      ShipmentHelpers.isFdaNumberAvailable(saebiFlags),
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY]: isIossAvailable,
    [FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY]:
      isInvoiceAvailable &&
      !ShipmentHelpers.isFlagNeedles(
        saebiFlags,
        FORM.NETWORK_FIELDS.EXPORT_REASON_REQUIRED
      ),
  };
};

export const getAllowedFields = (values, references, currentSession) => ({
  ...getInvoiceAvailableFields(values),
  saveForNextTime:
    !get(values, FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  saveForNextTimeReceiver:
    !get(values, FORM.SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  destinationPickupPoint:
    !!get(values, FORM.SHIPMENT_FIELDS.PICKUP_DETAILS.KEY) &&
    !!references[REFERENCE_NAME.DELIVERY_PICKUP],
});

const getProductDescriptions = products =>
  products.map(({ productDescription, _id }) => ({
    productDescription,
    _id,
  }));

export const getFieldsToUpdate = ({
  values,
  shipment,
  isBusiness,
  atRisk,
  newSaebiFlags,
  references = {},
}) => {
  const fieldsToReset = [];
  const fieldsToUpdate = {
    [FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isBusiness,
  };
  const setFieldIfMissing = (key, source, sourceKey) => {
    if (!ObjectUtil.getValue(values, key, '')) {
      fieldsToUpdate[key] = ObjectUtil.getValue(source, sourceKey, '');
    }
  };
  const products = ObjectUtil.getValue(values, FORM.PRODUCTS_PATH, []);
  const isNiShipment = ShipmentHelpers.isNiShipment(values);
  const isUkimsAvailable = isNiShipment && isBusiness && !atRisk;
  const isExportImportEoriRequired =
    ShipmentHelpers.isExportImportEoriRequired(newSaebiFlags);
  const isExportReasonAvailable =
    ShipmentHelpers.isInvoiceAvailable(newSaebiFlags) &&
    !ShipmentHelpers.isFlagNeedles(
      newSaebiFlags,
      FORM.NETWORK_FIELDS.EXPORT_REASON_REQUIRED
    );

  if (isNiShipment) {
    fieldsToUpdate[FORM.SHIPMENT_FIELDS.NETWORK.KEY] = {
      ...get(values, FORM.SHIPMENT_FIELDS.NETWORK.KEY),
      ...newSaebiFlags,
    };
  }

  if (!ShipmentHelpers.isFdaNumberAvailable(newSaebiFlags)) {
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY);
  }

  if (isExportImportEoriRequired) {
    setFieldIfMissing(
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY,
      references?.collectionAddressBook,
      FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY
    );
    setFieldIfMissing(
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY,
      references?.collectionAddressBook,
      FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY
    );
  } else {
    fieldsToReset.push(
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY
    );
  }

  if (isBusiness) {
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY);

    if (isExportImportEoriRequired) {
      setFieldIfMissing(
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY,
        references?.deliveryAddressBook,
        FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY
      );
      setFieldIfMissing(
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY,
        references?.deliveryAddressBook,
        FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY
      );
    }

    if (isNiShipment) {
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY] =
        atRisk;
    }

    if (isUkimsAvailable) {
      setFieldIfMissing(
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
        references?.deliveryAddressBook,
        FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY
      );
      setFieldIfMissing(
        FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY,
        references?.collectionAddressBook,
        FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY
      );
    } else {
      fieldsToReset.push(
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
        FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
      );
    }
  } else {
    fieldsToReset.push(
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY
    );

    if (isNiShipment) {
      fieldsToUpdate[FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY] = false;
    }

    if (isExportImportEoriRequired) {
      setFieldIfMissing(
        FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY,
        references?.deliveryAddressBook,
        FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY
      );
    }
  }

  if (
    ShipmentHelpers.isFlagDescription(
      newSaebiFlags,
      FORM.NETWORK_FIELDS.PROD_REQUIRED
    ) &&
    !isEmpty(products)
  ) {
    fieldsToUpdate[FORM.PRODUCTS_PATH] = getProductDescriptions(products);
  }

  if (isExportReasonAvailable) {
    setFieldIfMissing(
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY,
      shipment,
      FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY
    );
    setFieldIfMissing(
      FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY,
      shipment,
      FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY
    );
  } else {
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_EXPORT_REASON.KEY);
    fieldsToReset.push(FORM.SHIPMENT_FIELDS.INVOICE_TYPE.KEY);
  }

  return {
    fieldsToUpdate,
    fieldsToReset,
  };
};

const getImporterDetails = (values, addressBook, isExportImportEoriRequired) =>
  chain({
    // NOTE: destructure to avoid mutation deliveryDetails
    ...ObjectUtil.getValue(
      values,
      FORM.SHIPMENT_FIELDS.DELIVERY_DETAILS.KEY,
      {}
    ),
  })
    .thru(importerDetails => {
      const defaultIsBusiness = InvoiceHelpers.getDefaultIsBusiness(values, {
        businessPath: FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
        organisationPath: FORM.SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
      });
      set(importerDetails, 'isBusiness', defaultIsBusiness);
      set(
        importerDetails,
        'contactDetails.email',
        get(importerDetails, 'notificationDetails.email', '')
      );

      if (defaultIsBusiness) {
        set(importerDetails, 'atRisk', false);

        if (isExportImportEoriRequired) {
          set(
            importerDetails,
            'eoriNumber',
            ObjectUtil.getValue(
              addressBook,
              FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY,
              ''
            )
          );
          set(
            importerDetails,
            'vatNumber',
            ObjectUtil.getValue(
              addressBook,
              FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY,
              ''
            )
          );
        }

        if (ShipmentHelpers.isUkimsAvailable(values)) {
          set(
            importerDetails,
            'ukimsNumber',
            ObjectUtil.getValue(
              addressBook,
              FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
              ''
            )
          );
        }
      } else if (isExportImportEoriRequired) {
        set(
          importerDetails,
          'pidNumber',
          ObjectUtil.getValue(
            addressBook,
            FORM.ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY,
            ''
          )
        );
      }

      return importerDetails;
    })
    .value();

const getExporterDetails = (values, addressBook, isExportImportEoriRequired) =>
  // NOTE: destructure to avoid mutation collectionDetails
  chain({
    ...ObjectUtil.getValue(
      values,
      FORM.SHIPMENT_FIELDS.COLLECTION_DETAILS.KEY,
      {}
    ),
  })
    .thru(exporterDetails => {
      set(
        exporterDetails,
        'isBusiness',
        InvoiceHelpers.getDefaultIsBusiness(values, {
          businessPath: FORM.SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
          organisationPath: FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
        })
      );

      if (isExportImportEoriRequired) {
        set(
          exporterDetails,
          'eoriNumber',
          ObjectUtil.getValue(
            addressBook,
            FORM.ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY,
            ''
          )
        );
        set(
          exporterDetails,
          'vatNumber',
          ObjectUtil.getValue(
            addressBook,
            FORM.ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY,
            ''
          )
        );
      }

      if (ShipmentHelpers.isUkimsAvailable(values)) {
        set(
          exporterDetails,
          'ukimsNumber',
          ObjectUtil.getValue(
            addressBook,
            FORM.ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY,
            ''
          )
        );
      }

      return exporterDetails;
    })
    .value();

const setInvoiceFields = (values, references = {}) =>
  chain(values)
    .thru(values => {
      const invoice = ObjectUtil.getValue(values, 'invoice', {});
      const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
      const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(saebiFlags);
      const isExportImportEoriRequired =
        ShipmentHelpers.isExportImportEoriRequired(saebiFlags);

      if (!isInvoiceAvailable && !isEmpty(invoice)) {
        delete values.invoice;
        return values;
      }

      if (isInvoiceAvailable && isEmpty(invoice)) {
        set(
          values,
          FORM.SHIPMENT_FIELDS.INVOICE_INTERNATIONAL_BILLING_TERMS.KEY,
          INVOICE_CONSTANTS.DELIVERED_AT_PLACE
        );
        set(
          values,
          'invoice.importerDetails',
          getImporterDetails(
            values,
            references?.deliveryAddressBook,
            isExportImportEoriRequired
          )
        );
        set(
          values,
          'invoice.exporterDetails',
          getExporterDetails(
            values,
            references?.collectionAddressBook,
            isExportImportEoriRequired
          )
        );
      }

      return values;
    })
    .value();

const setProducts = values =>
  chain(values)
    .thru(values => {
      const isProductAvailable = ShipmentHelpers.isProductAvailable(values);
      const products = ObjectUtil.getValue(values, FORM.PRODUCTS_PATH, []);
      set(
        values,
        FORM.SHIPMENT_FIELDS.GENERATE_CUSTOMS_DATA.KEY,
        isProductAvailable
      );

      if (isProductAvailable) {
        set(
          values,
          FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY,
          NormalizerHelpers.addTrailingZeros(
            NormalizerHelpers.formatAndConstrainDecimal({
              value: ShipmentHelpers.getTotalParcelsValue(
                get(values, FORM.SHIPMENT_FIELDS.PARCELS.KEY)
              ),
              maxIntegerLength: 4,
              maxDecimalLength: 2,
              minValue: 0.01,
            }),
            2
          )
        );

        if (
          !ObjectUtil.getValue(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY, '')
        ) {
          set(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP);
        }

        if (
          ShipmentHelpers.isFlagDescription(
            ShipmentHelpers.getSaebiFlags(values),
            FORM.NETWORK_FIELDS.PROD_REQUIRED
          ) &&
          !isEmpty(products)
        ) {
          set(values, FORM.PRODUCTS_PATH, getProductDescriptions(products));
        }
      } else {
        unset(values, FORM.SHIPMENT_FIELDS.CURRENCY.KEY);
        unset(values, FORM.SHIPMENT_FIELDS.CUSTOMS_VALUE.KEY);

        if (!isEmpty(products)) {
          unset(values, 'outboundConsignment.parcels[0].products');
        }
      }

      return values;
    })
    .value();

export const setContactDetails = values => {
  const contactFields = [
    FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
    FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
    FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_TELEPHONE.KEY,
  ];

  return chain(values)
    .thru(values => {
      contactFields.forEach(field => {
        set(values, field, ObjectUtil.getValue(values, field, ''));
      });
      return values;
    })
    .value();
};

const setInvoiceAddressDetails = values =>
  chain(values)
    .thru(values => {
      const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
      const isInvoiceAvailable = ShipmentHelpers.isInvoiceAvailable(saebiFlags);
      if (!isInvoiceAvailable) {
        return values;
      }
      const importerAddressDetails = {
        ...ObjectUtil.getValue(
          values,
          FORM.SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY,
          {}
        ),
      };
      const exporterAddressDetails = {
        ...ObjectUtil.getValue(
          values,
          FORM.SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY,
          {}
        ),
      };
      set(values, 'invoice.importerDetails.address', importerAddressDetails);
      set(values, 'invoice.exporterDetails.address', exporterAddressDetails);
      return values;
    })
    .value();

const resetIossNumberIfNotAvailable = values =>
  chain(values)
    .thru(values => {
      const saebiFlags = ShipmentHelpers.getSaebiFlags(values);
      const isIossAvailable = !ShipmentHelpers.isFlagNeedles(
        saebiFlags,
        FORM.NETWORK_FIELDS.TAX_REQUIRED
      );
      if (
        !isIossAvailable &&
        get(values, FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY)
      ) {
        set(values, FORM.SHIPMENT_FIELDS.IOSS_NUMBER.KEY, '');
        return values;
      }
      return values;
    })
    .value();

export const getInitialValues = (values, references) =>
  chain(values)
    .cloneDeep()
    .thru(clonedValues => {
      setInvoiceFields(clonedValues, references);
      setInvoiceAddressDetails(clonedValues);
      setProducts(clonedValues);
      setContactDetails(clonedValues);
      resetIossNumberIfNotAvailable(clonedValues);

      return clonedValues;
    })
    .value();
