import Joi from 'joi';
import { set } from 'lodash';
import { PostcodeValidator } from '@dpdgroupuk/consumer-shipping-helper';
import { FORM, REGEX, STRINGS } from '../constants';
import { StringUtil } from '../utils';

export const required = value => (value ? undefined : STRINGS.REQUIRED);

export const empty = [null, ''];

export const emptyString = Joi.string().allow(...empty);

export const phoneNumber = label =>
  Joi.string()
    .pattern(REGEX.COMMON_PHONE_NUMBER_REGEXP)
    .max(15)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.PHONE_NUMBER
      ),
    })
    .label(label);

export const strictRequired = Joi.required().empty(...empty);

export const isPostcodeValid = (postcode, countryValue) =>
  PostcodeValidator.isPostcodeValid(postcode, countryValue);

export const validatePostcode = ({ value, helpers, countryValue }) => {
  const bfpoResult = bfpoValidation(value, helpers);

  // code property only exists if there is a JOI error
  if (bfpoResult && bfpoResult.code) {
    return bfpoResult;
  }
  if (!isPostcodeValid(value, countryValue)) {
    return helpers.error('postcode.country.invalid');
  }

  return value;
};

// Validator for Joi with react-final-form integration
export const validateWithJoi = (values, schema, options = {}) => {
  const { error } = schema.validate(values, {
    abortEarly: false,
    errors: { wrap: { label: false } },
    ...options,
  });

  if (error) {
    return error.details.reduce((acc, cur) => {
      set(acc, cur.path.join('.'), cur.message);

      return acc;
    }, {});
  }

  return {};
};

// NOTE: Somehow only this approach works for objects
export const requiredObject = Joi.object().required().empty(null);

export const requireKeysSchema = (schema, requireKeys = []) => {
  const makeRequired = x => {
    if (x.type === 'object') {
      return x.concat(requiredObject);
    }

    return x
      .required()
      .empty(...empty)
      .invalid(...empty)
      .messages({
        'string.empty': '{#label} is required', // NOTE: Needed in combination with prevention of missing objects
      });
  };

  return schema.fork(requireKeys, makeRequired);
};

export const getJoiFieldFlag = (schema, fieldName, validationFlag) => {
  // Get schema flags
  const fieldSchema = schema.extract(fieldName);

  // Check field validation flag
  return fieldSchema?._flags?.presence === validationFlag;
};

export const isFieldRequired = (schema, fieldName) =>
  getJoiFieldFlag(schema, fieldName, 'required');

export const numberOfParcels = Joi.number()
  .min(1)
  .max(99)
  .required()
  .label(FORM.QUANTITY);
const totalWeight = Joi.number().greater(0).required();
const parcelNumber = Joi.string()
  .pattern(REGEX.PARCEL_NUMBER_REGEXP)
  .concat(strictRequired)
  .empty('', null)
  .messages({
    'string.pattern.base': STRINGS.PARCEL_NUMBER_MUST_CONTAIN_14_DIGITS,
    'any.required': STRINGS.PARCEL_NUMBER_IS_REQUIRED,
  });

export const postcode = Joi.string().max(8);

export const bfpoValidation = (value, helpers) => {
  if (REGEX.BFPO_POSTCODES.some(regex => regex.test(value))) {
    return helpers.error('postcode.bfpo.invalid');
  }
  return value;
};

export const addressSchema = {
  street: Joi.string().max(35).label(FORM.ADDRESS_LINE_1),
  locality: emptyString.max(35).label(FORM.ADDRESS_LINE_2),
  town: Joi.string().max(35).label(FORM.CITY),
  county: emptyString.max(35).label(FORM.COUNTY),
  organisation: emptyString.max(35).label(FORM.COMPANY_NAME),
  countryCode: Joi.string().label(FORM.COUNTRY),
};

export const addressSchemaWithPostCode = countryValue => ({
  ...addressSchema,
  postcode: postcode
    .label(FORM.POSTAL_ZIP_CODE)
    .custom((value, helpers) =>
      validatePostcode({ value, helpers, countryValue })
    )
    .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES)
    .allow(...empty),
});

export const contactSchema = {
  contactName: Joi.string().max(35).required().label(FORM.CONTACT_NAME),
  nickname: Joi.string().max(35).required().label(FORM.NICKNAME),
  email: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .required()
    .max(100)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
    })
    .label(FORM.EMAIL),
  phoneNumber: phoneNumber(FORM.PHONE_NUMBER).required(),
  vatNumber: emptyString.min(4).max(45).label(FORM.VAT_NUMBER),
  eoriNumber: emptyString.min(4).max(45).label(FORM.EORI_NUMBER),
  pidNumber: emptyString.min(4).max(45).label(FORM.PID_NUMBER),
  ukimsNumber: emptyString.length(32).label(FORM.UKIMS_NUMBER),
};

export const accountDetailsSchema = Joi.object({
  [FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.LABEL),
  [FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.LABEL),
  [FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.NAME]: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .required()
    .max(100)
    .label(FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.LABEL)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
    }),
  [FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.NAME]: Joi.string()
    .allow(null)
    .label(FORM.ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.LABEL),
});

export const addressBookSchema = countryValue =>
  Joi.object({
    address: Joi.object(addressSchemaWithPostCode(countryValue)),
    ...contactSchema,
  });

export const getNetworksSchema = () =>
  Joi.object({
    deliveryDetails: Joi.object({
      address: Joi.object({
        countryCode: Joi.string().required(),
        town: Joi.string().max(35).required(),
        county: emptyString.max(35),
        postcode: postcode
          .when(Joi.ref('$deliveryCountryValue.isPostcodeRequired'), {
            is: true,
            then: postcode.required(),
            otherwise: postcode.optional().allow('', null),
          })
          .label(FORM.POSTAL_ZIP_CODE)
          .custom((value, helpers) => {
            const { deliveryCountryValue } = helpers.prefs.context; // Access context
            return validatePostcode({
              value,
              helpers,
              countryValue: deliveryCountryValue,
            });
          })
          .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES),
      }).required(),
    }).required(),
    collectionDetails: Joi.object({
      address: Joi.object({
        countryCode: Joi.string().required(),
        town: Joi.string().max(35).required(),
        county: emptyString.max(35),
        postcode: postcode
          .required()
          .label(FORM.POSTAL_ZIP_CODE)
          .custom((value, helpers) => {
            const { collectionCountryValue } = helpers.prefs.context; // Access context
            return validatePostcode({
              value,
              helpers,
              countryValue: collectionCountryValue,
            });
          })
          .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES),
      }).required(),
    }).required(),
    numberOfParcels,
    totalWeight,
    isBusiness: Joi.boolean(),
    atRisk: Joi.boolean(),
  }).required();

export const voucherSchema = Joi.object({
  voucherCode: Joi.string()
    .min(3)
    .messages({
      'string.min': STRINGS.GREATER_OR_$_CHARACTERS(3),
    }),
});

export const trackParcelSchema = Joi.object({
  [FORM.FIELDS.PARCEL_NUMBER.KEY]: parcelNumber,
  [FORM.FIELDS.POSTCODE_OR_ORDER_ID_SEARCH.KEY]: Joi.alternatives()
    .try(
      Joi.string().pattern(REGEX.ORDER_ID_REGEX),
      postcode.concat(strictRequired).empty('', null)
    )
    .required()
    .label(FORM.FIELDS.POSTCODE_OR_ORDER_ID_SEARCH.LABEL)
    .messages({
      'alternatives.match': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        FORM.FIELDS.POSTCODE_OR_ORDER_ID_SEARCH.LABEL
      ),
    }),
});

export const contactDetailsHelpSchema = Joi.object({
  name: Joi.string()
    .max(100)
    .required()
    .label(FORM.HELP_FIELDS.CONTACT_NAME.LABEL),
  email: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .required()
    .max(100)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
    })
    .label(FORM.HELP_FIELDS.EMAIL.LABEL),
  phone: phoneNumber(FORM.HELP_FIELDS.PHONE.LABEL).allow(...empty),
});

export const helpParcelSchema = Joi.object({
  [FORM.HELP_FIELDS.PARCEL_NUMBER.KEY]: parcelNumber,
  [FORM.HELP_FIELDS.POSTCODE_OR_ORDER_ID.KEY]: Joi.alternatives()
    .try(
      Joi.string().pattern(REGEX.ORDER_ID_REGEX),
      postcode.concat(strictRequired).empty('', null)
    )
    .required()
    .label(FORM.FIELDS.POSTCODE_OR_ORDER_ID_SEARCH.LABEL)
    .messages({
      'alternatives.match': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        FORM.FIELDS.POSTCODE_OR_ORDER_ID_SEARCH.LABEL
      ),
    }),
  [FORM.HELP_FIELDS.ISSUE_TYPE.KEY]: Joi.string().required(),
  [FORM.HELP_FIELDS.HELP_TYPE.KEY]: Joi.string().required(),
  [FORM.HELP_KEYS.CONTACT_DETAILS]: contactDetailsHelpSchema,
  [FORM.HELP_KEYS.PARCEL_CONTENTS]: Joi.string().max(255).required().messages({
    'any.required': STRINGS.VALUE_IS_REQUIRED,
  }),
  [FORM.HELP_KEYS.GOODS_VALUE]: Joi.string()
    .pattern(REGEX.GOODS_VALUE)
    .max(8)
    .when('$isGoodsValueRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.empty': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(8),
    }),
  [FORM.HELP_KEYS.PACKAGE_DESCRIPTION]: Joi.string()
    .max(1000)
    .when('$isPackageDescriptionRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(1000),
    }),
  [FORM.HELP_KEYS.ADDITIONAL_INFORMATION]: Joi.string()
    .max(32768)
    .when('$isAdditionalInfoRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(32768),
    }),
  [FORM.HELP_KEYS.DAMAGE_DESCRIPTION]: Joi.string()
    .max(255)
    .when('$isDamageDescriptionRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(255),
    }),
  [FORM.HELP_KEYS.MISSING_DESCRIPTION]: Joi.string()
    .max(255)
    .when('$isMissingDescriptionRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(255),
    }),
  [FORM.HELP_KEYS.DAMAGED_ITEM]: Joi.string()
    .max(255)
    .when('$isDamagedItemRequired', {
      is: true,
      then: Joi.required(),
      otherwise: Joi.allow(...empty),
    })
    .messages({
      'any.required': STRINGS.VALUE_IS_REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(255),
    }),
  [FORM.HELP_KEYS.ATTACHED_IMAGES]: Joi.array().items(
    Joi.object({
      image: Joi.string(),
      fileName: Joi.string(),
      contentType: Joi.string(),
      size: Joi.number(),
      preview: Joi.string(),
    })
  ),
});

export const loginEmailSchema = Joi.object({
  [FORM.FIELDS.EMAIL.KEY]: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .concat(strictRequired)
    .max(100)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
      'any.required': STRINGS.REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(100),
    }),
});

export const isUKPostcodeValid = postcode =>
  REGEX.UK_POST_CODE_FULL.test(postcode);
