import Joi from 'joi';
import { FORM } from '../../../constants';
import { ErrorHelpers, Validators } from '../../../helpers';

const dimensionValidator = Joi.number().greater(0).max(100).messages({
  'number.max': 'Size too long',
});

export const maxTotalParcelsValue = 1000;
export const minParcelValue = 0.01;
export const minParcelWeight = 0.01;
export const maxUnitValue = 1000;

export const productSchema = (maxWeight = 99999.99) =>
  Joi.object({
    _id: Validators.emptyString,
    productDescription: Validators.emptyString
      .max(200)
      .label(FORM.SHIPMENT_FIELDS.PRODUCT_DESCRIPTION.LABEL),
    productQty: Joi.number()
      .min(1)
      .max(99999)
      .integer()
      .allow(...Validators.empty)
      .label(FORM.SHIPMENT_FIELDS.PRODUCT_QUANTITY.LABEL),
    unitValue: Joi.number()
      .min(minParcelValue)
      .max(maxUnitValue)
      .allow(...Validators.empty)
      .label(FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_VALUE.LABEL),
    unitWeight: Joi.number()
      .min(minParcelWeight)
      .max(maxWeight)
      .allow(...Validators.empty)
      .label(FORM.SHIPMENT_FIELDS.PRODUCT_UNIT_WEIGHT.LABEL),
    countryOfOrigin: Validators.emptyString.label(
      FORM.SHIPMENT_FIELDS.PRODUCT_COUNTRY_OF_ORIGIN.LABEL
    ),
    _commodityDescription: Validators.emptyString,
    commodityCode: Validators.emptyString.label(
      FORM.SHIPMENT_FIELDS.PRODUCT_COMMODITY_CODE.LABEL
    ),
  });

export const parcelSchema = ({ maxWeight = 30, maxParcelNumber = 99 }) =>
  Joi.object({
    _parcelId: Joi.string(),
    _weight: Joi.number()
      .greater(0)
      .max(maxWeight)
      .label(FORM.WEIGHT)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxWeight}`,
      }),
    _quantity: Joi.number()
      .min(1)
      .max(maxParcelNumber)
      .label(FORM.QUANTITY)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxParcelNumber}`,
      }),
    _width: dimensionValidator.label(FORM.WIDTH).required(),
    _height: dimensionValidator.label(FORM.HEIGHT).required(),
    _length: dimensionValidator.label(FORM.LENGTH).required(),
    _value: Joi.number()
      .required()
      .min(minParcelValue)
      .max(maxTotalParcelsValue)
      .required()
      .messages({
        'number.min': `Min value is ${minParcelValue}`,
        'number.max': `Max value is ${maxTotalParcelsValue}`,
      })
      .label(FORM.VALUE),
    // NOTE: Temporary, remove after column in the table will be removed
    _description: Joi.string().allow('', null),
  });

export const productsSchema = ({ maxWeight = 30, productRequiredKeys = [] }) =>
  Joi.object({
    products: Joi.array()
      .items(
        Validators.requireKeysSchema(
          productSchema(maxWeight),
          productRequiredKeys
        )
      )
      .when('$isProductRequired', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.allow(null),
      })
      .custom((value, helpers) => {
        if (helpers.prefs.context?.groupErrors) {
          const countErrors = value.reduce((count, product) => {
            const schema = Validators.requireKeysSchema(
              productSchema(maxWeight),
              productRequiredKeys
            );
            const { error } = schema.validate(product, { abortEarly: false });

            return error ? count + 1 : count;
          }, 0);

          if (countErrors) {
            return helpers.message(
              ErrorHelpers.getProductErrorMessage(countErrors, value.length)
            );
          }

          return value;
        }
      })
      .label('Product'),
  });
