import { useCallback } from 'react';
import Dropzone, { ErrorCode } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { forEach, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

import {
  COMPRESSION_OPTIONS,
  MAX_IMAGES_UPLOAD_LIMIT,
} from '../../pages/Help/constants';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { STRINGS } from '../../constants';
import { useOverlay } from '../../features/Overlay';
import ImageThumb from './components/ImageThumb';

const ImageDropzone = ({
  input: { value, onChange },
  disabled,
  innerContent,
  showPreview,
  ...props
}) => {
  const theme = useTheme();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();

  const fileToBase64 = useCallback(
    file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }),
    []
  );

  const handleFile = useCallback(
    async file => {
      try {
        const compressedFile = await imageCompression(
          file,
          COMPRESSION_OPTIONS
        );

        const base64 = await fileToBase64(compressedFile);

        return {
          image: base64,
          fileName: file.name,
          contentType: file.type,
          preview: URL.createObjectURL(file),
          size: file.size,
        };
      } catch (error) {
        showError({ message: STRINGS.UPLOAD_ERROR_IMAGE });
      }
    },
    [fileToBase64, overlay, showError]
  );

  const onDropAccepted = useCallback(
    async acceptedFiles => {
      try {
        overlay.show();

        const previousUploadCount = isArray(value) ? value.length : 0;
        const totalCount = previousUploadCount + acceptedFiles.length;
        const isTooManyFiles = totalCount > MAX_IMAGES_UPLOAD_LIMIT;

        const errors = [];

        if (isTooManyFiles) {
          errors.push(
            STRINGS.LIST_OF_UPLOAD_ERROR_IMAGE[ErrorCode.TooManyFiles]
          );
        }

        if (errors.length > 0) {
          forEach(errors, error => showError({ message: error }));

          return;
        }

        const validFiles = (
          await Promise.all(acceptedFiles.map(handleFile))
        ).filter(Boolean);

        onChange(value ? [...value, ...validFiles] : [...validFiles]);
      } catch (error) {
        showError({ message: STRINGS.UPLOAD_ERROR_IMAGE });
      } finally {
        overlay.hide();
      }
    },
    [handleFile, onChange, showError, value]
  );

  const onHandelRejected = useCallback(
    fileRejections => {
      const listOfUniqueErrors = new Set(
        fileRejections.flatMap(
          rejection => rejection.errors?.map(error => error.code) || []
        )
      ); // to avoid duplicate the same error per every file

      listOfUniqueErrors.forEach(code => {
        showError({
          message:
            STRINGS.LIST_OF_UPLOAD_ERROR_IMAGE[code] ||
            STRINGS.LIST_OF_UPLOAD_ERROR_IMAGE['default'],
        });
      });
    },
    [showError]
  );

  const removeFile = fileToRemove => {
    const updatedFiles = value
      ? value.filter(file => file !== fileToRemove)
      : [];
    onChange(updatedFiles);
    URL.revokeObjectURL(fileToRemove.preview);
  };

  return (
    <Dropzone
      onDropAccepted={onDropAccepted}
      onDropRejected={onHandelRejected}
      {...props}
    >
      {({ getRootProps, getInputProps }) => (
        <Box>
          <Box
            {...getRootProps()}
            sx={{
              border: `2px dashed ${theme.palette.divider}`,
              borderRadius: 2,
              cursor: !disabled && 'pointer',
            }}
          >
            <input {...getInputProps()} />
            {innerContent}
          </Box>

          {showPreview && (
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}
            >
              {value &&
                value.map((file, index) => (
                  <ImageThumb
                    image={file.preview}
                    alt={file.name}
                    onRemoveClick={() => removeFile(file)}
                    key={`${file.name}-${index}`}
                  />
                ))}
            </Box>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

ImageDropzone.propTypes = {
  innerContent: PropTypes.node,
  disabled: PropTypes.bool,
  showErrors: PropTypes.bool,
  showPreview: PropTypes.bool,
  ...Dropzone.propTypes,
};

export default ImageDropzone;
