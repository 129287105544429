import { get } from 'lodash';
import { transformAddressValues } from '../../../helpers/address';

export const convertPickupToFormValues = pickup => {
  const { addressLine1, addressLine2 } = transformAddressValues(
    pickup.pickupLocation.address
  );
  return {
    allowRemotePickup: true,
    pickupLocationCode: pickup.pickupLocation.pickupLocationCode,
    pickupCountryCode: pickup.pickupLocation.address.countryCode,
    pickupOrganisation:
      get(pickup, 'pickupLocation.address.organisation') ||
      get(pickup, 'pickupLocation.shortName'),
    pickupPostcode: pickup.pickupLocation.address.postcode,
    pickupAddressLine1: addressLine1,
    pickupAddressLine2: addressLine2,
    pickupAddressLine3:
      get(pickup, 'pickupLocation.address.town') ||
      get(pickup, 'pickupLocation.address.city'),
    pickupAddressLine4: pickup.pickupLocation.address.county,
  };
};
