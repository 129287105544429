import { useCallback, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { USER_PREFERENCES_SECTIONS } from '../../constants/strings';

import useAnalytics from '../../hooks/useAnalytics';
import { USER_PREFERENCES } from '../../constants/analytics';
import { fetchAccountDetails } from '../../redux/userSlice';
import Loader from '../../features/Loader';
import { SideMenu } from './components';

const UserPreferences = () => {
  const { setInterfaceId, Trackable } = useAnalytics();
  const dispatch = useDispatch();

  const userPreferencesSectionsValues = Object.values(
    USER_PREFERENCES_SECTIONS
  );

  useEffect(() => {
    setInterfaceId(USER_PREFERENCES.INTERFACE_ID);
  }, []);

  const loadData = useCallback(
    () => dispatch(fetchAccountDetails()).unwrap(),
    []
  );

  return (
    <Trackable loadId={USER_PREFERENCES.LOAD}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <Loader promiseFn={loadData}>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid
              item
              sx={{ position: { xs: 'absolute', md: 'static' } }}
              xs={12}
              md={3}
              lg={2}
            >
              <SideMenu menuItems={userPreferencesSectionsValues} />
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <Outlet />
            </Grid>
          </Grid>
        </Loader>
      </Box>
    </Trackable>
  );
};

export default UserPreferences;
