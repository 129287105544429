import { useCallback, useState } from 'react';

const useAddressBookSearch = ({ setCurrentPage, setPaginationParameters }) => {
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchTrigger, setSearchTrigger] = useState(0);

  const resetToFirstPage = useCallback(() => {
    if (setCurrentPage) {
      setCurrentPage(1);
    } else if (setPaginationParameters) {
      setPaginationParameters({ currentPage: 1 });
    }
  }, [setCurrentPage, setPaginationParameters]);

  const handleSearchChange = useCallback(
    value => {
      setSearchString(value);

      if (!value.trim()) {
        setIsSearchMode(false);
        // reset to the first page if search string is empty
        resetToFirstPage();
      }
    },
    [resetToFirstPage]
  );

  const handleSearchSubmit = useCallback(() => {
    if (searchString.trim()) {
      setIsSearchMode(true);

      // reset to the first page when submitting a search
      resetToFirstPage();
      setSearchTrigger(prev => prev + 1);
    }
  }, [resetToFirstPage, searchString]);

  const resetSearch = useCallback(() => {
    setSearchString('');
    setIsSearchMode(false);

    // reset to the first page when search input is being reset
    resetToFirstPage();
  }, [resetToFirstPage]);

  return {
    isSearchMode,
    searchString,
    searchTrigger,
    handleSearchChange,
    handleSearchSubmit,
    resetSearch,
  };
};

export default useAddressBookSearch;
