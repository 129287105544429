import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Box, Divider, Drawer, List, ListItem, styled } from '@mui/material';
import { useTheme } from '@emotion/react';

import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { LOGOUT, USER_PREFERENCES_SECTIONS } from '../../../constants/strings';
import { USER_PREFERENCES_MOBILE_MENU_FIXED_HEIGHT } from '../../../constants/gaps';
import { PROFILE } from '../../../constants/routes';

import useBreakpoint from '../../../hooks/useBreakpoint';
import { useAuth } from '../../../features/Auth/index';
import useAnalytics from '../../../hooks/useAnalytics';

const MENU_FONT_SIZE = { fontSize: 13 };

const StyledLink = styled(Link)(({ theme, isActive, isSmallScreen }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  padding: isSmallScreen ? theme.spacing(0.5, 1) : theme.spacing(2, 4),
  justifyContent: isSmallScreen ? 'center' : 'flex-start',
  textTransform: isSmallScreen ? 'uppercase' : 'none',
  textDecoration: 'none',
  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

const SideMenu = ({ menuItems }) => {
  const { pathname: currentPath } = useLocation();
  const { trackAction } = useAnalytics();
  const { signOut } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useBreakpoint('md');
  const handleLogout = useCallback(event => {
    trackAction(event);
    signOut();
  }, []);

  return (
    <Drawer
      variant='permanent'
      sx={{
        height: '100%',
      }}
      PaperProps={{
        sx: {
          position: { xs: 'fixed', md: 'static' },
          justifyContent: { md: 'space-between' },
          borderTop: { xs: `1px solid ${theme.palette.divider}`, md: 'none' },
          height: {
            xs: 'auto',
            md: '100%',
          },
          left: 0,
          right: 0,
          bottom: 0,
          top: 'auto',
        },
      }}
    >
      <List
        sx={{
          display: { xs: 'flex', md: 'block' },
          height: {
            xs: USER_PREFERENCES_MOBILE_MENU_FIXED_HEIGHT,
            md: '100%',
          },
        }}
      >
        {menuItems.map((value, index) => {
          const hrefPath = `${PROFILE}/${value.toLowerCase()}`;
          const isActive =
            value === USER_PREFERENCES_SECTIONS.OVERVIEW &&
            currentPath === PROFILE
              ? true
              : currentPath === hrefPath;

          return (
            <ListItem key={value} sx={{ p: 0 }} divider={!isSmallScreen}>
              <StyledLink
                to={index === 0 ? PROFILE : hrefPath}
                isSmallScreen={isSmallScreen}
                isActive={isActive}
              >
                <Typography sx={{ fontSize: MENU_FONT_SIZE }}>
                  {value}
                </Typography>
              </StyledLink>
            </ListItem>
          );
        })}
      </List>
      {!isSmallScreen && (
        <Box>
          <Divider />
          <Button
            sx={{
              fontSize: '13px',
              lineHeight: '18px',
              letterSpacing: '0.16px',
              px: 4,
              py: 2,
              color: 'primary.main',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableRipple
            onClick={handleLogout}
          >
            {LOGOUT}
          </Button>
        </Box>
      )}
    </Drawer>
  );
};

SideMenu.propTypes = {
  menuItem: PropTypes.arrayOf(PropTypes.string),
};

export default SideMenu;
