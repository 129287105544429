import { Grid, Paper, Typography } from '@mui/material';
import { FORM, STRINGS } from '../../../constants';
import { FormInput } from '../../../components/FormInput';

const ContactDetails = () => (
  <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
    <Typography variant='h3' sx={{ mb: 1 }}>
      {STRINGS.YOUR_CONTACT_DETAILS}
    </Typography>
    <Grid item xs={12} sx={{ mt: 3 }}>
      <FormInput
        name={FORM.HELP_FIELDS.CONTACT_NAME.KEY}
        required
        label={FORM.HELP_FIELDS.CONTACT_NAME.LABEL}
        maxLength={100}
        fullWidth
      />
    </Grid>
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <FormInput
          name={FORM.HELP_FIELDS.EMAIL.KEY}
          required
          label={FORM.HELP_FIELDS.EMAIL.LABEL}
          maxLength={100}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput
          name={FORM.HELP_FIELDS.PHONE.KEY}
          label={FORM.HELP_FIELDS.PHONE.LABEL}
          maxLength={15}
          fullWidth
        />
      </Grid>
    </Grid>
  </Paper>
);

export default ContactDetails;
