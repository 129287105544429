import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useCustomSnackbar } from '../../features/CustomSnackbar';

const CopyToClipboardButton = ({ className, text, successMessage }) => {
  const { showDefault, showError } = useCustomSnackbar();

  const onCopyHandler = async text => {
    try {
      await navigator.clipboard.writeText(text);
      showDefault({
        message: successMessage,
      });
    } catch (error) {
      showError({
        message: error.message,
      });
    }
  };

  return (
    <Box
      className={className}
      sx={{
        transition: 'opacity 0.3s ease',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '4px 8px',
        position: 'absolute',
        top: '-6px',
        right: '-8px',
        borderRadius: '50%',
        zIndex: 1,
      }}
    >
      <IconButton
        size='small'
        onClick={event => {
          onCopyHandler(text);
          event.stopPropagation();
        }}
      >
        <ContentCopyIcon sx={{ width: '14px', height: '14px' }} />
      </IconButton>
    </Box>
  );
};

CopyToClipboardButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
