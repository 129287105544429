import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { getBackgroundColor, splitCode } from '../helpers/commodities';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CommodityCard = ({
  commodity,
  currentLevel = 0,
  setCommoditySelected,
  onEditClick,
  isValid,
  setIsValid,
}) => {
  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isDisabled = !commodity?.applyCode;
  const codePairs = splitCode(commodity?.code);

  const handleTooltipClick = () => {
    setTooltipOpen(prev => !prev);
  };

  return (
    <Box
      key={commodity.code}
      sx={{
        mt: 2,
        gap: '8px',
        border: !isValid
          ? `1px solid ${theme.palette.primary.main}`
          : `1px solid ${theme.palette.primary.iconGray}`,
        marginLeft: `${currentLevel * 20}px`,
        borderRadius: '4px',
        color: isDisabled ? 'gray' : 'black',
        background: isDisabled
          ? theme.palette.primary.disabledBg
          : 'transparent',
        pointerEvents: isDisabled ? 'none' : 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px 0px' }}>
        {codePairs &&
          codePairs.map((pair, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: !isDisabled
                  ? getBackgroundColor(index, theme)
                  : theme.palette.primary.gray_200,
                padding: '8px',
                margin: '4px',
                borderRadius: '4px',
                color: theme.palette.text.primary,
              }}
            >
              <Typography variant='body2'>{pair}</Typography>
            </Box>
          ))}
      </Box>
      <Tooltip
        title={commodity.description || ''}
        arrow
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        onClick={handleTooltipClick}
        disableInteractive
      >
        <Typography
          variant='body2'
          sx={{
            color: theme.palette.primary.main,
            margin: 'auto',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '47%',
            cursor: 'pointer',
          }}
        >
          {commodity.description}
        </Typography>
      </Tooltip>
      <StyledIconButton
        size='large'
        aria-label='edit'
        color='inherit'
        sx={{ display: 'inline-flex' }}
        onClick={onEditClick}
      >
        <EditIcon />
      </StyledIconButton>
      <StyledIconButton
        size='large'
        aria-label='delete'
        color='inherit'
        sx={{ display: 'inline-flex' }}
        onClick={() => {
          setIsValid(true);
          setCommoditySelected({});
        }}
      >
        <DeleteIcon />
      </StyledIconButton>
    </Box>
  );
};

CommodityCard.propTypes = {
  commodity: PropTypes.object,
  currentLevel: PropTypes.number,
  setCommoditySelected: PropTypes.func,
  onEditClick: PropTypes.func,
  isValid: PropTypes.bool,
  setIsValid: PropTypes.func,
};

export default CommodityCard;
