import { add, get, round, set } from 'lodash';

export const bulkUpdate = (form, fields = {}) =>
  form.batch(() => {
    Object.entries(fields).forEach(([key, value]) => {
      form.change(key, value);
    });
  });

export const bulkReset = (form, fields = []) =>
  form.batch(() => fields.map(fieldName => form.change(fieldName, undefined)));

export const hasErrorInRegisteredFields = (errors = {}, fields = []) => {
  const newErrors = {};

  fields.map(field => {
    if (get(errors, field)) {
      set(newErrors, field, get(errors, field));
    }
  });

  return newErrors;
};

export const getLiabilityCoverByValue = (insuranceConfig, value) => {
  const MAX_COVERAGE =
    insuranceConfig[insuranceConfig.length - 1].insuranceValue;
  const coverageArray = insuranceConfig.map(
    insurance => insurance.insuranceValue
  );

  return coverageArray.find(cVal => value <= cVal) || MAX_COVERAGE;
};

export const getTotalCoverage = (parcels = [], insuranceConfig = []) => {
  const totalValue = parcels.reduce(
    (totalParcelsValue, parcel) =>
      add(
        totalParcelsValue,
        round(Number(parcel._value || 0) * Number(parcel._quantity))
      ),
    0
  );
  return getLiabilityCoverByValue(insuranceConfig, totalValue);
};

export const fieldTrimOnBlur = {
  formatOnBlur: true,
  format: value => (typeof value === 'string' ? value.trim() : ''),
};
