import { add, filter, get, max, multiply, set, sortBy } from 'lodash';
import { FORM, POINT_TYPE } from '../constants';
import { ObjectUtil } from '../utils';
import { isNiShipment } from './shipment';

export const getNetworkQuery = values => {
  const data = ObjectUtil.pickDeep(values, [
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_CITY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY,
    FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_CITY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY,
    FORM.SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,

    FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
    FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
  ]);

  if (!get(data, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY)) {
    set(data, FORM.SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY, 1);
  }

  if (!get(data, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY)) {
    set(data, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY, 1);
  }

  const query = data.outboundConsignment;

  const isNiZone = isNiShipment(values);

  if (isNiZone) {
    const company = get(
      values,
      FORM.SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
    );
    query.isBusiness = get(
      values,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
      !!company
    );
    query.atRisk = get(
      values,
      FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY,
      false
    );
  }

  return query;
};

export const getNiRequiredFieldsQuery = values => ({
  countryCode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY, ''),
  postcode: get(values, FORM.SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY, ''),
  isBusiness: get(
    values,
    FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  ),
  atRisk: get(values, FORM.SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY, false),
});

export const getDriverSurchargePrice = ({ network }) =>
  network ? get(network, 'price.driverCollectionSurcharge', 0) : 0;

export const getDriverSurcharge = ({ shipment, network }) => {
  let driverSurchargePrice = 0;
  const isCollectedFromDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR;
  if (isCollectedFromDoor) {
    driverSurchargePrice = getDriverSurchargePrice({ network });
  }
  return driverSurchargePrice;
};

export const getTotalPrice = ({ shipment, network }) => {
  const parcels = get(shipment, FORM.SHIPMENT_FIELDS.PARCELS.KEY, []);
  let driverSurcharge = 0;
  let taxAmount = 0;
  let totalIncVatAmount = 0;

  if (parcels.length > 0) {
    let totalExcVatAmount = getBasePriceForNetwork({ parcels, network });
    driverSurcharge = getDriverSurcharge({ shipment, network });
    totalExcVatAmount = totalExcVatAmount + driverSurcharge;
    const vatRate = get(network, 'price.vatRate', 0);
    taxAmount =
      Math.round(((totalExcVatAmount * vatRate) / 100) * 100) / 100 || 0;
    totalIncVatAmount = totalExcVatAmount + taxAmount || 0;
  }

  return {
    taxAmount,
    totalIncVatAmount,
    driverSurcharge,
  };
};

export const getBasePriceForNetwork = ({ parcels, network }) => {
  if (!network) {
    return;
  }

  return parcels?.reduce((total, parcel) => {
    const parcelPrice = getParcelPrice({
      parcel,
      network,
    });
    return total + parcelPrice * (parcel._quantity || 1);
  }, 0);
};

export const getParcelPrice = ({ parcel, network }) => {
  if (!network) {
    return;
  }
  const basePrice = network.price?.baseRate;

  const extraWeightPrice = getExtraWeightPriceForParcel({
    parcel,
    network,
  });

  return basePrice + extraWeightPrice;
};

export const getExtraWeightPriceForParcel = ({ parcel, network }) => {
  if (!network) {
    return;
  }
  const weight = parcel._weight;
  const baseRateWeight = get(network, 'price.baseRateWeight');

  const additionalRateWeight = get(network, 'price.additionalRateWeight', 0);
  const additionalRate = get(network, 'price.additionalRate', 0);
  // Calculate extra weight
  const extraWeight = weight > baseRateWeight ? weight - baseRateWeight : 0;

  // Calculate additional charge based on increments
  const additionalCharges =
    additionalRateWeight !== 0
      ? Math.ceil(extraWeight / additionalRateWeight) * (additionalRate || 1)
      : 0;

  return additionalCharges || 0;
};

export const getDefaultNetwork = ({ shipment, minNet }) => {
  if (!minNet) {
    return;
  }
  const isDroppedToDoor =
    get(shipment, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY) === POINT_TYPE.DOOR;
  return isDroppedToDoor
    ? minNet.minFromDoorPriceNetwork
    : minNet.minFromShopPriceNetwork;
};

export const getMinPriceNetworks = networks => {
  const minFromShopPriceNetwork = sortBy(
    networks.filter(
      network => network.price.baseRate && network.networkKey === '2^91'
    ),
    'price.baseRate'
  )[0];

  const minFromDoorPriceNetwork = sortBy(
    networks.filter(
      network => network.price.baseRate && network.networkKey !== '2^91'
    ),
    'price.baseRate'
  )[0];

  return { minFromShopPriceNetwork, minFromDoorPriceNetwork };
};
/**
 *
 * @param {Array} networks - Array of networks
 * @param {number} shipment - Shipment
 * @returns {Array} - Available networks based on number of parcels and their weights
 */
export const getNetworksByParcelSizeAndWeight = (networks = [], shipment) =>
  networks.filter(({ networkDetails }) => {
    const parcels = get(shipment, FORM.SHIPMENT_FIELDS.PARCELS.KEY, []);
    return (
      parcels.every(
        ({ _weight }) => _weight <= networkDetails?.maxWeight / 1000
      ) &&
      get(shipment, FORM.SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY) <=
        networkDetails?.maxParcels
    );
  });

/**
 *
 * @param {Array} networks - Array of networks
 * @param {boolean} dropAtShop - Boolean indicator of whether parcel goes to shop
 * @returns {Array} - Sorted array of networks by price based on whether parcel goes to shop
 */
// export const sortNetworksByPrice = networks =>
//   networks.sort((prev, curr) => prev?.price?.baseRate - curr?.price?.baseRate);

export const sortNetworksByPrice = networks =>
  networks.sort(
    (prev, curr) =>
      (prev?.price?.baseRate || Infinity) - (curr?.price?.baseRate || Infinity)
  );

export const getParcelsTotalWeight = (shipment, customParcels = []) => {
  const parcels = customParcels.length
    ? customParcels
    : get(shipment, FORM.SHIPMENT_FIELDS.PARCELS.KEY) || [];

  return parcels.reduce(
    (acc, curr) =>
      add(
        acc,
        multiply(
          Number(curr._weight || 0),
          Number.parseInt(curr._quantity || 0)
        )
      ),
    0
  );
};

export const getParcelTotalQuantity = (parcels = []) =>
  parcels.reduce(
    (acc, curr) => add(acc, Number.parseInt(curr._quantity || 0)),
    0
  );

/**
 *
 * @param {Array} networks - List of networks
 * @returns Maximum weight and parcel number for networks
 */
export const getMaxWeightAndParcelNumber = (networks = []) => {
  const { maxWeight, maxParcelNumber } = networks.reduce((acc, curr) => {
    let maxWeight = acc.maxWeight;
    let maxParcelNumber = acc.maxParcelNumber;

    if (curr.networkDetails?.maxWeight) {
      maxWeight = max([maxWeight, curr.networkDetails?.maxWeight / 1000]);
    }
    if (curr.networkDetails?.maxParcels) {
      maxParcelNumber = max([maxParcelNumber, curr.networkDetails?.maxParcels]);
    }

    return {
      maxWeight,
      maxParcelNumber,
    };
  }, {});

  return {
    maxWeight: maxWeight || 30,
    maxParcelNumber: maxParcelNumber || 99,
  };
};

const getArrivalServicesExtraPrices = ({
  networks,
  shipment,
  minDoorPrice,
  minFromDoorPriceNetwork,
}) => {
  const arrivalServicesNetworks = filter(networks, network => {
    const baseRate = network?.price?.baseRate;

    return baseRate && baseRate >= minFromDoorPriceNetwork?.price?.baseRate;
  });

  return arrivalServicesNetworks.map(network => {
    const servicePrice = getBasePriceForNetwork({
      network,
      parcels: get(shipment, FORM.SHIPMENT_FIELDS.PARCELS.KEY),
    });

    return {
      networkKey: network?.networkKey,
      extraPrice:
        servicePrice -
        minDoorPrice +
        ((network?.price?.driverCollectionSurcharge || 0) -
          (minFromDoorPriceNetwork?.price?.driverCollectionSurcharge || 0)),
    };
  });
};

export const getPriceData = ({ price = {}, networks, shipment }) => {
  const parcels = get(shipment, FORM.SHIPMENT_FIELDS.PARCELS.KEY, []);
  const selectedNetwork = get(shipment, FORM.SHIPMENT_FIELDS.NETWORK.KEY);
  const minNet = getMinPriceNetworks(networks);
  const defaultNetwork = getDefaultNetwork({ shipment, minNet });
  const defaultDriverSurchargePrice = getDriverSurchargePrice({
    // network: selectedNetwork || defaultNetwork,
    network: defaultNetwork,
  });

  const totalPrice = getTotalPrice({
    network: selectedNetwork || defaultNetwork,
    shipment,
  });
  const minDoorPrice = getBasePriceForNetwork({
    parcels,
    network: minNet.minFromDoorPriceNetwork,
  });

  return {
    ...price,
    basePrice: getBasePriceForNetwork({ parcels, network: defaultNetwork }),
    minShopPrice: getBasePriceForNetwork({
      parcels,
      network: minNet.minFromShopPriceNetwork,
    }),
    minDoorPrice,
    deliveryExtraPrice: getBasePriceForNetwork({
      parcels,
      network: minNet.minFromDoorPriceNetwork,
    }),
    collectionExtraPrice: defaultDriverSurchargePrice || 0,
    taxAmount: price.taxAmount || totalPrice.taxAmount || 0,
    totalIncVatAmount:
      price.totalIncVatAmount || totalPrice.totalIncVatAmount || 0,
    arrivalServicesExtraPrices: getArrivalServicesExtraPrices({
      networks,
      shipment,
      minDoorPrice,
      minFromDoorPriceNetwork: minNet.minFromDoorPriceNetwork,
    }),
  };
};

export const removeBusinessUnit = value =>
  typeof value === 'string' ? value.replace(/^[12]{1}\^/, '') : '';

export const getNetworkByCode = (networks = [], code = '') =>
  networks.find(({ networkKey }) => networkKey === code);
